import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {NpsComponent} from 'fuse-core/components/nps/nps.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';

/* const routes: Routes = [
    {
        path     : 'nps',
        component: NpsComponent
    }
]; */

@NgModule({
    declarations: [
        NpsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        //RouterModule.forChild(routes),
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatDialogModule
    ]
})
export class NpsModule { }