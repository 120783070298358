import * as _ from 'lodash-es';

import {ModelSchema, Structures} from 'octopus-model';

import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {FollowedListComponentOptionsInterface} from '@modules/assignation/core/components/followed-list/followed-list-component-options.interface';
import {Component, Injectable} from '@angular/core';
import {modulesSettings} from '../../../../settings';
import {AssignationComponent} from '@modules/assignation/core/components/assignation/assignation.component';

export type FollowedListComponentSettingsInterface = Omit<
    FollowedListComponentOptionsInterface,
    'initialRequestFilters'
>;

const settingsStructure: ModelSchema = new ModelSchema({
    activateFxHide: Structures.boolean(true),
    // True if lesson are allowed to be assigned
    assignableLesson: Structures.boolean(false),
    columnsDashboardWidget: Structures.object({
        default: [
            'type',
            'title',
            'start_date',
            'end_date',
            'group',
            'workgroup',
            'progress',
        ],
    }),
    completionDate: Structures.array(['assessment', 'homework']),
    completionStartDateOnly: Structures.array(),
    completionTime: Structures.array(['assessment']),
    defaultType: Structures.string(null),
    defaultStartDate: Structures.boolean(false),
    rolesCanShowBannerInfo: Structures.array(),
    rolesCanShowBannerInfoClosedAssignment: Structures.array(),
    // If false, the trainer have to have specific rights defined on group, institutions, etc.
    doTrainersHaveRestrictiveRights: Structures.boolean(false),
    editableAssignmentCommentMaxLength: Structures.number(null),
    editableAssignmentFields: Structures.object({default: []}),
    enableGradeType: Structures.array(),
    assignationGroupsFields: Structures.object({
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate'],
    }),
    followedLogBookFields: Structures.object({
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate'],
    }),
    userSavesList: Structures.object({
        default: ['group', 'exoName', 'learner'],
    }),
    followedTabsList: Structures.array([
        'translationTabLabelList',
        'translationTabLabelLogBook',
        'assignation.self_assignments_list',
    ]),
    formFields: Structures.object({
        default: [
            'group',
            'workgroup',
            'learner',
            'project',
            'assignment_type',
            'assignment_grade',
            'start_date',
            'due_date',
        ],
    }),
    genericAssignmentListColumns: Structures.object({
        default: [
            'type',
            'assigned_node_title',
            'start_date',
            'end_date',
            'assigned_user',
            'class',
            'group',
            'state',
            'progress',
            'score',
            'buttons',
        ],
    }),
    genericAssignmentListDisableOpening: Structures.boolean(false),
    genericAssignmentListFilters: Structures.object({
        default: ['type', 'title', 'group', 'workgroup', 'learner'],
    }),
    genericConditionalFilters: Structures.object({
        default: [],
    }),
    selfConditionalFilters: Structures.object({
        default: [],
    }),
    genericAssignmentListFormCreationLink: Structures.array([]),
    genericAssignmentListAllowedActions: Structures.object({
        default: ['unassign'],
    }),
    genericAssignmentListGetStateFromDate: Structures.boolean(false),
    genericAssignmentListRolesForOverrideAssignmentLaunchToMetadatas:
        Structures.array([]),
    genericAssignmentListShowHours: Structures.boolean(true),
    genericAssignmentListShowNameClass: Structures.boolean(false),
    genericAssignmentListStateWithIcon: Structures.boolean(false),
    genericAssignmentsListCheckDefaultFiltersInUrl: Structures.boolean(false),
    gettingStarted: Structures.object({}),
    hasCompletionDate: Structures.boolean(true),
    hasCompletionTime: Structures.boolean(true),
    hasType: Structures.boolean(true),
    hideLearnerAutoAssignments: Structures.boolean(false),
    isAssignationIsLimitedByUsage: Structures.boolean(false),
    isAutoAssignmentForced: Structures.boolean(false),
    isLessonCanBeAssignedOnlyOnceByUser: Structures.boolean(false),
    isMultipleActionWithCheckBoxes: Structures.boolean(false),
    maxCommentLenght: Structures.number(280),
    menuLearnerMustBeInGroup: Structures.boolean(false),
    noFilterforAssignmentsWidget: Structures.boolean(false),
    ratingBase: Structures.array(),
    selfAssignmentListActiveAvailableRole: Structures.array([]),
    assignationGroupsActive: Structures.array([]),
    assignationGroupsDetailActive: Structures.array([]),
    isUserSaveListActive: Structures.array([]),
    selfAssignmentListAllowedActions: Structures.object({
        default: ['unassign'],
    }),
    selfAssignmentListColumns: Structures.object({
        default: ['assigned_node_title', 'start_date', 'state', 'buttons'],
    }),
    selfAssignmentListDisableOpening: Structures.boolean(false),
    selfAssignmentListFilters: Structures.object({
        default: [],
    }),
    selfAssignmentListFormCreationLink: Structures.array([]),
    selfAssignmentListGetStateFromDate: Structures.boolean(false),
    selfAssignmentListRolesForOverrideAssignmentLaunchToMetadatas:
        Structures.array([]),
    selfAssignmentListShowHours: Structures.boolean(true),
    selfAssignmentListShowNameClass: Structures.boolean(false),
    selfAssignmentListStateWithIcon: Structures.boolean(false),
    selfAssignmentsListCheckDefaultFiltersInUrl: Structures.boolean(false),
    shouldRedirectoToApp: Structures.boolean(false),
    useHideFeedbacksToLaunchAdaptativeModal: Structures.boolean(false),
    useSchoolYearFilter: Structures.boolean(false),
    useNicknameInsteadOfUsername: Structures.boolean(false),
    navigateDirectlyToSummary: Structures.boolean(false),
    isAssignationClosedActive: Structures.array([]),
    assignmentsStateIds: Structures.object({closed: '2682', assigned: '2681', pending: '2683', valid: '4270'}), // assignment state
    selfShowAllStates: Structures.boolean(false),
    genericShowAllStates: Structures.boolean(false),
    assignationModalComponentToUse: Structures.string('AssignationComponent'),
    isProgressionEnable: Structures.boolean(false),
    isGroupsMustBeDisplayedWithChips: Structures.boolean(false),
    isRetryEnabledOnClickInAssignmentTitle: Structures.boolean(false),
    isRestrictionForDeAssign: Structures.boolean(false),
    disallowedConcepts: Structures.array([]),
    subscribeToLessonsTabComponentEvent: Structures.boolean(false),
    showBannerAndAssignationDetailBloc: Structures.boolean(true),
    applyAssignatedFilterToFalse: Structures.boolean(false),
    addFilterGrade: Structures.boolean(false),
    loadRootFilter: Structures.boolean(false),
    useGetLessonByIdsOnce: Structures.boolean(false),
    listenAssignatedControlEvent: Structures.boolean(false),
    notAllowedToDisableSearch: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root',
})
export class AssignationConfigurationService {
    /** @deprecated use a method here */
    public settings: { [p: string]: any };
    private readonly privateSettings: { [p: string]: any };

    constructor(
        private communicationCenter: CommunicationCenterService,
        private authenticationService: AuthenticationService
    ) {
        this.settings = this.privateSettings = settingsStructure.filterModel(
            modulesSettings.assignation
        );

        this.communicationCenter
            .getRoom('assignation')
            .next('isAssignationActionIsActive', this.isAssignationActionIsActive());
        this.communicationCenter
            .getRoom('assignation')
            .next(
                'isAssignationIsLimitedByUsage',
                this.isAssignationIsLimitedByUsage()
            );
        this.communicationCenter
            .getRoom('assignation')
            .next('isAutoAssignmentForced', this.isAutoAssignmentForced());
    }

    /**
     * if true, user is redirect to app depending of its lesson concepts
     */
    public shouldRedirectoToApp(): boolean {
        return this.privateSettings.shouldRedirectoToApp;
    }

    /**
     * if false, nobody can assign a lesson
     */
    public isAssignationActionIsActive(): boolean {
        return this.privateSettings.assignableLesson;
    }

    /**
     * if true, we must check the usage of the lesson for know if we could assign it
     */
    public isAssignationIsLimitedByUsage(): boolean {
        return this.privateSettings.isAssignationIsLimitedByUsage;
    }

    /**
     * if true, we try to create an assignment when we start a lesson
     */
    public isAutoAssignmentForced(): boolean {
        return this.privateSettings.isAutoAssignmentForced;
    }

    /**
     * If false, the trainer have to have specific rights defined on group, institutions, etc.
     */
    public doTrainersHaveRestrictiveRights(): boolean {
        return this.privateSettings.doTrainersHaveRestrictiveRights;
    }

    /**
     * if true, display header banner info
     */
    public rolesCanShowBannerInfo(): string[] {
        return this.privateSettings.rolesCanShowBannerInfo;
    }

    public rolesCanShowBannerInfoClosedAssignment(): string[] {
        return this.privateSettings.rolesCanShowBannerInfoClosedAssignment;
    }

    /**
     * do we activate fxhide on array
     */
    public activateFxHide(): boolean {
        return this.privateSettings.activateFxHide;
    }

    public isSelfAssignmentListActive(): boolean {
        const role = this.authenticationService.accessLevel;
        const rawSettings: string[] = _.get(
            this.privateSettings,
            'selfAssignmentListActiveAvailableRole',
            []
        );

        return rawSettings.includes(role);
    }

    public isAssignationGroupsActive(): boolean {
        const role = this.authenticationService.accessLevel;
        const rawSettings: string[] = _.get(
            this.privateSettings,
            'assignationGroupsActive',
            []
        );

        return rawSettings.includes(role);
    }

    public isAssignationGroupsDetailActive(): boolean {
        const role = this.authenticationService.accessLevel;
        const rawSettings: string[] = _.get(
            this.privateSettings,
            'assignationGroupsDetailActive',
            []
        );

        return rawSettings.includes(role);
    }

    public isAssignationClosedActive(): boolean {
        const role = this.authenticationService.accessLevel;
        const rawSettings: string[] = _.get(
            this.privateSettings,
            'isAssignationClosedActive',
            []
        );

        return rawSettings.includes(role);
    }

    public isUserSaveListActive(): boolean {
        const role = this.authenticationService.accessLevel;
        const rawSettings: string[] = _.get(
            this.privateSettings,
            'isUserSaveListActive',
            []
        );

        return rawSettings.includes(role);
    }

    /**
     * allow to override the default tabs list and its order
     */
    public followedTabsList(): string[] {
        const role = this.authenticationService.accessLevel;
        const followedTabs = _.get(this.privateSettings, 'followedTabsList', {});
        return followedTabs[role] || followedTabs['default'] || followedTabs || [];
    }

    public getAssignmentsListOptions(
        kindOf: 'generic' | 'self'
    ): FollowedListComponentSettingsInterface {
        const role = this.authenticationService.accessLevel;

        const columnsRoles = _.get(
            this.privateSettings,
            `${kindOf}AssignmentListColumns`,
            {default: []}
        );
        const filtersRoles = _.get(
            this.privateSettings,
            `${kindOf}AssignmentListFilters`,
            {default: []}
        );
        const conditionalFiltersRoles = _.get(
            this.privateSettings,
            `${kindOf}ConditionalFilters`,
            {default: []}
        );
        const allowedActionsRoles = _.get(
            this.privateSettings,
            `${kindOf}AssignmentListAllowedActions`,
            {default: []}
        );


        const columns = columnsRoles.hasOwnProperty(role)
            ? columnsRoles[role]
            : columnsRoles.default;

        const filters = filtersRoles.hasOwnProperty(role)
            ? filtersRoles[role]
            : filtersRoles.default;
        const conditionalFilters = conditionalFiltersRoles.hasOwnProperty(role)
            ? conditionalFiltersRoles[role]
            : conditionalFiltersRoles.default;
        const allowedActions = allowedActionsRoles.hasOwnProperty(role)
            ? allowedActionsRoles[role]
            : allowedActionsRoles.default;

        const useHideFeedbacksToLaunchAdaptativeModal = _.get(
            this.privateSettings,
            'useHideFeedbacksToLaunchAdaptativeModal',
            false
        );

        const assignmentsListOptions = {
            checkDefaultFiltersInUrl: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListCheckDefaultFiltersInUrl`,
                false
            ),
            disableOpening: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListDisableOpening`,
                false
            ),
            formCreationLink: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListFormCreationLink`,
                []
            ),
            getStateFromDate: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListGetStateFromDate`,
                false
            ),
            rolesForOverrideAssignmentLaunchToMetadatas: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListRolesForOverrideAssignmentLaunchToMetadatas`,
                []
            ),
            showHours: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListShowHours`,
                true
            ),
            showNameClass: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListShowNameClass`,
                false
            ),
            stateWithIcon: _.get(
                this.privateSettings,
                `${kindOf}AssignmentListStateWithIcon`,
                false
            ),
            useNicknameInsteadOfUsername: _.get(this.privateSettings, `useNicknameInsteadOfUsername`, false),
            navigateDirectlyToSummary: this.navigateDirectlyToSummary(),
            showAllStates: _.get(
                this.privateSettings,
                `${kindOf}ShowAllStates`,
                false
            ),
            isProgressionEnable: _.get(
                this.privateSettings,
                `isProgressionEnable`,
                false
            ),
            isGroupsMustBeDisplayedWithChips: _.get(
                this.privateSettings,
                `isGroupsMustBeDisplayedWithChips`,
                false
            ),
            isRetryEnabledOnClickInAssignmentTitle: _.get(
                this.privateSettings,
                `isRetryEnabledOnClickInAssignmentTitle`,
                false
            ),
            isRestrictionForDeAssign: _.get(
                this.privateSettings,
                `isRestrictionForDeAssign`,
                false
            ),
        };

        return {
            ...assignmentsListOptions,
            columns,
            filters,
            conditionalFilters,
            allowedActions,
            useHideFeedbacksToLaunchAdaptativeModal,
            // si prof ou eleve même chose on regarde toutes les assignation, et on ne recupere pas ses autoassignation
            excludeAssignator:
                kindOf === 'generic',
        };
    }

    public isMultipleActionWithCheckBoxes(): boolean {
        return _.get(this.privateSettings, `isMultipleActionWithCheckBoxes`, null);
    }

    public getEditableAssignmentFields(): string[] {
        const role = this.authenticationService.accessLevel;

        const editableAssignmentFields = _.get(
            this.privateSettings,
            `editableAssignmentFields`,
            {default: []}
        );
        return editableAssignmentFields.hasOwnProperty(role)
            ? editableAssignmentFields[role]
            : editableAssignmentFields.default;
    }

    public getEditableAssignmentCommentMaxLength(): number | null {
        return _.get(
            this.privateSettings,
            `editableAssignmentCommentMaxLength`,
            null
        );
    }

    public learnerMustBeInGroupToSeeMenu(): boolean {
        return _.get(this.privateSettings, `menuLearnerMustBeInGroup`, false);
    }

    public useNicknameInsteadOfUsername(): boolean {
        return _.get(this.privateSettings, `useNicknameInsteadOfUsername`, false);
    }

    public navigateDirectlyToSummary(): boolean {
        return _.get(this.privateSettings, `navigateDirectlyToSummary`, false);
    }

    public useDefaultStartDate(): boolean {
        return _.get(this.privateSettings, `defaultStartDate`, false);
    }

    public assignmentsStateIds(): { closed: string, assigned: string, pending: string, valid: string } {
        return _.get(this.privateSettings, `assignmentsStateIds`, {closed: '2682', assigned: '2681', pending: '2683', valid: '4270'});
    }

    public assignationModalComponentToUse(): string {
        return _.get(this.privateSettings, `assignationModalComponentToUse`, 'AssignationComponent');
    }

    public disallowedConcepts(): string[] {
        return _.get(this.privateSettings, 'disallowedConcepts', []);
    }

    public subscribeToLessonsTabComponentEvent(): boolean {
        return _.get(this.privateSettings, 'subscribeToLessonsTabComponentEvent', false);
    }

    public showBannerAndAssignationDetailBloc(): boolean {
        return _.get(this.privateSettings, 'showBannerAndAssignationDetailBloc', true);
    }

    public applyAssignatedFilterToFalse(): boolean {
        return _.get(this.privateSettings, 'applyAssignatedFilterToFalse', false);
    }

    public addFilterGrade(): boolean {
        return _.get(this.privateSettings, 'addFilterGrade', false);
    }

    public loadRootFilter(): boolean {
        return _.get(this.privateSettings, 'loadRootFilter', false);
    }

    public listenAssignatedControlEvent(): boolean {
        return _.get(this.privateSettings, 'listenAssignatedControlEvent', false);
    }

    public notAllowedToDisableSearch(): boolean {
        return _.get(this.privateSettings, 'notAllowedToDisableSearch', false);
    }

    public useGetLessonByIdsOnce(): boolean {
        return _.get(this.privateSettings, 'useGetLessonByIdsOnce', false);
    }


}
