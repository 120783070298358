import {
    environment,
    getTrackingID,
    getTrackingIDMatomo,
    getTrackingIDGAds,
    getTrackingIDGTM,
    getTrackingIDMetaPixel,
    getTrackingIDSnapchat
} from '../../../environments/environment';

import { LayoutConfig } from 'fuse-core/types';
import { RecursivePartial } from 'shared/utils';
import { Roles } from 'shared/models/roles';
import { accountManagement } from './modules/account-management';
import { achievements } from './modules/achievements';
import { activities } from './modules/activities';
import { assignation } from './modules/assignation';
import { authentication } from './modules/authentication';
import { basicPage } from './modules/basic-page';
import { browserTest } from './modules/browser-test';
import { cards } from './core/cards';
import { configuration } from './modules/configuration';
import { contest } from "./modules/contest";
import { corpus } from './modules/corpus';
import { dashboard } from './modules/dashboard';
import { faq } from './modules/faq';
import { featureAccess } from './core/feature-access';
import { gamecode } from './modules/gamecode';
import { gamification } from './modules/gamification';
import { graphAssignation } from './modules/graph-assignation';
import {graphMulti} from './modules/graph-multi';
import { groupsManagement } from './modules/groups-management';
import { header } from './core/header';
import { homePage } from './modules/home-page';
import { ideasWall } from './modules/ideas-wall';
import { licensing } from './modules/licensing';
import { lrs } from './modules/lrs';
import { mainMenu } from './core/menu';
import { notification } from './modules/notification';
import { projectsManagement } from './modules/projects-management';
import { researchSheet } from './modules/research-sheet';
import {routesAccess} from "./core/routes-access";
import { timeline } from './modules/timeline';
import {mindmap} from './modules/mindmap';
import {notepad} from "./modules/notepad";
import {infoLegales} from './core/info-legales';
export const defaultValues = {}
export const brand = 'amazon_python'; // instance name of repository project, asset floder name, settings ...
export const defaultMetaDescription = {
    fr: "Commencez la nouvelle aventure d'apprentissage de la programmation et construisez votre ville. Réussissez et remportez votre Open Badge Python ou Blockly",
};
export const brandTitle = 'Future Engineer'; // index.html title tab title
// 'recaptcha' (google) or 'hcaptcha' or 'friendlyCaptcha'. Hcaptcha not working on localhost, use test.mydomain.com instead => (ng serve --host test.mydomain.com)
export const captcha = 'friendlyCaptcha';
export const NPS = false; // display or not NPS form to all users
export const userReview = []; // display or not content Score form to specified roles
export const matomoTracking = true; // allow or not matomo tracking
export const accessibiActivate = false; // launch accessibe
export const youTubeTracking = false; // allow or not youtube tracking
export const twitterTracking = false; // allow or not twitter tracking
export const GATracking = false; // allow or not Google Analytics tracking
export const GAdsTracking = true; // allow or not Google Ads tracking
export const GTMTracking = true; // allow or not Google Tag Manager tracking
export const MetaPixelTracking = true; // allow or not Meta pixel tracking
export const SnapchatTracking = true; // allow or not Google Analytics tracking
export const matomoTrackingID = '40'; // à changer, ID matomo de la prod de l'instance
export const GTMTrackingID = 'GTM-WCWM2RH'; // à changer, ID matomo de la prod de l'instance
export const GTMPushToDataLayer = true;
export const MetaPixelTrackingID = '3435985733288535'; // à changer, ID matomo de la prod de l'instance
export const SnapchatTrackingID = 'e934da9d-93c7-4a9e-839e-613a9210c4b5'; // à changer, ID matomo de la prod de l'instance
export const GAdsTrackingID = 'AW-11007137423'; // à changer, ID matomo de la prod de l'instance
export const GAdsTrackingIDFromSettings =
    getTrackingIDGAds(GAdsTrackingID);
export const GTMTrackingIDFromSettings =
    getTrackingIDGTM(GTMTrackingID);
export const MetaPixelTrackingIDFromSettings =
    getTrackingIDMetaPixel(MetaPixelTrackingID);
export const SnapchatTrackingIDFromSettings =
    getTrackingIDSnapchat(SnapchatTrackingID);
export const matomoTrackingIDFromSettings =
    getTrackingIDMatomo(matomoTrackingID);
export const trackingID = '';
export const trackingIDFromSettings = getTrackingID(trackingID);
export const LRSTracking = false; // allow or not LRS tracking
export const LRSTrackingUrl = ''; // only used for mindmath, see lrs settings now
export const useBrandTwoDifferentSvg = false; // use two svg one for menu open and one for close and logo is different from those use in login menu
export const brandLogoSvg = false;
export const baseAppUrl = environment.baseAppUrl(brand);
export const defaultApiURL = environment.baseApiUrl(brand);
export const defaultNodeURL = environment.baseNodeUrl(brand);
export const defaultURL = environment.baseUrl(brand);
export const defaultLoginRoute = 'achievement'; // route just after connexion when you are on root localhost
export const defaultRoute = 'achievement'; // route by default when on root localhost but already connected using token
// export const defaultLanguage = 'en';
export const defaultLanguage = 'fr';
export const filterByCurrentYearByDefault = false;
export const addRoleLicenceSuffixForExternalLink = false;
export const langs = [
    {
        id: 'fr',
        title: 'Français',
        flag: 'fr',
        code: 'fr-FR',
    },
    {
        id: 'nl',
        title: 'Nederlands',
        flag: 'be',
        code: 'nl-BE'
    },
];
export const mobileUrlRedirection = null;
export const isMobileAlert = true;
export const dateTimeOptions: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};
export const tralalereBar = {
    displayed: false,
};
export const defaultValue = {};
export const displayHelp = false;

export const displayHeader = false;

export const displayHeaderTitle = true;

export const customHomePageBanner = false;

export const titleTextInHomePageBanner = false;

export const cardLayout = 'card-split-media-info';

export const enableRgpdLinks = false;

export const customLayoutConfig: RecursivePartial<{
    [key in Roles | 'default' | 'anonymous']: LayoutConfig;
}> = {
    anonymous: {
        layout: {
            navbar: {
                hidden: true,
            },
            footer: {
                position: 'below-fixed',
                hidden: false,
            }
        }
    },
    default: {
        layout: {
            navbar: {
                hidden: false,
            },
            footer: {
                hidden: true,
            }
        }
    }
};

export const furtherLinks = [];

export const globalNewsSettings = {
    snackbar: {
        active: {
            default: false,
        },
        cacheInLocalStorage: true,
    },
    recallButton: {
        active: {
            default: false,
        },
        pathLimitations: {
            accepted: [
                /^\/?(dashboard|achievement|gamification|lessons|avatars|followed\/tab)/,
            ],
            forbidden: [/^\/?(achievement\/onboarding|achievement$)/],
        },
    },
};

export const modulesSettings = {
    accountManagement,
    activities,
    assignation,
    authentication,
    basicPage,
    browserTest,
    cards,
    contest,
    corpus,
    dashboard,
    featureAccess,
    gamecode,
    gamification,
    graphAssignation,
    graphMulti,
    groupsManagement,
    homePage,
    ideasWall,
    licensing,
    mainMenu,
    notification,
    projectsManagement,
    researchSheet,
    routesAccess,
    configuration,
    timeline,
    header,
    lrs,
    achievements,
    faq,
    mindmap,
    notepad,
    infoLegales
};

export const externalUrl = {
    'access-future-engineer': 'https://www.futureengineer.fr',
    'contact-us': 'https://www.futureengineer.fr/contact/',
};

// https://tarteaucitron.io/fr/
export const tarteaucitronConf: object = {
    "privacyUrl": "", /* Privacy policy url */
    "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */
    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "popup", /* Banner position (top - bottom - middle - popup) */
    "groupServices": false, /* Group services by category */
    "showDetailsOnClick": true, /* Click to expand the description */
    "serviceDefaultState": "wait", /* Default state (true - wait - false) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "showIcon": true, /* Show cookie icon to manage cookies */
    "iconSrc": "https://www.tralalere.com/header/images/cookies.svg",
    "iconPosition": "BottomRight", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta" : true, /* Show the deny all button */
    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": false, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.services.js file will be loaded */
    // "cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */
    "readmoreLink": "https://www.tralalere.com/page/rgpd", /* Change the default readmore link pointing to tarteaucitron.io */
    "mandatory": false, /* Show a message about mandatory cookies */
    "mandatoryCta": true, /* Show the disabled accept button when mandatory on */
    // "customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */
    "googleConsentMode": true /* Enable Google Consent Mode v2 for Google ads and GA4 */
};

export * from './core/fuse-config';
