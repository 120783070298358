import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthenticationService} from '@modules/authentication';
import {BDToolModule} from 'bdtool';
import {BdCardComponent} from './bd-card/bd-card.component';
import {BdContentEditorComponent} from './bd-content-editor/bd-content-editor.component';
import { BdContentViewerModalComponent } from './bd-content-viewer-modal/bd-content-viewer-modal.component';
import {BdDataEditorModalComponent} from './bd-data-editor-modal/bd-data-editor-modal.component';
import {BdGenericAlertModalComponent} from './bd-generic-alert-modal/bd-generic-alert-modal.component';
import {BdListComponent} from './bd-list/bd-list.component';
import {BdRepositoryService} from './bd-repository.service';
import {BdService} from './bd.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {FormsModule} from '@angular/forms';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {SearchFiltersModule} from 'fuse-core/components';
import {SharedModule} from 'shared/shared.module';

const routes: Routes = [
    {
        path: 'bd-tool',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: BdListComponent,
            },
            {
                path: ':bdId/edit',
                component: BdContentEditorComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        BDToolModule.forRoot({ texts: {} }),
        SharedModule,
        SearchFiltersModule,
    ],
    declarations: [
        BdDataEditorModalComponent,
        BdCardComponent,
        BdListComponent,
        BdContentEditorComponent,
        BdGenericAlertModalComponent,
        BdContentViewerModalComponent
    ],
})
export class BdModule {
    private static isMenuSet = false;

    constructor(private dynamicNavigation: DynamicNavigationService,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<BdModule> {
        return {
            ngModule: BdModule,
            providers: [
                BdService,
                BdRepositoryService
            ]
        };
    }

    private postAuthentication(): void {
        if (!BdModule.isMenuSet) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',

            });
            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'bdList',
                'title': 'bdList',
                'translate': 'bd.title',
                'type': 'item',
                'url': '/bd-tool/list',
            });
            BdModule.isMenuSet = true;
        }
    }

    private postLogout(): void {
        BdModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'bdList');
    }
}