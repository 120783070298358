import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GamecodeService} from '../gamecode.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {GamecodeRepositoryService} from '@modules/gamecode/core/gamecode-repository.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-gamecode-content-editor',
    templateUrl: './gamecode-content-editor.component.html',
})
export class GamecodeContentEditorComponent implements OnInit {
    /**
     * Gamecode title used as header
     */
    public gamecodeTitle = '';

    /**
     * Current gamecode granule (from granule endpoint)
     */
    private gamecodeGranule: DataEntity = null;
    public urlToGamecode: SafeResourceUrl;

    constructor(private activatedRoute: ActivatedRoute,
                private dialog: MatDialog,
                private gamecodeSvc: GamecodeService,
                private gamecodeRepoSvc: GamecodeRepositoryService,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer) {
    }

    /**
     * Defined if the component a ready
     */
    public get isLoadingDone(): boolean {
        return this.gamecodeGranule !== null;
    }

    ngOnInit(): void {
        this.route.params.pipe(
            mergeMap(params => this.gamecodeRepoSvc.getGamecode(params['gamecodeId'])),
            tap(gamecodeGranule => {
                this.initialize(gamecodeGranule);
            })
        ).subscribe();
    }

    /**
     * Close the component by redirecting to the gamecodeList or by emit on the `Complete Subject`.
     * To access to the emit subject, the component need to have a subject unique id.
     * This unique id is used by the {@link GamecodeService.onGamecodeDataEditionCompleteSubject} to reference the subject.
     *
     * @remaks Sometimes, like by reloading component from nowhere but with the `onComplete queryParams` the on complete subject id can be not associated to a subject.
     */
    public close(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    if (this.gamecodeSvc.onGamecodeDataEditionCompleteSubject.hasOwnProperty(params['onComplete'])) {
                        this.gamecodeSvc.onGamecodeDataEditionCompleteSubject[params['onComplete']].next(this.gamecodeGranule);
                        return;
                    }
                }

                this.gamecodeSvc.goToGamecodeList();
            })
        ).subscribe();
    }

    /**
     * Reset the component with default values defined by the granule.
     * Different than reset the content.
     * HACK there are no login in the iframe, the give it the current user token for the v1
     * @param gamecodeGranule
     */
    private initialize(gamecodeGranule: DataEntity): void {
        this.gamecodeGranule = gamecodeGranule;
        this.gamecodeTitle = this.gamecodeGranule.get('title');
        const gamecodeId = this.gamecodeGranule.id;
        const token = localStorage.getItem('http_accessToken');
        this.urlToGamecode = this.sanitizer.bypassSecurityTrustResourceUrl(environment[this.gamecodeGranule.get('application') + 'Url']() + '?creation='+gamecodeId+'&token=' + token.slice(1, -1));
    }
}