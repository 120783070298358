import { BehaviorSubject, Observable } from "rxjs";
import { DataEntity, OctopusConnectService } from "octopus-connect";
import { distinctUntilChanged, map } from "rxjs/operators";

import { CorpusService } from "../../corpus.service";
import { Group } from "./resource-share-to-groups-modal.interfaces";
import { Injectable } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { TypedDataCollectionInterface } from "app/shared/models/octopus-connect/typed-data-collection.interface";

interface State {
  groupList: Group[];
  selectedGroupList: Group[];
}

@Injectable()
export class ResourceshareToGroupModalService {
  state$ = new BehaviorSubject<State>({
    groupList: [],
    selectedGroupList: [],
  });

  get state(): State {
    return this.state$.getValue();
  }

  select<T>(mapFn: (state: State) => T): Observable<T> {
    return this.state$.asObservable().pipe(
      map((state: State) => mapFn(state)),
      distinctUntilChanged()
    );
  }

  setState(newState: Partial<State>) {
    this.state$.next({
      ...this.state,
      ...newState,
    });
  }

  constructor(
    private octopusConnect: OctopusConnectService,
    private corpusService: CorpusService
  ) {}

  /**
   * Handles the selection of a group from an autocomplete dropdown list.
   * Adds the selected group to the selectedGroupList state if it is not already in the list.
   * @param group - The MatAutocompleteSelectedEvent object representing the selected group.
   * @returns void
   */
  selectGroup(group: MatAutocompleteSelectedEvent): void {
    if (this.state.selectedGroupList.length > 0) {
      const isGroupAlreadyAdded = this.state.selectedGroupList.filter(
        (g) => g.id === group.option.value.id
      );

      if (isGroupAlreadyAdded.length > 0) {
        return;
      }
    }

    this.setState({
      selectedGroupList: [...this.state.selectedGroupList, group.option.value],
    });
  }

  removeGroup(group: Group) {
    this.setState({
      selectedGroupList: this.state.selectedGroupList.filter(
        (g) => g !== group
      ),
    });
  }

  setSelectedGroups(resourceGroups: Group[]) {
    this.setState({
      selectedGroupList: resourceGroups,
    });
  }

  fetchUserGroups() {
    this.octopusConnect
      .loadCollection("groups")
      .pipe(
        map((dataCollection: TypedDataCollectionInterface<Group>) => {
          const userGroups: Group[] = dataCollection.entities.map((data) => ({
            id: data.id,
            label: data.attributes.label,
          }));
          return userGroups;
        })
      )
      .subscribe({
        next: (userGroups) => {
          this.setState({ groupList: userGroups });
        },
      });
  }

  validate(resource: DataEntity) {
    const currentResourceDataEntity = new DataEntity(
      "granule",
      resource.attributes,
      this.octopusConnect,
      resource.id
    );

    currentResourceDataEntity.set(
      "groups",
      this.state.selectedGroupList.map((group) => group.id)
    );
    currentResourceDataEntity.save().subscribe({
      next: (_) => {
        // On doit refresh la liste car on vient de modifier un élément de celle-ci
        this.corpusService.refreshCorpus.next(true);
      },
    });
  }
}