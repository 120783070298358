import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';

@Component({
    selector: 'app-activity-editor-text-input',
    templateUrl: './activity-editor-text-input.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActivityEditorTextInputComponent implements OnInit, OnChanges {
    @Input() public control!: FormControl;
    @Input() public characterLimit: number;
    @Input() public icon: string;
    @Input() public placeholder: string;
    @Input() public isAnAbsoluteLimit = true;
    @Input() public isWysiwyg = false;
    @Output() public isWysiwygValidityChange = new EventEmitter<boolean>();
    private isWysiwygValidityChangeDistinct = new Subject<boolean>();

    public availableCharacters: number;

    public ngOnInit(): void {
        this.updateControlValidity();
        this.updateCharacterLimitInformations();

        this.isWysiwygValidityChangeDistinct.pipe(
            distinctUntilChanged()
        ).subscribe((isValid: boolean) => {
            this.isWysiwygValidityChange.emit(isValid);
        });

    }

    public ngOnChanges(): void {
        this.updateControlValidity();
        this.updateCharacterLimitInformations();
    }

    // Logic

    private updateControlValidity(): void {
        if (this.characterLimit !== undefined && !!this.control) {
            // add validator
            const validators = this.control.validator ? [this.control.validator] : [];
            validators.push(Validators.maxLength(this.characterLimit));
            this.control.setValidators(validators);
        }
    }

    private updateCharacterLimitInformations(): void {
        if (this.characterLimit !== undefined && !!this.control) {
            this.availableCharacters = this.characterLimit - (this.control.value?.length || 0);

            this.control.valueChanges.pipe(
                filter(() => !this.isWysiwyg))
            .subscribe((value: string) => {
                this.availableCharacters = this.characterLimit - value.length;

                if (this.isAnAbsoluteLimit && this.availableCharacters < 0) {
                    this.control.setValue(value.slice(0, this.characterLimit));
                }
            });
        }
    }

    public onWordCountUpdate($event: { words: number; characters: number }): void {
        this.availableCharacters = this.characterLimit - $event.characters
        this.isWysiwygValidityChangeDistinct.next(this.availableCharacters >= 0);
    }
}
