import {Component, Input, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {AuthenticationService} from '@modules/authentication';
import {ReplaySubject, combineLatest, Observable} from 'rxjs';
import {fuseAnimations} from 'fuse-core/animations';
import {localizedDate, localizedTime} from 'shared/utils/datetime';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
  selector: 'app-widget-assignments-group',
  templateUrl: './widget-assignments-group.component.html',
  styleUrls: ['./widget-assignments-group.component.scss'],
  animations: fuseAnimations
})
export class WidgetAssignmentsGroupComponent implements OnInit {

    @Input() assignmentsGroupsSubscription: ReplaySubject<any>;

    public displayedColumns: string[] = ['type', 'title', 'start_date', 'end_date', 'group', 'workgroup', 'learners', 'progress'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();

    entities: any;
    user: any;
    resource: boolean;
    private groups: DataEntity[];
    private workGroups: DataEntity[];



    constructor(
        public authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): any {
        this.assignmentsGroupsSubscription.subscribe((resources) => {
            this.dataSource.data = resources.slice(0, 5);
            this.resource = resources && resources.length;
        });

        const groupListSubject = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');

        const workgroupListSubject: Observable<DataEntity[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');

        combineLatest(groupListSubject, workgroupListSubject).subscribe((resp: [DataEntity[], DataEntity[]]) => {
            this.groups = resp[0];
            this.workGroups = resp[1];
        });
    }

    public countAssignments(entity): number | string {
        if (entity) {
            let i = 0;
            for (const data in entity.get('assignations')) {
                i++;
            }
            return i;
        }
        return '';
    }

    public getProgress(entity): number | string {
        const reducer = (accumulator, currentValue) => (accumulator + currentValue);
        const allProgress = [];
        if (entity) {
            for (const data in entity.get('assignations')) {
                const progress = entity.get('assignations')[data]['progress'] === undefined ? 0 : entity.get('assignations')[data]['progress'];
                allProgress.push(Math.floor(+ progress));
            }
            if (allProgress.length) {
                return Math.round(allProgress.reduce(reducer) / allProgress.length);
            }
        }
        return 0;
    }

    openAssignmentsGroup(entity): void {
        if (entity) {
            const data = {
                title: entity.get('lesson'),
                type: entity.get('type_term').label
            };
            this.router.navigate(['../', 'followed', 'list'], { relativeTo: this.route, queryParams: data});
        }
    }

    localeDate(date: number): string {
        if (+date) {
            return localizedDate(date);
        }
    }

    localeTime(date: number): string {
        if (+date) {
            return localizedTime(date);
        }
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return this.resource;
    }

    filterGroupsOnly(groupIds: string[]): any {
        return this.groups.filter(g => groupIds.includes(g.id.toString()))
            .map(g => g['groupname'])
            .join(', ');
    }

    filterWorkGroupsOnly(groupIds: string[]): any {
        return this.workGroups.filter(g => groupIds.includes(g.id.toString()))
            .map(g => g['workgroupname'])
            .join(', ');
    }
}