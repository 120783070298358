import {Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-groups-custom-action',
    templateUrl: './groups-custom-action.component.html',
    styleUrls: ['./groups-custom-action.component.scss']
})
export class GroupsCustomActionComponent implements OnInit {
    title: string;
    views: [{'room': object, 'subject': object, 'params': object}];
    actionsButton: [{'room': object, 'subject': object, 'params': object}];
    username: string;

    constructor(public dialogRef: MatDialogRef<GroupsCustomActionComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.username = '';
        let views = _.cloneDeep(data.views);
        let actionsButton = _.cloneDeep(data.actionsButton);

        if (data.item) {
            this.username = data.item.username;
            for (let i = 0; i < views.length; i++) {
                for (let key in views[i].params) {
                    views[i].params[key] = views[i].params[key].replace('{userid}', data.item.id);
                }
            }
            for (let i = 0; i < actionsButton.length; i++) {
                for (let key in actionsButton[i].params) {
                    actionsButton[i].params[key] = actionsButton[i].params[key].replace('{userid}', data.item.id);
                }
            }
        }

        this.title = data.title.replace('{user}', this.username);
        this.views = views;
        this.actionsButton = actionsButton;
    }

    ngOnInit() {
    }

}