import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from '@angular/core';
import {combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LessonsService} from '@modules/activities';
import {LessonGranuleEntity, LessonReference, NavigateToLessonOptions} from '@modules/activities/core/models';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {ChapterEntity, ChaptersService} from 'fuse-core/services/chapters.service';
import {usingLoading} from 'shared/utils/rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {UserDataEntity} from '@modules/authentication';
import {FilterData} from 'octopus-connect/lib/models/types';
import {DataEntity} from 'octopus-connect';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {TypedDataCollectionInterface, TypedDataEntityInterface} from 'shared/models/octopus-connect';


interface LocalAssignationAttributes {
    'assignated_node': {
        id: string | number,
    },
    'config': string,
}

type LocalAssignationCollection = TypedDataCollectionInterface<LocalAssignationAttributes>

type LocalAssignationEntity = TypedDataEntityInterface<LocalAssignationAttributes>;

type AssignationState = TypedDataEntityInterface<{
    label: string
}>

@Component({
    selector: 'app-lessons-in-chapters-list',
    templateUrl: './lessons-in-chapters-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonsInChaptersListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @HostBinding('class') hostClass: string = 'english';

    public readonly parentPageUrl = '../..';
    public isLessonsLoading$ = new Subject<boolean>();
    public isChapterLoading$ = new Subject<boolean>();
    private lessonsService = inject(LessonsService);
    private chaptersService = inject(ChaptersService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private communicationCenter = inject(CommunicationCenterService);
    private conf = inject(LessonsConfigurationService);

    private readonly routeParams$ = this.route.params.pipe(takeUntil(this.unsubscribeInTakeUntil));
    private readonly conceptId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.conceptId)
    );
    private readonly educationalLevelId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.gradeId)
    );
    private readonly chapterId$: Observable<number> = this.routeParams$.pipe(
        map(params => params.chapterId)
    );
    public readonly chapter$ = this.chapterId$.pipe(
        mergeMap(chapterId => this.loadChapter(chapterId)),
        usingLoading(this.isChapterLoading$)
    );
    public readonly chapterName$ = this.chapter$.pipe(map(chapter => chapter.get('name')));
    private readonly lessonsFilters$ = combineLatest([
        this.educationalLevelId$,
        this.conceptId$,
        this.chapterId$,
    ]);
    public readonly lessons$ = this.lessonsFilters$.pipe(
        mergeMap(([educationalLevelId, conceptId, chapterId]) =>
            this.loadLessons(educationalLevelId, conceptId, chapterId)),
        usingLoading(this.isLessonsLoading$)
    );
    private readonly user$ = this.communicationCenter
        .getRoom('authentication')
        .getSubject<UserDataEntity>('userData')
        .pipe(
            filter(userData => !!userData),
        );
    private readonly assignationTypes$ = this.communicationCenter
        .getRoom('assignment')
        .getSubject<{
            id: string | number,
            label: string
        }[]>('assignationTypes');

    private readonly autoAssignmentType$ = this.assignationTypes$.pipe(
        map(assignationTypes =>
            assignationTypes.find(assignationType => assignationType.label === 'auto')
        ),
        filter(assignationType => !!assignationType),
    );

    private readonly assignmentStates$ = this.communicationCenter
        .getRoom('assignments')
        .getSubject<AssignationState[]>('statesList');

    private readonly assignatedState$ = this.assignmentStates$.pipe(
        map(assignatedStates =>
            assignatedStates.find(assignationState => assignationState.get('label') === 'assigned')
        ),
        filter(assignationState => !!assignationState),
    );

    public readonly lessonsWithAssignments$ = this.lessons$.pipe(
        mergeMap(lessons => combineLatest([
            of(lessons),
            this.user$,
            this.autoAssignmentType$,
        ])),
        mergeMap(([lessons, user, autoAssignmentType]) =>
            combineLatest(lessons.map(lesson =>
                    this.loadAutoAssignmentsForLessonsOfCurrentUser(lesson, user, autoAssignmentType)
                        .pipe(
                            map(assignments => ({
                                    lesson,
                                    assignment: assignments.entities.length > 0 ? assignments.entities[0] : null,
                                    assignments: assignments.entities.length ? assignments.entities : null
                                })
                            )
                        )
                )
            )
        )
    );
    private readonly loadPaginatedAssignmentsCallBack$ = this.communicationCenter
        .getRoom('assignment')
        .getSubject<(filters: FilterData) => Observable<LocalAssignationCollection>>('loadPaginatedAssignmentsCallback');

    public getChildrenAsButtons(batch: {
        lesson: LessonGranuleEntity,
        assignment: LocalAssignationEntity
        assignments: LocalAssignationEntity[]
    }) {
        return batch.lesson.get('reference').map((child, index) => ({
            title: child.title,
            action: ($event: Event) => {
                $event.stopPropagation();
                this.openLesson(batch, index);
            },
            score: this.getLessonProgress(batch.assignments, child)
        }));

    }

    ngOnInit(): void {
        this.init();
        this.communicationCenter.getRoom('lessonList').getSubject('navigate').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(
            res => {
                if (res) {
                    this.communicationCenter.getRoom('lessonList').getSubject('navigate').next(false);
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.router.navigate(res);  // Navigue de nouveau vers la nouvelle route lancé depuis le footer fais ici
                                                    // car sans ça ne se reset pas ou fais des boucles infini
                    });
                }
            });
    }

    private init() {
        this.lessonsFilters$.pipe(take(1)).subscribe(res => {
            // store data for button in footer
            this.communicationCenter.getRoom('currentContext').getSubject('gradeConceptChapter').next({grade: res[0], concept: res[1], chapter: res[2]});
        });

        this.conceptId$.pipe(take(1)).subscribe(conceptId => {
            this.hostClass = this.conf.periodBackGroundToUse(conceptId.toString());
        });
    }

    private loadLessons(educationalLevelId: number, conceptId: number, chapterId: number): Observable<LessonGranuleEntity[]> {
        const optionsInterface = {
            filter: {
                level: educationalLevelId,
                chapters: chapterId,
                concepts: conceptId,
            },
            page: 1, // On veut le début de la liste
            range: 50 // Et on espère qu'il n'y ai pas plus de 50 leçons
        };

        const allLessons = 'byRole'; // Seulement les parcours modèles
        const byRolesCreatorRoleIds = this.lessonsService.getAllowedRoleIdsForModelsCreation();

        return this.lessonsService
            .loadPaginatedLessons(allLessons, byRolesCreatorRoleIds, '', optionsInterface)
            .pipe(takeUntil(this.unsubscribeInTakeUntil));
    }

    private loadChapter(chapterId: number): Observable<ChapterEntity> {
        return this.chaptersService.getChapter(chapterId);
    }

    private createAssignment(lesson: LessonGranuleEntity) {
        return combineLatest([
            this.user$,
            this.autoAssignmentType$,
            this.assignatedState$,
        ]).pipe(
            map(([user, autoAssignmentType, assignedAssignmentState]) => ({
                assignated_node: lesson.id,
                assignated_user: user.id,
                assignator: user.id,
                dates: {value: 0, value2: 0},
                state_term: assignedAssignmentState.id,
                type_term: autoAssignmentType.id,
            })),
            mergeMap(createAssignmentData => this.communicationCenter
                .getRoom('assignment')
                .getSubject('createSimpleAssignment')
                .pipe(
                    mergeMap((createAssignmentFunction: (data) => Observable<LocalAssignationEntity>) => createAssignmentFunction(createAssignmentData))
                )
            )
        );
    }

    private openLesson(batch: {
        lesson: LessonGranuleEntity;
        assignment: LocalAssignationEntity
    }, stepIndex) {
        const navigateToLessonOptions: NavigateToLessonOptions = {
            startOnStepIndex: stepIndex,
            exitLessonUrl: null
        };

        const assignment$ = !!batch.assignment ? of(batch.assignment) : this.createAssignment(batch.lesson);

        assignment$.pipe(
            take(1),
            map(assignment => this.lessonsService.loadAndNavigateToLessonFromAssignment(assignment, navigateToLessonOptions))
        ).subscribe();
    }

    private loadAutoAssignmentsForLessonsOfCurrentUser(lesson: LessonGranuleEntity, user: UserDataEntity, autoAssignmentType: {
        id: string | number
    }) {
        return this.loadPaginatedAssignmentsCallBack$.pipe(
            switchMap(callback => callback({
                assignated_user: user.id,
                //assignments_type: autoAssignmentType.id,
                assignated_lesson_id: lesson.id
            }))
        );
    }

    private getLessonProgress(assignments: LocalAssignationEntity[], child: LessonReference): number {
        if (!assignments || !assignments.length) {
            return 0;
        }
        return assignments.reduce((maxProgress, assignment) => {
            if (!assignment) {
                return maxProgress;
            }

            let config;
            try {
                config = JSON.parse(assignment.get('config') || '{}');
            } catch (e) {
                console.error('Error parsing assignment config', e);
                return maxProgress;
            }

// Mise à jour de `maxProgress` si la condition est remplie.
            const score = config[child.id]?.score;
            return score > maxProgress ? score : maxProgress;
        }, 0);
    }
}
