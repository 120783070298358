import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-idea-edition-modal',
    templateUrl: './idea-edition-modal.component.html',
    styleUrls: ['./idea-edition-modal.component.scss']
})
export class IdeaEditionModalComponent implements OnInit {

    @ViewChild('textIdea', { static: true }) textIdea: MatInput;

    ideaForm: UntypedFormGroup;
    text: string;
    type: string;
    phase: string;
    placeholderText: string;

    constructor(
        public dialogRef: MatDialogRef<IdeaEditionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService
    ) {}

    ngAfterViewInit() {
        setTimeout(() => { // TODO - Attendre proprement la fin du check et eviter un timeout
            this.textIdea.focus();
        });
    }

    ngOnInit(): void {
        this.text = this.data['text'] || '';
        this.type = this.data['type'];
        this.phase = this.data['phase'];

        this.translate.get(['ideas-wall.idea-text', 'ideas-wall.category-name']).subscribe((resp: Object) => {
            if (this.type === 'idea') {
                this.placeholderText = resp['ideas-wall.idea-text'];
            } else if (this.type === 'category') {
                this.placeholderText = resp['ideas-wall.category-name'];
            }
        });



        this.ideaForm = this.formBuilder.group({
            text: [this.text]
        });
    }

    validate(): void {
        this.dialogRef.close(this.text);
    }
}