import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonHelpComponent } from "fuse-core/components/button-help/button-help.component";
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from "@ngx-translate/core";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [ButtonHelpComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule
    ],
    exports: [
        ButtonHelpComponent
    ]

})
export class ButtonHelpModule { }