import {ForbiddenPathInterface, IsUserAuthorisedByRoleGuard} from '../../../guards/is-user-authorised-by-role.guard';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthenticationService} from '@modules/authentication';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {DashWidgetModule} from 'fuse-core/components/dash-widget/dash-widget.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardService} from './services/dashboard.service';
import {DataEntity} from 'octopus-connect';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {FuseSharedModule} from '@fuse/shared.module';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SharedModule} from 'shared/shared.module';
import {WidgetAssignmentsGroupComponent} from '@modules/dashboard/core/widgets/widget-assignments-group/widget-assignments-group.component';
import {WidgetFormModelsComponent} from '@modules/dashboard/core/widgets/widget-form-models/widget-form-models.component';
import {WidgetGroupComponent} from './widgets/widget-group/widget-group.component';
import {WidgetGroupService} from '@modules/dashboard/core/widgets/widget-group/widget-group.service';
import {WidgetMessagingComponent} from './widgets/widget-messaging-thread/widget-messaging-thread.component';
import {WidgetNewsComponent} from './widgets/widget-news/widget-news.component';
import {WidgetProjectComponent} from './widgets/widget-project/widget-project.component';
import {WidgetProjectService} from '@modules/dashboard/core/widgets/widget-project/widget-project.service';
import {WidgetResearchSheetModelsComponent} from '@modules/dashboard/core/widgets/widget-research-sheet-models/widget-research-sheet-models.component';
import {WidgetWorkgroupComponent} from '@modules/dashboard/core/widgets/widget-workgroup/widget-workgroup.component';

const routes: Routes = [
    {
        path: 'dashboard',
        canActivate: [IsUserLogged, IsUserAuthorisedByRoleGuard],
        component: DashboardComponent,
    },
    {
        path: 'favoris',
        canActivate: [IsUserLogged, IsUserAuthorisedByRoleGuard],
        component: DashboardComponent,
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        DashWidgetModule,
        MatSnackBarModule,
    ],
    declarations: [
        DashboardComponent,
        WidgetProjectComponent,
        WidgetGroupComponent,
        WidgetResearchSheetModelsComponent,
        WidgetFormModelsComponent,
        WidgetWorkgroupComponent,
        WidgetAssignmentsGroupComponent,
        WidgetMessagingComponent,
        WidgetNewsComponent,
    ],
})
export class DashboardModule {
    private static isMenuSet = false;
    private isUserAuthorisedByRoleGuardForbiddenPaths: ForbiddenPathInterface[] = [];

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private dashboardService: DashboardService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter.getRoom('news').next('newsListComponent', WidgetNewsComponent);
    }

    static forRoot(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [
                DashboardService,
                WidgetGroupService,
                WidgetProjectService
            ]
        };
    }

    private postLogout(): void {
        DashboardModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'dashboard');
        this.dynamicNavigation.clearMenuItem('level0', 'favoris');
        this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').next('remove', this.isUserAuthorisedByRoleGuardForbiddenPaths);

    }

    private postAuthentication(): void {
        const isUserAuthorisedByRoleGuardForbiddenPaths = this.getForbiddenPathObject(this.authService.accessLevel);
        if (isUserAuthorisedByRoleGuardForbiddenPaths.length > 0) {
            this.isUserAuthorisedByRoleGuardForbiddenPaths = isUserAuthorisedByRoleGuardForbiddenPaths;
            this.communicationCenter.getRoom('IsUserAuthorisedByRoleGuard').next('add', isUserAuthorisedByRoleGuardForbiddenPaths);
        }

        const isGarAndLearner = this.authService.isGAR() && this.authService.accessLevel === 'learner';
        if (
          !DashboardModule.isMenuSet &&
          this.authService.isAuthenticated &&
          !isGarAndLearner
        ) {
          // redirect dashboard'url to basic page url
          this.dynamicNavigation.registerModuleMenu("level0", {
            id: "dashboard",
            title: "Tableau de bord",
            translate: "navigation.dashboard",
            type: "item",
            icon: "dashboard",
            url: "/dashboard",
          });

            this.dynamicNavigation.registerModuleMenu("level0", {
                id: "favoris",
                title: "Favoris",
                translate: "generic.favoris",
                type: "item",
                icon: "dashboard",
                url: "/favoris",
            });

          DashboardModule.isMenuSet = true;
        }
    }

    private getForbiddenPathObject(accessLevel: string): ForbiddenPathInterface[] {
        const forbiddenPaths = this.authService.getRoleAssociatedValueOrDefault(this.dashboardService.getForbiddenPaths());
        return forbiddenPaths.map((fp, index) => ({
            identifier: `dashboard_${accessLevel}_${index}`,
            path: fp
        }));
    }
}