import {Structures} from 'octopus-model';

export const accountManagement = {
    canEditAvatar: [
        // nobody can edit avatar after create account
    ],
    canEditYouAre: false,
    canJoinGroup: ['learner'],
    allowedMaxSize: 1,
    displayDialogMinorAdult: false,
    displayFilters: false,
    fields: {
        learner: [
            'label|required',
            'email|required',
            'password|required',
            'passwordConfirm|required',
        ],
        default: [
            'you_are|required',
            'label|required',
            'password|required',
            'passwordConfirm|required',
            'email',
            'level|required',
            'region|required',
            'newsletter',
            'termsMinor|required'
        ], // label = pseudo
    },
    floatLabelControl: 'always', // 'always' | 'never' | 'auto'
    selfSignup: true,
    passwordPolicy: {
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    },
    youAreValues: ['teacher', 'parent', 'teen', 'other'],
    validateEmailStrategyActivated: false,
    validateEmailStrategyMode: 'page',
    editableFields: {
        trainer: ['label', 'email', 'password', 'you_are', 'picture', 'newsletter'],
        default: ['label', 'email', 'password', 'you_are', 'picture', 'level', 'newsletter'],
    },
    // si le select educationalLevel est caché dans le formulaire d'inscription car l'utilisateur indique qu'il est un 'teacher'
    isEducationalLevelSelectionHiddenIfUserIsTeacher: true,
    showJoinGroup: true, // part of code to join or leave a school
    allowedMultiplesGroups: true,
    useleaveGroupEvent: true,
    useSpecificDateForGarLicence: true,
    emailRequiredConditionnal: true,

};
