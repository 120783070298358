import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    ButtonListDialogComponent
} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-dynamic-wrapper',
    templateUrl: './dynamic-wrapper.component.html',
    styleUrls: ['./dynamic-wrapper.component.scss']
})
export class DynamicWrapperComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public  data: {[key: string]: any}, public ref: MatDialogRef<ButtonListDialogComponent>,) { }

    ngOnInit(): void {
        if (this.data.outputEvent as EventEmitter<any>) {
            this.data.outputEvent
                .pipe(
                    tap((value) => this.close(value))
                )
                .subscribe();
        }
    }

    public close(value?): void {
        this.ref.close(value)
    }

}