import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';

export interface ErrorReportingFormDataInterface {
    formValues: {[key: string]: any};
    onFormSubmitComplete: Subject<void>;
}

export interface ErrorReportingDataInterface {
    reportingType: string;
    defaultValues: { [key: string]: string };
    onFormSubmitObs: Subject<ErrorReportingFormDataInterface>;
}

@Component({
    selector: 'app-error-reporting-modal',
    templateUrl: './error-reporting-modal.component.html',
    styleUrls: ['./error-reporting-modal.component.scss']
})
export class ErrorReportingModalComponent implements OnInit {

    public reportingForm: UntypedFormGroup;
    public isFormPending = false;
    public isFormOnError = false;
    public title: string;

    constructor(public dialogRef: MatDialogRef<ErrorReportingModalComponent>,
                @Inject(MAT_DIALOG_DATA) public injectedData: ErrorReportingDataInterface,
    private fb: UntypedFormBuilder) { }

    ngOnInit(): void {
        this.generateFormGroup();
    }

    private generateFormGroup(): void {
        this.reportingForm = this.fb.group({
            mail: [this.getValueOrDefault('mail'), Validators.required],
            subject: [this.getValueOrDefault('subject'), Validators.required],
            message: [this.getValueOrDefault('message')],
            objectTitle: [this.getValueOrDefault('objectTitle')]
        });

        if (this.injectedData.reportingType === 'exercise') {
            this.reportingForm.setControl('exerciseId', new UntypedFormControl(this.getValueOrDefault('exerciseId')));
        } else if (this.injectedData.reportingType === 'resource') {
            this.reportingForm.setControl('resourceId', new UntypedFormControl(this.getValueOrDefault('resourceId')));
        } else {
            throw new Error('Reporting type is not implemented');
        }

        this.reportingForm.get('objectTitle').disable();
    }

    private getValueOrDefault(key: string, _default= ''): any {
        return (this.injectedData.defaultValues && this.injectedData.defaultValues.hasOwnProperty(key)) ? this.injectedData.defaultValues[key] : _default;
    }

    public submitForm(): void {
        // Callback d'erreur
        const errCb = (err: Error) => {
            console.error(err);
            this.isFormOnError = true;
            this.isFormPending = false;
        };

        // Callback de success
        const successCb = () => {
            this.dialogRef.close(true);
        };

        // Ceinture et bretelle : le form est valid ?
        if (this.reportingForm.valid) {

            // Mettre le loader
            this.isFormPending = true;
            this.reportingForm.disable();

            // Appel
            const onSubmitComplete = new Subject<void>();

            onSubmitComplete.subscribe(
                () => successCb(),
                error => errCb(error)
            );

            this.injectedData.onFormSubmitObs.next({
                formValues: this.reportingForm.value,
                onFormSubmitComplete: onSubmitComplete
            });
        }
    }
}