import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {OnlyImageFileMimes} from 'shared/media/media.service';
import {FileData} from 'shared/models';

export type FlashcardData = {
    image: FileData<OnlyImageFileMimes> | null;
    audio: FileData<'audio/mpeg'> | null;
    text: {
        control: FormControl;
        characterLimit?: number;
        icon?: string;
        placeholder?: string;
        isAnAbsoluteLimit?: boolean;
    }
}

@Component({
    selector: 'app-activity-edition-flashcard-edit',
    templateUrl: './activity-edition-flashcard-edit.component.html',
    styleUrls: ['./activity-edition-flashcard-edit.component.scss']
})
export class ActivityEditionFlashcardEditComponent {
    @Input() public flashcardData!: FlashcardData;

    @Output() public flashcardDataChange = new EventEmitter<FlashcardData>();
    @Output() public mediaSoftDelete = new EventEmitter<FileData<OnlyImageFileMimes | 'audio/mpeg'>>();

    public onImageChange($event: FileData<OnlyImageFileMimes>): void {
        this.flashcardData.image = $event;
        this.flashcardDataChange.emit(this.flashcardData);
    }

    public onAudioChange($event: FileData<'audio/mpeg'>): void {
        this.flashcardData.audio = $event;
        this.flashcardDataChange.emit(this.flashcardData);
    }

    public onMediaSoftDelete($event: FileData<OnlyImageFileMimes | 'audio/mpeg'>): void {
        this.mediaSoftDelete.emit($event);
    }
}
