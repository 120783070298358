import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DateAdapter} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AssignationService} from '../../services/assignation.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith, take, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {localizedDate} from 'shared/utils/datetime';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {GenericContextualService} from "fuse-core/services/generic-contextual.service";
import {AutoUnsubscribeTakeUntilClass} from "shared/models/auto-unsubscribe-take-until.class";
import {ContextualService} from "@modules/assignation/core/services/contextual.service";
import {AssignmentComponentsService} from "@modules/assignation";

@Component({
    selector: 'fuse-app-assignation',
    templateUrl: './assignation.component.html',
    styleUrls: ['./assignation.component.scss']
})
export class AssignationComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public entityForm: UntypedFormGroup;
    public entityFormErrors: any;
    public groupsChips: any[] = [];
    public groupsCtrl = new UntypedFormControl();
    public groupsAddOnBlur = false;
    public groupsSeparatorKeysCodes = [ENTER, COMMA];
    public groupsSelectable = true;
    public groupsRemovable = true;
    public groupsFilteredChips: Observable<any[]>;
    public learnersChips = []; // array of workgroup Chips selected
    public learnersCtrl = new UntypedFormControl();
    public commentCtrl = new UntypedFormControl();
    public learnersAddOnBlur = false;
    public learnersSeparatorKeysCodes = [ENTER, COMMA];
    public learnersSelectable = true;
    public learnersRemovable = true;
    public learnersFilteredChips: Observable<any[]>;
    public projectsList;
    public workgroupsChips = []; // array of workgroup Chips selected
    public workgroupsCtrl = new UntypedFormControl();
    public workgroupsAddOnBlur = false;
    public workgroupsSeparatorKeysCodes = [ENTER, COMMA];
    public workgroupsSelectable = true;
    public workgroupsRemovable = true;
    public workgroupsFilteredChips: Observable<any[]>;
    public disableTypeSelect: boolean = false;
    public typeObjectValue: any[] = [];
    public assignationObjects: DataEntity[];
    public selectedLesson: DataEntity;

    private allTypes: any[] = [];
    private entity: any;
    private groupsAllChips = [];
    private initialDate: any;
    private learnersAllChips = [];
    private maxDueTime: string;
    private maxStartDate: any;
    private maxStartTime: string;
    private minStartDate: any;
    private minDueDate: any;
    private minDueTime: string;
    private translatedTermAll: string;
    private workgroupsAllChips = [];

    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;
    @ViewChild('learnersChipInput') learnersChipInput: ElementRef;
    @ViewChild('startDateInput') startDateInput: ElementRef;

    constructor(
        public assignationConfiguration: AssignationConfigurationService,
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService,
        private adapter: DateAdapter<any>,
        private formBuilder: UntypedFormBuilder,
        public assignationService: AssignationService,
        public assignmentComponentService: AssignmentComponentsService,
        public dialogRef: MatDialogRef<AssignationComponent>,
        public dialog: MatDialog,
        public authService: AuthenticationService,
        private genericContextualService: GenericContextualService,
        private contextualService: ContextualService,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        super();

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('projectsList')
            .subscribe((projectsList) => {
                this.projectsList = projectsList.filter(project => !project.archived);
            });

        this.entity = {
            nodeId: +data.nodeId
        };

        this.assignationObjects = data.activities;

        const formFields = ['learners'];

        if (this.hasProject) {
            formFields.push('project');
        }

        if (this.hasType) {
            formFields.push('type');
        }

        formFields.push('rating_base');
        formFields.push('startDate');
        formFields.push('startTime');
        formFields.push('dueDate');
        formFields.push('dueTime');

        formFields.push('nodeId');
        formFields.push('group');
        formFields.push('comment');

        formFields.push('selectedLesson');
        formFields.push('selectedActivities');

        this.initialDate = this.adapter.today();
        this.entityFormErrors = {
            dueTime: {}
        };
        this.entityForm = this.createEntityForm(formFields);
        this.entityForm.get('comment').setValidators([Validators.minLength(0), Validators.maxLength(this.assignationService.settings.maxCommentLenght ? this.assignationService.settings.maxCommentLenght : 280)]);

        this.entityForm.get('group').setValue([]);
        this.entityForm.get('learners').setValue([]);

        if (this.assignationConfiguration.useDefaultStartDate()) {
            this.entityForm.get('startDate').setValue(this.initialDate);
        }

        // disable assignation type selectlist if the assignation type is given by the lesson
        if (this.data.node && this.data.node.get('metadatas') && this.data.node.get('metadatas').assignation_type && this.data.node.get('metadatas').assignation_type.id) {
            this.disableTypeSelect = true;
        }

        this.entityForm.get('startDate').valueChanges.subscribe((startDate) => {
            if (startDate) {
                this.minDueLimit = new Date(+startDate.format('x'));
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000) && this.dueTime) {
                    this.maxStartSetTime = this.dueTime;
                } else {
                    this.maxStartSetTime = null;
                }
            }
        });
        this.entityForm.get('dueDate').valueChanges.subscribe((dueDate) => {
            if (dueDate) {
                this.maxStartLimit = new Date(+dueDate.format('x'));
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000) && this.startTime) {
                    this.minDueSetTime = this.startTime;
                    this.timeError();
                } else {
                    this.minDueSetTime = null;
                }
            }
        });
        this.entityForm.get('startTime').valueChanges.subscribe((startTime) => {
            if (startTime) {
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000)) {
                    this.minDueSetTime = startTime;
                }
            }
        });
        this.entityForm.get('dueTime').valueChanges.subscribe((dueTime) => {
            if (dueTime) {
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000)) {
                    this.maxStartSetTime = dueTime;
                }
            }
        });
        this.entityForm.valueChanges.subscribe(() => {
            const controlType = this.entityForm.get('type');
            const controlRatingBase = this.entityForm.get('rating_base');
            const controlStartDate = this.entityForm.get('startDate');
            const controlStartTime = this.entityForm.get('startTime');
            const controlDueDate = this.entityForm.get('dueDate');
            const controlDueTime = this.entityForm.get('dueTime');
            if (controlType && controlType.valid) {
                this.typeObjectValue = this.allTypes.filter((type) => type.id === controlType.value);
                if (this.typeObjectValue.length > 0 && this.typeObjectValue[0].label !== 'training') {
                    if (this.typeObjectValue[0].label === 'assessment') {
                        controlStartTime.setValidators(Validators.required);
                        controlDueTime.setValidators(Validators.required);
                    } else {
                        controlStartTime.setErrors(null);
                        controlDueTime.setErrors(null);
                        controlStartTime.setValidators(null);
                        controlDueTime.setValidators(null);
                    }
                    controlRatingBase.setValidators(Validators.required);
                    controlStartDate.setValidators(Validators.required);
                    controlDueDate.setValidators(Validators.required);

                } else {

                    if (controlStartDate.value === null) { // if no StartDate
                        this.entityForm.patchValue({startDate: this.initialDate}); // set today as start date for training
                    }
                    controlRatingBase.setErrors(null);
                    controlStartDate.setErrors(null);
                    controlStartTime.setErrors(null);
                    controlDueDate.setErrors(null);
                    controlDueTime.setErrors(null);

                    controlStartDate.setValidators(Validators.required);
                    controlStartTime.setValidators(null);
                    controlDueDate.setValidators(null);
                    controlDueTime.setValidators(null);
                }
            }
        });
    }

    ngOnInit(): void {
        this.minDueLimit = new Date(Date.now());
        this.minStartLimit = new Date(Date.now());
        this.translate.get('generic.all').subscribe((translation: string) => this.translatedTermAll = translation);
        this.translate.onLangChange.subscribe((data) => {
            this.translatedTermAll = data.translations.generic.all;
        });

        this.adapter.setLocale(this.translate.currentLang);

        this.learnersAllChips = this.assignationService.learnersList ? this.assignationService.learnersList : [];
        this.groupsAllChips = this.assignationService.groupsList ? this.assignationService.groupsList : [];
        this.workgroupsAllChips = this.assignationService.workgroupsList ? this.assignationService.workgroupsList : [];

        this.learnersFilteredChips = this.learnersCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && chip !== 'all' ?
                this.distinctFilter(this.filter(chip ? chip : '', 'learner'), 'learners') :
                this.distinctFilter(this.filterLearnersByGroupAndWGroups(this.learnersAllChips.slice()), 'learners')
            )
        );

        this.groupsFilteredChips = this.groupsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip ?
                this.distinctFilter(this.filter(chip ? chip : '', 'group'), 'group') :
                this.distinctFilter(this.groupsAllChips.slice(), 'group')
            )
        );


        this.workgroupsFilteredChips = this.workgroupsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip ?
                this.distinctFilter(this.filter(chip ? chip : '', 'workgroup'), 'workgroup') :
                this.distinctFilter(this.workgroupsAllChips.slice(), 'workgroup')
            )
        );

        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
            if (this.entityForm.get('type')) {
                // eslint-disable-next-line max-len
                this.entityForm.get('type').setValue(this.data.node && this.data.node.get('metadatas') && this.data.node.get('metadatas').assignation_type ? this.data.node.get('metadatas').assignation_type.id : '');
            }
        });

        this.assignmentComponentService.setAssignationComponentOpened();

        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                const data = response.getRawValue();

                data.type = this.typeObjectValue[0];

                // add default type if exists in settings
                if (this.assignationService.settings.defaultType !== null) {
                    data.type = this.allTypes.find((type) => type.label === this.assignationService.settings.defaultType);
                }

                const lessonType = !!data.type ? data.type.label : null;
                if (!this.assignationService.hasCompletionStartDateOnly(lessonType)) {
                    if (!this.assignationService.hasCompletionDate(lessonType)) {
                        if (!this.assignationService.hasCompletionTime(lessonType)) {
                            data.startTime = 0;
                            data.dueTime = 0;
                        }
                        data.startDate = 0;
                        data.dueDate = 0;
                    }
                }

                this.createAssignment(data);
            }

            this.assignmentComponentService.setAssignationComponentClosed();
        });
        this.entityForm.get('learners').setValidators(Validators.required);

        this.entityForm.get('selectedLesson').valueChanges.subscribe(value => {
            this.selectedLesson = value;
            const allValues = this.selectedLesson.get('reference').map(item => item.id);
            this.entityForm.controls.selectedActivities.setValue(allValues);
        });

        this.setupContextual();
    }

    private setupContextual(): void {
        this.contextualService.actionLessonAssign$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.assignLesson());

        this.genericContextualService.dataField$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({field, callback}) => {
                if (this[field + 'sAllChips']) {
                    callback(this[field + 'sAllChips'].map((element) => element.groupname || element.workgroupname || element.username || ''));
                }
            });
        this.genericContextualService.actionFilter$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(({field, value}) => {
                if (this[field + 'sAllChips']) {
                    const element = this[field + 'sAllChips'].find((element) => element.groupname === value || element.workgroupname === value || element.username === value);
                    this.selectChip(element, field);
                }
            });
    }

    /**
     * create assignment if assignment can only be do once by user a control is made before and
     * we remove learner already assigned
     * @param data data need to create assignment dataEntity contain array of learner
     */
    private createAssignment(data): void {
        if (this.assignationService.settings.isLessonCanBeAssignedOnlyOnceByUser) {
            const selectedLearnerIds = data.learners.map(d => d.id.toString());
            this.listOfUserAlreadyAssignedToThisLesson(selectedLearnerIds).subscribe(learnerAlreadyAssigned => {
                const learnerAlreadyAssignedIds = learnerAlreadyAssigned.map(a => a.attributes.assignated_user.uid.toString());
                const dataWithoutExisitngAssination = data.learners.filter(learner => !(learnerAlreadyAssignedIds.includes(learner.id.toString())));
                // keep only learner without previous assignment
                data.learners = dataWithoutExisitngAssination;
                if (learnerAlreadyAssigned.length > 0 && selectedLearnerIds.length >= 1) {
                    this.alertAlreadyAssignedLesson(learnerAlreadyAssigned);
                }
                this.assignationService.createAssignment(data);
            });
        } else {
            this.assignationService.createAssignment(data);
        }
    }

    /**
     * open an alert whith the list of learner already assigned who won't be reassigned
     * @param learnerAlreadyAssigned :  dataEntity of learners
     */
    private alertAlreadyAssignedLesson(learnerAlreadyAssigned): void {
        let userAlreadyAssigned = '<br>';
        learnerAlreadyAssigned.forEach(user => {
            userAlreadyAssigned = userAlreadyAssigned + user.attributes.assignated_user.name + '<br>';
        });

        let alertAlreadyAssigned = '';
        this.translate.get('generic.alert_already_assigned').subscribe((translation: string) => alertAlreadyAssigned = translation);

        let alertAlreadyAssignedLearner = '';
        this.translate.get('generic.alert_list_Learners_already_assigned_will_not_be_reassigned').subscribe((translation: string) => alertAlreadyAssignedLearner = translation);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: alertAlreadyAssigned,
            bodyDialog: alertAlreadyAssignedLearner + userAlreadyAssigned,
        };
        this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
    }

    private filter(name: string, type: string): Array<string> {
        const allChips = type + 'sAllChips';
        let chipName = type + 'name';
        if (type === 'learner') {
            chipName = 'username';
        }
        // because we use chiplist when we select an element of list we get an object
        // instead of a string like when we use auto completion value in this case is the final one no more filter need
        if (typeof name === 'string') {
            return this[allChips].filter(chip =>
                chip[chipName].toLowerCase().indexOf(name.toLowerCase()) !== -1);
        } else {
            return this[allChips];
        }
    }

    /**
     * Remove already selected items in chip list
     * @param items could be a learner, group or workgroup but type is defined by {field}
     * @param field type of item to filter (learner, group or workgroup)
     */
    private distinctFilter(items: any[], field: string): any[] {
        switch (field) {
            case 'workgroup':
                return items.filter(item => this.workgroupsChips.includes(item) === false);
            case 'group':
                return items.filter(item => this.groupsChips.includes(item) === false);
            case 'learners':
                return items.filter(item => this.learnersChips.includes(item) === false);
            default:
                break;
        }
    }

    /**
     * Remove a chip or all chips in the local chip list
     * @param chip if null, all chips will be remove
     * @param type field of chip (learner, group or workgroup)
     */
    public removeChip(chip: any | null, type: string): void {

        // remove chip from array of chips (group or workgroup)
        const chips = type + 'sChips';
        const index = this[chips].indexOf(chip);

        if (!chip) {
            this[chips] = [];
        } else {
            if (index >= 0) {
                this[chips].splice(index, 1);
            }

            this.fillInternalForm();
        }

        this[type + 'sCtrl'].setValue(null);
        // trigger the list refresh
        this.learnersCtrl.setValue(this.learnersCtrl.value, {emitEvent: true});
        this.blurAllChipsList();
    }

    public blurAllChipsList(): void {
        this.groupsChipInput?.nativeElement.blur();
        this.workgroupsChipInput?.nativeElement.blur();
        this.learnersChipInput?.nativeElement.blur();
    }

    public chipSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        this.selectChip(event.option.value, type);
    }

    private selectChip(value: any, type: string) {
        const chips = type + 'sChips';
        const input = type + 'sChipInput';
        const chipsCtrl = type + 'sCtrl';

        if (type === 'learner') {
            // Remove 'all' chip from selected if exist
            if (this[chips].indexOf('all') !== -1 || this[chips].indexOf(this.translatedTermAll) !== -1) {
                this.removeChip(this.translatedTermAll, 'learner');
            }

            // remove others chip if 'all' is selected
            if (this[chips].length && value === 'all') {
                this.removeChip(null, type);
            }

            // add new chip in chip list (could be 'all')
            this[chips].push(value === 'all' ? this.translatedTermAll : value);
        } else {
            this.removeChip(null, type);
            this[chips].push(value);
            this.learnersCtrl.setValue(null);
            this.learnersChips = [this.translatedTermAll];
        }
        this[chipsCtrl].setValue(null);
        this[input].nativeElement.value = '';
        this.fillInternalForm();
        this.blurAllChipsList();
    }

    private filterLearnersByGroupAndWGroups(learners: any[]): any[] {
        if (!Array.isArray(learners)) {
            return [];
        }

        let filteredList = learners;

        // There can be only one group.
        if (this.groupsChips.length === 1) {
            filteredList = filteredList.filter(item => item.groups.includes(this.groupsChips[0].groupname));
        }

        // There can be only one workgroup.
        if (this.workgroupsChips.length === 1) {
            filteredList = filteredList.filter(item => item.workgroups.includes(this.workgroupsChips[0].workgroupname));
        }

        return filteredList;
    }

    private fillInternalForm(): any {
        let learners = [];

        if (this.learnersChips.length && (
            this.learnersChips.indexOf('all') === -1 &&
            this.learnersChips.indexOf(this.translatedTermAll) === -1
        )
        ) {
            learners.push(...this.learnersChips);
        } else {
            learners = this.filterLearnersByGroupAndWGroups(this.learnersAllChips);
        }


        const controlLearners = this.entityForm.get('learners');
        if (learners.length) {
            controlLearners.reset([]);
            controlLearners.setValue(learners);
        } else {
            controlLearners.setValue([]);
            controlLearners.setErrors({learnersArrayEmpty: true});
        }

        this.entityForm.get('group').reset([]);
        this.entityForm.get('group').value.push(...this.groupsChips, ...this.workgroupsChips);
    }

    public displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let formFields = this.assignationService.settings.formFields[role];
        if (formFields === undefined) {
            formFields = this.assignationService.settings.formFields['default'];
        }
        return formFields.indexOf(name) > -1;
    }

    public get typeList(): any[] {
        return this.allTypes;
    }

    public get hasProject(): boolean {
        return this.projectsList && !!this.projectsList.length;
    }

    public get hasType(): boolean {
        return this.assignationService.settings.hasType;
    }

    public get hasDefaultType(): boolean {
        return this.assignationService.settings.defaultType !== null;
    }

    public get hasCompletionDate(): boolean {
        return this.assignationService.hasCompletionDate(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.typeObjectValue[0].label : '');
    }

    public get hasCompletionStartDateOnly(): boolean {
        // check if default type need start date
        let type;
        if (this.assignationService.settings.defaultType !== null) {
            type = this.allTypes.find((t) => t.label === this.assignationService.settings.defaultType);
            return type ? this.assignationService.hasCompletionStartDateOnly(type.label) : false;
        } else {
            type = this.typeObjectValue[0];
            return this.assignationService.hasCompletionStartDateOnly(this.entityForm && this.entityForm.value && this.entityForm.value.type ? type.label : '');
        }
    }

    public get hasCompletionTime(): boolean {
        return this.assignationService.hasCompletionTime(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.typeObjectValue[0].label : '');
    }

    public get hasGrade(): boolean {
        return this.assignationService.hasGrade(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.typeObjectValue[0].label : '');
    }

    public get ratingBaseList(): string[] {
        return this.assignationService.settings.ratingBase;
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    private createEntityForm(fields: string[]): UntypedFormGroup {
        const config = {};
        fields.forEach((field: string) => {
            config[field] = [this.entity[field]];
        });

        config['rating_base'] = [this.ratingBaseList[this.ratingBaseList.length - 1]];

        return this.formBuilder.group(config);
    }

    public assignLesson(): void {
        this.dialogRef.close(this.entityForm);
    }

    public set minDueLimit(data) {
        this.minDueDate = data;
    }

    public set minStartLimit(data) {
        this.minStartDate = data;
    }

    public get dueDateMinLimit(): any {
        return this.minDueDate;
    }

    public get startDateMinLimit(): any {
        return this.minStartDate;
    }

    public get inputStartDateValue(): any {
        return this.startDateInput && this.startDateInput.nativeElement.value && this.startDateInput.nativeElement.value !== '';
    }

    public set maxStartLimit(data) {
        this.maxStartDate = data;
    }

    public get maxDateLimit(): any {
        return this.maxStartDate;
    }

    public set maxStartSetTime(data) {
        this.maxStartTime = data;
    }

    public set minDueSetTime(data) {
        this.minDueTime = data;
    }

    public get minStartTimeLimit(): any {
        if (this.entityForm.get('startDate').value) {
            const limitTime = new Date(Date.now());
            const currentDate = this.entityForm.get('startDate').value;
            if (localizedDate(limitTime.getTime() / 1000) === localizedDate(currentDate.format('X'))) {
                return limitTime.getTime();
            }
        }
        return null;
    }

    public get maxStartTimeLimit(): any {
        return this.maxStartTime;
    }

    public get minDueTimeLimit(): any {
        return this.minDueTime;
    }

    public get maxDueTimeLimit(): any {
        return this.maxDueTime;
    }

    public get startTime(): any {
        if (this.entityForm.get('startTime')) {
            return this.entityForm.get('startTime').value;
        }
        return null;
    }

    public get dueTime(): any {
        if (this.entityForm.get('dueTime')) {
            return this.entityForm.get('dueTime').value;
        }
        return null;
    }

    private timeError(): void {
        if (this.startTime && this.dueTime) {
            if (this.entityForm && this.entityForm.get('dueTime')) {
                const startTimeArray = this.startTime.split(':');
                const dueTimeArray = this.dueTime.split(':');
                if (+startTimeArray[0] > +dueTimeArray[0] || +startTimeArray[0] === +dueTimeArray[0] && +startTimeArray[1] > +dueTimeArray[1]) {
                    this.entityForm.get('dueTime').setErrors({timeError: true});
                    this.entityFormErrors['dueTime'] = this.entityForm.get('dueTime').errors;
                } else {
                    this.entityForm.get('dueTime').setErrors(null);
                }
            }
        }
    }

    /**
     * list of learner id that already have an assignation for the current lesson
     * @param learnersIds list des id des learner selectionnés
     */
    private listOfUserAlreadyAssignedToThisLesson(learnersIds: string[]): Observable<DataEntity[]> {
        return this.assignationService.loadAndGetAssignmentByLessonId(+this.entity.nodeId).pipe(
            take(1),
            map(assignements => {
                return assignements.entities.filter(r => learnersIds.includes(r.attributes.assignated_user.uid));
            })
        );
    }
}