import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {GamecodeService} from './gamecode.service';
import {GamecodeListComponent} from './gamecode-list/gamecode-list.component';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {GamecodeCardComponent} from './gamecode-card/gamecode-card.component';
import {GamecodeDataEditorModalComponent} from './gamecode-data-editor-modal/gamecode-data-editor-modal.component';
import {GamecodeContentEditorComponent} from './gamecode-content-editor/gamecode-content-editor.component';
import {GamecodeGenericAlertModalComponent} from './gamecode-generic-alert-modal/gamecode-generic-alert-modal.component';
import {GamecodeRepositoryService} from './gamecode-repository.service';
import { GamecodeContentViewerModalComponent } from './gamecode-content-viewer-modal/gamecode-content-viewer-modal.component';
import {SearchFiltersModule} from 'fuse-core/components';

const routes: Routes = [
    {
        path: 'code',
        canActivate: [IsUserLogged],
        children: [
            {
                path: ':application/list',
                component: GamecodeListComponent,
            },
            {
                path: ':application/:gamecodeId/edit',
                component: GamecodeContentEditorComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        SharedModule,
        SearchFiltersModule,
    ],
    declarations: [
        GamecodeDataEditorModalComponent,
        GamecodeCardComponent,
        GamecodeListComponent,
        GamecodeContentEditorComponent,
        GamecodeGenericAlertModalComponent,
        GamecodeContentViewerModalComponent
    ],
})
export class GamecodeModule {
    private static isMenuSet = false;

    constructor(private dynamicNavigation: DynamicNavigationService,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<GamecodeModule> {
        return {
            ngModule: GamecodeModule,
            providers: [
                GamecodeService,
                GamecodeRepositoryService
            ]
        };
    }

    private postAuthentication(): void {

    }

    private postLogout(): void {
        GamecodeModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'gamecodeList');
    }
}