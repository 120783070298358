import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FuseGroupsFormDialogComponent} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {GroupService, InstitutionGroupService, LearnerService} from '@modules/groups-management';
import {InstitutionAdresseDataCollection} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from "@modules/communication-center";

@Component({
    selector: 'app-choose-group',
    templateUrl: './choose-group.component.html',
    styleUrls: ['./choose-group.component.scss']
})
export class ChooseGroupComponent implements OnInit {

    private classInfo: any;
    private groups: DataEntity[];
    private userData: DataEntity;
    public selectedGroup: DataEntity;
    public errorId: boolean;

    public embedded: boolean = false;
    public finish: boolean;
    public state = 'start';
    public errorMessage: string = '';
    public optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 50
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DataEntity | unknown,
        public dialogRef: MatDialogRef<FuseGroupsFormDialogComponent>,
        private communicationCenter: CommunicationCenterService,
        private institutionGroupsService: InstitutionGroupService,
        private groupService: GroupService,
        private learnerService: LearnerService
    ) {
    }

    ngOnInit(): void {
        if (this.data instanceof DataEntity) {
            this.userData = this.data;
            this.setup();
        } else {
            this.communicationCenter
                .getRoom('authentication')
                .getSubject('userData')
                .subscribe((data: DataEntity) => {
                    if (data) {
                        this.userData = data;
                        this.embedded = true;
                        this.setup();
                    }
                });
        }
    }

    private setup() {
        const ssoToken = this.userData.get('sso_token');

        if (ssoToken) {
            const uai = JSON.parse(ssoToken)?.UAI;

            if (uai) {
                this.fetchInstitutionGroupAdresses(uai);
            }
        }
    }

    /**
     * Fetches the institution group based on the given uai
     * @param uai The UAI parameter to filter institution groups
     */
    fetchInstitutionGroupAdresses(uai: string): void {
        this.optionsInterface.filter.parents = uai;

        this.institutionGroupsService.getInstitutionGroupAdresses(this.optionsInterface)
            .subscribe({
                next: (data: InstitutionAdresseDataCollection) => {
                    if (data && data.entities && data.entities.length > 0) {
                        this.errorMessage = '';
                        this.groups = data.entities;
                    } else {
                        this.errorMessage = "generic.empty_groups";
                    }
                },
                error: (error: any) => {
                    this.errorMessage = 'generic.empty_groups';
                    console.error(error)
                }
            });
    }


    reset(): void {
        this.classInfo = null;
        this.errorId = false;
        this.dialogRef.close();
    }


    public joinClass(): void {
        if (this.selectedGroup && this.userData) {
            this.groupService.joinGroup(this.selectedGroup).subscribe((data) => {
                this.learnerService.refreshLearners();
                this.groupService.loadGroups();
                this.dialogRef.close();
            }, (error) => {
                console.log(error);
                this.dialogRef.close();
            });
        }
    }

    public get title(): string {
        return 'group-management.select-class';
    }
}