// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  resize: none !important;
}

.idea-form-dialog .mat-mdc-dialog-title {
  margin: 0;
}
.idea-form-dialog .mat-mdc-dialog-surface {
  padding: 0;
  overflow: hidden;
}
.idea-form-dialog .save-button {
  background-color: #305792;
  color: #ffffff;
}
.idea-form-dialog .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.definition-icon {
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  line-height: 36px;
  font-weight: bold;
  font-size: 14px;
  color: #ff787d;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);
}
.definition-icon:hover, .definition-icon.selected {
  background: #ff787d;
  color: white;
}

.icons-container {
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/idea-edition-modal/idea-edition-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAYE;EACE,SAAA;AATJ;AAWE;EACE,UAAA;EACA,gBAAA;AATJ;AAWE;EACE,yBAAA;EACA,cAAA;AATJ;AAYE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAVJ;;AAcA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EAEA,mBAAA;EACA,iDAAA;AAZF;AAcE;EAEE,mBAAA;EACA,YAAA;AAbJ;;AAiBA;EACE,kBAAA;EACA,WAAA;AAdF","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n\r\ntextarea {\r\n  resize: none !important;\r\n}\r\n\r\n.idea-form-dialog {\r\n  //@include media-breakpoint('xs') {\r\n  //  width: 100%;\r\n  //}\r\n  //\r\n  //@include media-breakpoint-up('xs') {\r\n  //  width: 400px;\r\n  //}\r\n  .mat-mdc-dialog-title {\r\n    margin: 0;\r\n  }\r\n  .mat-mdc-dialog-surface {\r\n    padding: 0;\r\n    overflow: hidden;\r\n  }\r\n  .save-button {\r\n    background-color: #305792;\r\n    color: #ffffff;\r\n  }\r\n\r\n  .dialog-content-wrapper {\r\n    max-height: 85vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n}\r\n\r\n.definition-icon {\r\n  width: 34px;\r\n  height: 34px;\r\n  margin: 8px;\r\n  border-radius: 50%;\r\n  cursor: pointer;\r\n  text-align: center;\r\n  display: inline-block;\r\n  line-height: 36px;\r\n  font-weight: bold;\r\n  font-size: 14px;\r\n  color: #ff787d;\r\n\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);\r\n\r\n  &:hover,\r\n  &.selected {\r\n    background: #ff787d;\r\n    color: white;\r\n  }\r\n}\r\n\r\n.icons-container {\r\n  text-align: center;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
