import {Component, Input, OnInit} from '@angular/core';
import {CustomBlock} from 'fuse-core/components/collection/custom-blocks/custom-block.model';
import {DomSanitizer} from '@angular/platform-browser';
import {BlockHandlerService} from 'fuse-core/components/collection/custom-blocks/block-handler.service';
import {Router} from '@angular/router';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';

@Component({
    selector: 'app-half-width-cta',
    templateUrl: './half-width-cta.component.html',
    styleUrls: ['./half-width-cta.component.scss']
})
export class HalfWidthCtaComponent implements OnInit {

    @Input() block: CustomBlock;
    public blockPath = '';
    private extraDataUrl = '';

    constructor(
        public blockHandler: BlockHandlerService,
        private router: Router,
        public globalConfigurationService: GlobalConfigurationService,
    ) {
    }

    ngOnInit(): void {
        if (this.globalConfigurationService.getUseExtraDataUrl()) {
            this.extraDataUrl = '';
            if (this.router.url.includes('junior')) {
                this.extraDataUrl = 'junior';
            }
            if (this.router.url.includes(('college'))) {
                this.extraDataUrl = 'explorer';
            }
            if (this.router.url.includes(('explorer'))) {
                this.extraDataUrl = 'explorer';
            }
            this.blockPath = this.blockHandler.getBlockPath(this.block.btnLink, this.extraDataUrl);
        }
    }
}
