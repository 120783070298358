import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TagsSelectionModalWrapperComponent} from 'fuse-core/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component';
import {FuseSharedModule} from 'fuse-core/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {TranslateModule} from '@ngx-translate/core';
import { TagsSelectionCoreComponent } from './tags-selection-core/tags-selection-core.component';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatToolbarModule,
        FuseSharedModule,
        MatSelectModule,
        MatTableModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule
    ],
    declarations: [TagsSelectionModalWrapperComponent, TagsSelectionCoreComponent],
    exports: [
        TagsSelectionCoreComponent
    ]
})
export class TagsModule { }