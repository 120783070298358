/**
 * endpoint to create or update userSave
 */
export enum userSaveEndPointEnum {
    appaire = 'app-save',
    divider = 'divider-save',
    APP = 'app-save',
    Audio = 'noEndpoint', // no userSave
    CRT = 'qcm-save',
    EXT = 'genericsave',
    GCM = 'genericsave',
    IMG = 'noEndpoint', // no userSave
    IMGI = 'noEndpoint', // no userSave
    ORD = 'qcm-save',
    ORDMAT = 'qcm-save',
    TXTMAT = 'qcm-save',
    awareness = 'app-save',
    QCM = 'qcm-save',
    QCMU = 'qcm-save',
    POINTIMG = 'qcm-save',
    POINTWORD = 'qcm-save',
    POINTMULTIWORD = 'qcm-save',
    RB = 'rb-save',
    Tool = 'noEndpoint', // no userSave
    VF = 'qcm-save',
    summary = 'qcm-save',
    video = 'qcm-save',
    DRL = 'qcm-save', // Correspond a un tableau
    MEM = 'noEndpoint', // no save
    missing = 'qcm-save',
    missingaudio = 'qcm-save'
}