import {ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {map, mergeMap, switchMap, take, takeUntil} from 'rxjs/operators';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {AssignationService} from '@modules/assignation';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {ActivatedRoute} from '@angular/router';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {IndexService} from 'fuse-core/services/index.service';

@Component({
    selector: 'app-usersaves-list',
    templateUrl: './usersaves-list.component.html',
    styleUrls: ['./usersaves-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsersavesListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnDestroy {
    @Input() isAssignationClosedActive = false;
    public dataSource = new MatTableDataSource();
    public isLoading = true;
    public displayedColumns: string[] = ['lesson', 'questionSet', 'activity', 'errors', 'context'];

    private optionsInterface: CollectionOptionsInterface;
    private lessonsIds: string[] = [];
    private assignationIds: string[] = [];
    private lessons: any[] = [];
    private rows: any[] = [];
    private logsPaginated: PaginatedCollection;
    private subscription: Subscription;

    countEntities = 0;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];
    public selectedCollection = null;


    private octopusConnect = inject(OctopusConnectService);
    private communicationCenter = inject(CommunicationCenterService);
    private authService = inject(AuthenticationService);
    private assignationService = inject(AssignationService);
    private config = inject(AssignationConfigurationService);
    private ref = inject(ChangeDetectorRef);
    private activatedRoute = inject(ActivatedRoute);
    private indexService = inject(IndexService);
    public unsubscribePaginatedDataTakeUntil = new Subject<void>();

    public get displayedFilters(): string[] {
        const role = this.authService.accessLevel;
        let fields = this.assignationService.settings.userSavesList[role];
        if (fields === undefined) {
            fields = this.assignationService.settings.userSavesList['default'];
        }
        return fields;
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo();
    }

    public get rolesCanShowBannerInfoClosedAssignment(): string[] {
        return this.config.rolesCanShowBannerInfoClosedAssignment();
    }

    ngOnInit(): void {
        this.optionsInterface = {
            filter: {grade: 500},
            page: 1,
            range: 10,
        };

        if (this.authService.isAtLeastTrainer()) {
            this.optionsInterface.filter['showLearners'] = true;
            this.displayedColumns = ['learner', ...this.displayedColumns];
        }
        //ctz-setting
        if (this.config.applyAssignatedFilterToFalse() && this.authService.isAtLeastTrainer()) {
            // par defaut on ne filtre pas sur les assignés sur ctz code
            this.optionsInterface.filter['assignated'] = false;
        }
        if (!this.activatedRoute?.snapshot?.queryParams?.learnerId) {
            this.loadUserSave();
        }
    }

    /**
     * reload data in regard of new filter
     * @param options : optionsInterface
     */
    launchSearch(options: CollectionOptionsInterface = null): void {
        this.optionsInterface = options;
        //ctz-setting
        if (this.config.addFilterGrade()) {
            this.optionsInterface.filter['grade'] = 500; // don't know what is it if not we have lesson even if just launch and not finish
        }

        if (this.authService.isAtLeastTrainer()) {
            this.optionsInterface.filter['showLearners'] = true;
        }
        this.isLoading = true;
        this.loadData();
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    setPaginator(): void {
        if (this.logsPaginated?.paginator) {
            this.countEntities = this.logsPaginated.paginator.count ? this.logsPaginated.paginator.count : 0;
            this.pageIndex = this.logsPaginated.paginator.page - 1;
            this.pageRange = this.logsPaginated.paginator.range;
        }
    }

    public loadUserSave(): void {
        //ctz-setting
        if (this.config.loadRootFilter()) {
            // only launch here for learners not for teacher
            if (this.authService.isLearner()) {
                this.communicationCenter
                    .getRoom('root-filter')
                    .getSubject('selected')
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe(selectedCollection => {
                        if (selectedCollection !== undefined && selectedCollection !== null) {
                            this.selectedCollection = selectedCollection;
                            this.indexService.pythonIdInRegardOfIndex(this.selectedCollection.toString())
                                .pipe(take(1))
                                .subscribe(concept => {
                                    this.optionsInterface.filter['concepts'] = concept;
                                });
                        }
                        this.loadData();
                    });
            }
        } else {
            this.loadData();
        }
    }

    private loadData() {
        // si on veut aussi les non assigné on supprime le filtre sinon ça courcircuite le choix qu'on fait avec assignated
        if (!this.optionsInterface.filter?.assignated && this.optionsInterface.filter?.assignator) {
            delete this.optionsInterface.filter.assignator;
        }

        if (this.logsPaginated) {
            this.logsPaginated = null;
        }

        // to avoid dooble call when change root filter and avoid mistake possible to have  previous result come after the current one
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.logsPaginated = this.octopusConnect
            .paginatedLoadCollection('user-save', this.optionsInterface);

        this.subscription = this.logsPaginated.collectionObservable.pipe(takeUntil(this.unsubscribePaginatedDataTakeUntil))
            .subscribe((dataEntities) => {
                this.resetpartial();
                this.isLoading = true;
                if (dataEntities.entities.length > 0) {
                    this.extractLessonIds(dataEntities.entities);
                    //ctz-setting
                    const obs = this.config.useGetLessonByIdsOnce() ? this.getLessonByIdsOnce(this.lessonsIds) : this.getLessonByIds(this.lessonsIds);
                    obs.pipe(take(1))
                        .subscribe((lessons) => {
                            this.lessons = this.formatLessons(lessons);
                            this.rows = this.formatRows(dataEntities.entities, this.lessons);
                            this.dataSource.data = this.rows;
                            this.ref.detectChanges();
                            this.setPaginator();
                            this.isLoading = false;
                        });
                } else {
                    this.lessons = [];
                    this.rows = [];
                    this.dataSource.data = [];
                    this.ref.detectChanges();
                    this.setPaginator();
                    this.isLoading = false;
                }
            });
    }

    private extractLessonIds(entities: DataEntity[]): void {
        this.lessonsIds = [];
        this.assignationIds = [];
        entities.forEach((entity) => {
            this.lessonsIds.push(entity.get('lesson'));
            this.assignationIds.push(entity.get('context'));
        });
        this.lessonsIds = [...new Set(this.lessonsIds)];
    }

    private formatLessons(lessons: DataEntity[]): any[] {
        return lessons.map((item) => ({
            id: item.id,
            activities: item.get('reference'),
            questionSet: item.get('metadatas').title,
            concept: item.get('metadatas').concepts[0]
        }));
    }

    private formatRows(entities: DataEntity[], lessons: any[]): any[] {
        return entities.reduce((acc, entity) => {
            const lesson = lessons.find((item) => item.id === entity.get('lesson'));
            const activity = lesson.activities.find((item) => entity.get('granule')[0] === item.id);

            if (!activity) {
                return acc;
            } // Si activity est undefined, retournez le tableau accumulé tel quel

            const newRow = {
                grade: entity.get('grade'),
                errorsCount: entity.get('errorsCount'),
                id: entity.id,
                uid: entity.get('assignation').assignatedUser,
                questionSet: lesson.questionSet,
                concept: lesson.concept,
                activity: activity?.title,
                lesson: entity.get('assignation').title,
                context: entity.get('assignation').uid === entity.get('uid') ? 'auto-assignment' : 'assignment'
            };

            acc.push(newRow); // Ajoutez la nouvelle ligne au tableau accumulé
            return acc; // Retournez le tableau accumulé mis à jour
        }, []);
    }


    private getActivities(lesson: DataEntity): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter.getRoom('lessons').next('getActivities', {
            lesson: lesson,
            callbackSubject: lesson$,
        });
        return lesson$.pipe(mergeMap((obs) => obs));
    }

    private getLessonByIds(ids: string[] | number[]): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter.getRoom('lessons').next('getLessons', {
            lessonIds: ids,
            callbackSubject: lesson$
        });
        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    private getLessonByIdsOnce(ids: string[] | number[]): Observable<DataEntity[]> {
        const lesson$ = new ReplaySubject<Observable<DataEntity[]>>(1);
        this.communicationCenter.getRoom('lessons').next('getLessons', {
            lessonIds: ids,
            callbackSubject: lesson$
        });
        return lesson$.pipe(
            switchMap(obs => obs),
            take(1)
        );
    }


    public onPaginateChange(event): void {
        this.isLoading = true;
        this.logsPaginated.paginator.page =
            event.pageIndex + 1;
    }

    public isCellContentMustBeDisplayed(row: any): boolean {
        return !row.concept || !this.config.disallowedConcepts().includes(row.concept.label);
    }

    private reset(): void {
        if (this.unsubscribePaginatedDataTakeUntil) {
            this.unsubscribePaginatedDataTakeUntil.next();
            this.unsubscribePaginatedDataTakeUntil.complete();
        }
        this.unsubscribePaginatedDataTakeUntil = new Subject<void>();
        this.resetpartial();
    }

    private resetpartial(): void {
        this.pageIndex = 0;
        this.pageRange = 10;
        this.pageRangeOptions = [10];
        this.lessons = [];
        this.rows = [];
        this.dataSource.data = [];
        this.ref.detectChanges();
        this.countEntities = 0;
        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.unsubscribe();
        this.reset();
        if (this.logsPaginated) {
            this.logsPaginated = null;
        }
    }
}