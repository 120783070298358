import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserTestService} from './browser-test.service';
import {BrowserTestConfiguration} from './browser-test-configuration.interface';
import {ConfigurationProvider} from './configuration-provider.class';
import { ScreenSizeModalComponent } from './modals/screen-size-modal/screen-size-modal.component';
import {SharedTranslateModule} from 'shared/shared-translate.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { BlackListModalComponent } from './modals/black-list-modal/black-list-modal.component';
import {OctopusConnectModule} from 'octopus-connect';
import {OctopusConnectListenerService} from './octopus-connect-listener.service';
import { WaitingForResponseModalComponent } from './modals/waiting-for-response-modal/waiting-for-response-modal.component';
import { FatalErrorModalComponent } from './modals/fatal-error-modal/fatal-error-modal.component';
import {CommunicationCenterModule} from '@modules/communication-center';
import { InMaintenanceModalComponent } from './modals/in-maintenance-modal/in-maintenance-modal.component';
import { GenericMessageModalComponent } from './modals/generic-message-modal/generic-message-modal.component';
import { GenericErrorModalComponent } from './modals/generic-error-modal/generic-error-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedTranslateModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
        //OctopusConnectModule,
        // CommunicationCenterModule
    ],
    declarations: [
        ScreenSizeModalComponent,
        BlackListModalComponent,
        WaitingForResponseModalComponent,
        FatalErrorModalComponent,
        InMaintenanceModalComponent,
        GenericMessageModalComponent,
        GenericErrorModalComponent
    ],
    providers: [
        BrowserTestService,
        OctopusConnectListenerService
    ],
})
export class BrowserTestModule {
    static forRoot(configuration: BrowserTestConfiguration): ModuleWithProviders<BrowserTestModule> {
        return {
            ngModule: BrowserTestModule,
            providers: [
                ConfigurationProvider,
                {provide: 'browser-test-configuration', useValue: configuration}
            ]
        };
    }
}