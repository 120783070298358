import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {MediaService} from 'shared/media/media.service';
import {FileData} from 'shared/models';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent {

    @Input() public video?: FileData<'video/mp4'> = null;
    @Output() public videoChange = new EventEmitter<FileData<'video/mp4'>>();
    @Output() public videoSoftDelete = new EventEmitter<FileData<'video/mp4'>>();

    private mediaService = inject(MediaService);

    public acceptedTypes = this.mediaService.getMimeTypes('video').join(', ');
    public uploadFile: (file: File) => Observable<FileData<'video/mp4'>> = this.mediaService.uploadVideo.bind(this.mediaService);

    public handleFileUploadSuccess(uploadedFile: FileData<'video/mp4'>): void {
        this.videoChange.emit(uploadedFile);
        this.video = uploadedFile;
    }

    public handleFileUploadSoftDelete($event: FileData<'video/mp4'>): void {
        this.videoSoftDelete.emit($event);
        this.video = null;
    }
}
