import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, combineLatest, of} from 'rxjs';
import {CollectionOptionsInterface} from 'octopus-connect/lib/models/collection-options.interface';
import {debounceTime, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {CollectionPaginator, DataEntity} from 'octopus-connect';
import {
    LICENSE_TYPES_KEYS,
    LicenseManagementService,
    LicenseTypes, TranslatedLicenseType
} from '@modules/groups-management/core/services/license-management-service/license-management.service';
import {License, User, UserSearchDataEntity} from '@modules/groups-management/core/models/user-search-data-entity';
import {fuseAnimations} from 'fuse-core/animations';
import * as _ from 'lodash-es';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InstitutionDataEntity} from '@modules/groups-management/core/definitions';
import {EditUserAndLicenseComponent} from '@modules/groups-management/core/license-management/edit-user-and-license/edit-user-and-license.component';
import {Observable} from 'rxjs';
import {localizedDate} from 'shared/utils/datetime';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {LicenseControls, LicenseManagementDataToSave} from '@modules/groups-management/core/models/license-management';

@Component({
    selector: 'app-license-management',
    templateUrl: './license-management.component.html',
    styleUrls: ['./license-management.component.scss'],
    animations: fuseAnimations
})
export class LicenseManagementComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = []; // colonne à afficher
    public countEntities = 0; // nombre d'utilisateurs recuperé
    public pageIndex = 1; // index de la page en cours
    public pageRange = 10; // nombre d'utilisateurs par page récuperé
    public pageRangeOptions: number[] = [10]; // affichage du nombre d'utilisateurs par page récuperé
    public pending: boolean; // gère le spinner pour l'attente
    public usersInTable: User[]; // liste des utilisateurs
    public usersEntities: DataEntity[] = []; // liste des utilisateurs sous forme de  d'entités
    public filterControls: { // les form-controls des filtres
        name: UntypedFormControl;
        licenseType: UntypedFormControl;
        startDate: UntypedFormControl;
        endDate: UntypedFormControl;
    };
    public optionsFilter: CollectionOptionsInterface = { // le filtre qui sert pour la requête (recupérer les utilisateurs)
        filter: {},
        page: 1,
        range: 10
    };
    private paginator: CollectionPaginator; // le paginator d'octopus-connect
    private unsubscribeTakeUntil: Subject<void>; // se désabonné lorsque au ngOnDestroy
    private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(private licenseManagementService: LicenseManagementService,
                private dialog: MatDialog,
                private translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit(): void {
        this.unsubscribeTakeUntil = new Subject<void>();
        this.initialise();
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    /**
     * initialise le tableau des utilisateurs et les form controls. Recupere les utilisateurs.
     * @private
     */
    private initialise(): void {
        this.displayedColumns = ['name', 'role', 'license', 'institution', 'endDate', 'buttons'];
        this.loadUsers();
        this.filterControls = {
            name: new UntypedFormControl(null),
            licenseType: new UntypedFormControl(null),
            startDate: new UntypedFormControl(null),
            endDate: new UntypedFormControl(null)
        };
        this.filterControls.name.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.name = val;
                } else {
                    delete this.optionsFilter.filter.name;
                }
            })
        ).subscribe();

        this.filterControls.licenseType.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null && val !== 'generic.all') {
                   this.optionsFilter.filter.licenseType = LicenseTypes[val];
                } else {
                    delete this.optionsFilter.filter.licenseType;
                }
            })
        ).subscribe();

        this.filterControls.startDate.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.startDate = Math.round(+val.toDate().getTime() / 1000);
                } else {
                    delete this.optionsFilter.filter.startDate;
                }
            })
        ).subscribe();

        this.filterControls.endDate.valueChanges.pipe(
            debounceTime(400),
            tap((val) => {
                if (val !== null) {
                    this.optionsFilter.filter.endDate = Math.round(+val.toDate().getTime() / 1000);
                } else {
                    delete this.optionsFilter.filter.endDate;
                }
            })
        ).subscribe();
    }

    /**
     * recupere la liste des utilisateurs ainsi que les data associés (institutions, etc..)
     * @private
     */
    private loadUsers(): void {
        this.pending = true;
        this.licenseManagementService.getUsersAndPaginator(this.optionsFilter)
            .pipe(
                takeUntil(this.unsubscribeTakeUntil),
                tap((data: { entities: UserSearchDataEntity[], paginator: CollectionPaginator }) => this.paginator = data.paginator),
                tap((data: { entities: UserSearchDataEntity[], paginator: CollectionPaginator }) => this.usersEntities = data.entities.slice()),
                mergeMap(() => this.licenseManagementService.loadAllInstitutions().pipe(take(1))),
                tap((institutions: InstitutionDataEntity[]) => this.licenseManagementService.institutions = institutions),
                mergeMap(() => this.licenseManagementService.loadAllInstitutionsWithFilter().pipe(take(1))),
                tap((institutions: InstitutionDataEntity[]) => this.licenseManagementService.institutionsFiltered = institutions),
                tap(() => {
                    this.usersInTable = this.licenseManagementService.associateUserEntitiesWithUserInterface(this.licenseManagementService.usersEntities);
                }),
                tap(() => this.setPaginator()),
                tap(() => this.pending = false),
            )
            .subscribe();
    }

    /**
     * initialise les infos de la pagination
     * @private
     */
    private setPaginator(): void {
        if (this.paginator) {
            this.countEntities = this.paginator.count;
            this.pageIndex = this.paginator.page - 1;
            this.pageRange = this.paginator.range;
        }
    }

    /**
     * ouvre la pop up pour l'edition d'un ustilisateurs
     * @param user
     */
    public edit(user: User): void {
        const refDialog: MatDialogRef<EditUserAndLicenseComponent> = this.dialog.open(EditUserAndLicenseComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                // utilisateur à editer
                user,
                // institution de l'utilisateur
                userInstitution: this.getUserInstitution(user),
                // liste des institutions disponibles
                institutions: this.licenseManagementService.institutionsFiltered,
                // license actuel de l'utilisateur
                license: this.getLicense(user),
                // liste tous les types de licenses
                licenseTypes: this.licenseManagementService.licenseTypes,
                // les roles de l'utilisateur
                roles: this.associateRoleWithRoleMapping(user.roles),
                // desactive certaines options d'un select selon un certaines conditions.
                disableOption: (type) => this.disableOption(type, user),
                // desactive certains champs du formulaire d'edition selon un certaines conditions.
                disabledFields: (controls?: LicenseControls) => this.disabledFields(user, controls),
                // recupere la key d'un enum (ici sert pour les types de license)
                getEnumKey: (type) => this.getEnumKey(type),
                save: (data: LicenseManagementDataToSave) => this.saveUserData(data),

            }
        });
        refDialog.afterClosed().pipe(
            take(1),
            filter((response) => !!response),
            tap(() => this.pending = true),
            tap(() => this.loadUsersWithFilters())
        ).subscribe();
    }

    /**
     * sauvegarde de l'utilisateur, on a plusieurs cas differents selon le role, la license de l'utilisateur.
     * @param data
     */
    saveUserData(data: LicenseManagementDataToSave): Observable<DataEntity> {
        // creation d'un utilisateur
        if (data && !data.user) {
            return this.licenseManagementService.createUser(data, this.isDuplicatedInstitution(data));
        }
        return this.editLicenseAndInstitutionDependingOnTheCase(data);
    }

    private editLicenseAndInstitutionDependingOnTheCase(data: LicenseManagementDataToSave): Observable<DataEntity> {

        // si aucune information n'a été modifié, on ne fait rien.
        if (((data.license === this.licenseType(data.user))
            && ((!this.getLicense(data.user) && !data.endDate) || +data.endDate === +this.getLicense(data.user).endDate))
            && this.getUserInstitution(data.user) && data.institution
            && this.getUserInstitution(data.user).id === data.institution['id']) {
            return of(null);
        }

        if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'free'
            && this.licenseType(data.user) === 'class') {
            return this.licenseManagementService.detachUserFromInstitution(data.user.id);
        }

        // si le type ou la date de péremption de la license à changé au sein de la même institution
        if (((data.license && this.getLicense(data.user) && data.license !== this.licenseType(data.user))
            || (this.getLicense(data.user) && +data.endDate !== this.getLicense(data.user).endDate))
            && this.getUserInstitution(data.user) && data.institution
            && this.getUserInstitution(data.user).id === data.institution['id']
        ) {

            // si upgrade license 'institution' et une institution avec les mêmes infos existe en BDD, le client la rejoindra
            if (data.license !== this.licenseType(data.user) && data.license === 'institution') {
                const institutionExisted = this.licenseManagementService.institutions
                    .find((institutionEntity) => 'attributes' in data.institution && institutionEntity.get('label') === data.institution.get('label')
                        && institutionEntity.get('uai') === data.institution.get('uai')
                        && institutionEntity.get('license') && this.getEnumKey(institutionEntity.get('license').type) === 'institution');
                if (institutionExisted) {
                    data.institution = institutionExisted;
                    return this.joinInstitutionExisted(data.user, institutionExisted);
                }
            }
            // patch simplement la license et le role du client => directeur avec license institution
            if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'institution') {
                return this.licenseManagementService.editUser(data.user, {updateRoleToDirector: true})
                    .pipe(
                        mergeMap(() => this.licenseManagementService
                            .patchLicense(this.getLicense(data.user), {type: data.license, endDate: data.endDate}))
                    );
            }
            if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'class'
                && this.licenseType(data.user) === 'institution') {
                return this.createOrDuplicateInstitutionAndLicense(data);
            }

            return this.licenseManagementService.patchLicense(this.getLicense(data.user), {type: data.license, endDate: data.endDate});
        }

        // si une institution a été crée, on doit créer une license et potentiellement changer le role du client
        // ou le besoin est de dupliquer une institution et une license pour le client
        if (data.user && data.license && data.institution && !data.institution['id'] || this.isDuplicatedInstitution(data)) {
            return this.createOrDuplicateInstitutionAndLicense(data);
        }

        // si l'institution du client à changé pour une autre institution existante (forcement un prof)
        if (data.user && data.institution && data.institution['id']) {
            const institutionWithLicenseExist = this.licenseManagementService.institutions
                .find((institution) => data.institution['id'] === institution.id && +institution.get('license')?.uid === +data.user.id);
            if (institutionWithLicenseExist) {
               return this.licenseManagementService.editLicense(institutionWithLicenseExist.get('license').id, data)
                   .pipe(
                       mergeMap(() => this.joinInstitutionExisted(data.user, data.institution as InstitutionDataEntity, data.license === 'institution'))
                   );
            }
            return this.joinInstitutionExisted(data.user, data.institution as InstitutionDataEntity);
        }
        return of(null);
    }

    /**
     * recharge les données en fontion de l'index de page souhaité
     * @param event
     */
    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
    }

    /**
     * associe les id de role passé en  prop avec la map des roles recuperé de l'authentication service
     * permet de recuperer par exemple pour le role "7", le terme "directeur"
     * @param roles
     */
    public associateRoleWithRoleMapping(roles: { [key: string]: string }): string[] {
        const rolesAssociated = [];
        for (const key in roles) {
            const currentRole = _.findKey(this.licenseManagementService.roles, (role) => +role === +key);
            if (currentRole) {
                rolesAssociated.push(currentRole);
            }
        }
        // for director we show only this role not the other roles
        if (rolesAssociated.includes('Director')) {
            return ['Director'];
        }
        return rolesAssociated;
    }

    /**
     * recupere l'institution d'un l'utilisateur
     * @param user
     */
    public getUserInstitution(user: User | InstitutionDataEntity): InstitutionDataEntity {
        let institutions;
        if ('name' in user) {
            institutions = user.og_user_node && user.og_user_node.filter((item) => item.type === 'Institution');
        } else {
            const userEntity = this.usersEntities.find((u) => +u.id === +user.id);
            institutions = userEntity.get('og_user_node') && userEntity.get('og_user_node').filter((item) => item.type === 'Institution');
        }

        if (institutions.length) {
            return this.licenseManagementService.institutions.find((i) => +i.id === +institutions[0].id);
        }
        return null;
    }

    /**
     * recupère le type de license d'un utilisateur
     * @param user
     */
    public licenseType(user: User): string {
        return this.getLicense(user) && this.getLicense(user).type || null;
    }

    /**
     *  recupère la date de fin de la license d'un utilisateur
     * @param user
     */
    public licenseEndDate(user): number | string {
        return this.getLicense(user) && this.getLicense(user).endDate && localizedDate(this.getLicense(user).endDate) || null;
    }

    /**
     *  recupère la license complete d'un utilisateur
     * @param user
     * @private
     */
    private getLicense(user): {
        id: string,
        startDate: number,
        endDate: number,
        type: string,
        uid: string
    } {
        let license = null;
        if (user.license) {
            if (user.license &&  !_.isArray(user.license)) {
                license = {
                    id: user.license.id,
                    startDate: user.license.startDate,
                    endDate: user.license.expirationDate,
                    type: LICENSE_TYPES_KEYS.get(user.license.type as LicenseTypes),
                    uid: user.license.uid
                };
            }
        }
        return license;
    }

    /**
     * desactive certaines options d'un select selon un certaines conditions.
     * @param licenseType
     * @param user
     */
    public disableOption(licenseType: string, user: User): boolean {
        // todo use authorisation service to define if we disable option for specific role
        return user && this.associateRoleWithRoleMapping(user.roles).includes('director')
            && LicenseTypes[licenseType] !== LicenseTypes.institution;
    }

    /**
     * desactive certains champs du formulaire d'edition selon un certaines conditions.
     * @param user
     */
    public disabledFields(user, controls?: LicenseControls): string[] {
        const fieldsDisabled = user ? ['firstName', 'lastName', 'mail'] : [];
        if (user && this.associateRoleWithRoleMapping(user.roles).includes('director')
            && (this.licenseType(user) === 'school' || this.licenseType(user) === 'institution')) {
            fieldsDisabled.push('institution', 'label', 'license');
            return fieldsDisabled;
        }
        // Todo: voir ce que l'on fait avec le role prof pour desactiver certains champs du formulaire
        // si prof avec license institution
        if ((user && this.associateRoleWithRoleMapping(user.roles).includes('trainer')
            && this.licenseType(user) === 'institution' && this.getUserInstitution(user)
            && (!controls || !!controls?.institution?.value && controls?.license?.value === 'institution')) || controls?.license?.value === 'free') {
            fieldsDisabled.push('endDate');
            return fieldsDisabled;
        }

        /*if (user && this.associateRoleWithRoleMapping(user.roles).includes('trainer') && this.licenseType(user) === 'class') {
            fieldsDisabled.push('institution');
            return fieldsDisabled;
        }*/

        return fieldsDisabled;
    }

    /**
     * recupere la key d'un enum (ici sert pour les types de license)
     * @param value
     */
    public getEnumKey(value): string {
        return LICENSE_TYPES_KEYS.get(value as LicenseTypes);
    }

    /**
     * recupere une liste tous les types de licenses
     */
    get licenseTypes(): string[] {
        return this.licenseManagementService.licenseTypes || [];
    }


    public createUser(): void {
        const refDialog: MatDialogRef<EditUserAndLicenseComponent> = this.dialog.open(EditUserAndLicenseComponent, {
            panelClass: 'edit-user-Component-form-dialog',
            width: '60vw',
            data: {
                // creation d'un utilisateur donc pas de d'utilisateur selectionné
                user: null,
                // institution de l'utilisateur
                userInstitution: null,
                // liste des institutions disponibles
                institutions: this.licenseManagementService.institutionsFiltered,
                // license actuel de l'utilisateur
                license: null,
                // liste tous les types de licenses
                licenseTypes: this.licenseManagementService.licenseTypes,
                // les roles de l'utilisateur
                roles: [],
                // desactive certaines options d'un select selon un certaines conditions.
                disableOption: (type) => this.disableOption(type, null),
                // desactive certains champs du formulaire d'edition selon un certaines conditions.
                disabledFields: (controls?: LicenseControls) => this.disabledFields(null, controls),
                getEnumKey: (type) => this.getEnumKey(type), // recupere la key d'un enum (ici sert pour les types de license)
                save: (data: LicenseManagementDataToSave) => this.saveUserData(data),
            }
        });

        refDialog.afterClosed().pipe(
            take(1),
            filter((response: LicenseManagementDataToSave) => !!response),
            tap(() => this.pending = true),
            tap(() => this.loadUsersWithFilters())
        ).subscribe(() => {} , (errorFromApi: any) => {
            throw new Error(errorFromApi.data?.response?.title);
            this.pending = false;
        });
    }

    public institutionsByRole(user?): InstitutionDataEntity[] {
        if (user && this.associateRoleWithRoleMapping(user.roles).includes('director')) {
            return this.licenseManagementService.institutions.filter(i => i.get('license') && i.get('license').type === LicenseTypes.institution);
        } else {
            return this.licenseManagementService.institutions;
        }
    }


    /**
     * open a modal and if confirm locked the user he cannot connect anymore
     * @param userToLock
     */
    public lockUserAccess(userToLock: any): void {
        const data = {
            titleDialog: userToLock.status === '1' ? 'groups-management.insitution.lock.confirm.title' : 'groups-management.insitution.unlock.confirm.title',
            bodyDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.body' : 'groups-management.institution.unlock.confirm.body',
            labelTrueDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.yes' : 'groups-management.institution.unlock.confirm.yes',
            labelFalseDialog: userToLock.status === '1' ? 'groups-management.institution.lock.confirm.no' : 'groups-management.institution.unlock.confirm.no'
        };

        this.translate.get(data.titleDialog).subscribe((translation: string) => data.titleDialog = translation);
        this.translate.get(data.bodyDialog).subscribe((translation: string) => data.bodyDialog = translation);
        this.translate.get(data.labelTrueDialog).subscribe((translation: string) => data.labelTrueDialog = translation);
        this.translate.get(data.labelFalseDialog).subscribe((translation: string) => data.labelFalseDialog = translation);

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {panelClass: 'lock-user-confirm', data});

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.licenseManagementService.getUserById(userToLock.id)
                    .pipe(take(1))
                    .subscribe((user: DataEntity[]) => {
                        if (user) {
                            // active = "1" user is locked = "0"
                            if (user[0].get('status') === '1') {
                                user[0].set('status', '0');
                                userToLock.status = '0';
                            } else {
                                user[0].set('status', '1');
                                userToLock.status = '1';
                            }
                            user[0].save();
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    completeUnsubscribeTakeUntil(): void {
        if (this.unsubscribeTakeUntil) {
            this.unsubscribeTakeUntil.next();
            this.unsubscribeTakeUntil.complete();
            this.unsubscribeTakeUntil = new Subject<void>();
        }
    }

    private isDuplicatedInstitution(data: LicenseManagementDataToSave): boolean {
        let license: string;
        if (data.institution && 'attributes' in data.institution) {
            license = data.institution.get('license') ? this.getEnumKey(data.institution.get('license').type) : null;
        }

        if (data.institution && data.institution['id']) {
            if (!data.user && (data.license === 'class' || (data.license === 'institution' && license !== 'institution'))) {
                return true;
            }
            if (data.user && data.license === 'class' && this.associateRoleWithRoleMapping(data.user.roles).includes('trainer')
                && !this.licenseManagementService.institutions
                    .some((institution) => data.institution['id'] === institution.id && +institution.get('license')?.uid === +data.user.id)) {
                return true;
            }
        }
    }

    public showLabelInstitutionWithUai(institution: InstitutionDataEntity): string {
        if (institution) {
            const label = institution.get('label') || '';
            const uai = institution.get('uai') && '[' + institution.get('uai') + ']' || '';
            return uai + ' ' + label;
        }
        return '';
    }

    private joinInstitutionExisted(user: User, institution?: InstitutionDataEntity, updateRoleToDirector?: boolean): Observable<DataEntity> {
        return this.licenseManagementService.patchInstitution(user.id, institution as InstitutionDataEntity).pipe(
            take(1),
            mergeMap((institutionEntity: InstitutionDataEntity) =>
                this.licenseManagementService.editUser(user, {institution: institutionEntity as InstitutionDataEntity, updateRoleToDirector}))
        );
    }

    public loadUsersWithFilters(): void {
        this.completeUnsubscribeTakeUntil();
        this.loadUsers();
    }

    private createOrDuplicateInstitutionAndLicense(data: LicenseManagementDataToSave): Observable<DataEntity> {
        return combineLatest([
            this.licenseManagementService.createInstitution(data.user.id,
                this.isDuplicatedInstitution(data) ? data.institution as InstitutionDataEntity : data.institution as { label: string, uai: string }),
            this.licenseManagementService.createLicense({type: data.license, endDate: data.endDate, userId: data.user.id.toString()})
        ]).pipe(mergeMap(([institution, license]) => {
            const dataToSave: { institution: InstitutionDataEntity, role?: number } = {institution};
            if (this.associateRoleWithRoleMapping(data.user.roles).includes('trainer') && data.license === 'institution') {
                dataToSave.role = this.licenseManagementService.roles.director;
            }
            return this.licenseManagementService.editUser(data.user, dataToSave);
        }));
    }

    public getTranslation(value: string): string {
        return TranslatedLicenseType[value];
    }
}