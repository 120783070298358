import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {catchError, combineLatestWith, map, mergeMap, take} from 'rxjs/operators';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivitiesService} from '@modules/contest/core/services/activities.service';
import {AssignationService} from '@modules/contest/core/services/assignation.service';
import {combineLatest} from 'rxjs';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
    RegistrationDialogComponent, RegistrationDialogData
} from '@modules/contest/core/component/registration-dialog/registration-dialog.component';
import {AuthorizationService} from '@modules/authorization';
import {FormDialogService} from 'fuse-core/components/form-dialog/form-dialog.service';
import {AuthenticationService} from '@modules/authentication';
import {timer} from 'rxjs/internal/observable/timer';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {Contest, ContestRegistrationEntity, ContestRegistrationRequest} from '@modules/contest/core/model/contest';
import {SyncRules} from '../model/rules';

@Injectable({
    providedIn: 'root'
})
export class ContestService {

    public groups: DataEntity[];
    private contestCache = new Map<number, Contest>();

    constructor(
        private activitiesService: ActivitiesService,
        private assignationService: AssignationService,
        private authorizationService: AuthorizationService,
        private authenticationService: AuthenticationService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private formDialogService: FormDialogService,
        private router: Router
    ) {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(take(1))
            .subscribe((data) => {
                this.groups = data;
            });

        this.communicationCenter
            .getRoom('gamification')
            .getSubject('showRewards')
            .subscribe(() => {
                // add a delay here to appear after the reward modal that has a 2500ms delay
                timer(2600).subscribe(() => {
                    this.checkAndOpenSurvey();
                });
            });
    }

    public getContests(): Observable<Contest[]> {
        return this.octopusConnect.loadCollection('contests').pipe(
            take(1),
            map(data => data.entities.map(entity => this.convertToContest(entity))),
            catchError(error => {
                console.error('An error occurred', error);
                return of([]);
            })
        );
    }

    public getContestProgression(contestId: string | number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('contest-progress', {'contestId': contestId}).pipe(
            take(1),
            catchError(error => {
                console.error('An error occurred', error);
                return of(null);
            })
        );
    }

    public getRanking(contestId: string | number, groupId?: string | number): Observable<DataCollection> {
        const params = {'contestId': contestId};
        if (groupId) {
            params['groupId'] = groupId;
        }
        return this.octopusConnect.loadCollection('contest-rank', params).pipe(
            take(1),
            catchError(error => {
                console.error('An error occurred', error);
                return of(null);
            })
        );
    }

    public getTickets(contestId: string | number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('contest-tickets', {'contestId': contestId}).pipe(
            take(1),
            catchError(error => {
                console.error('An error occurred', error);
                return of(null);
            })
        );
    }

    private convertToContest(entity: DataEntity): Contest {
        return {
            id: entity.id,
            name: entity.get('name'),
            collective: entity.get('collective'),
            startDate: new Date(entity.get('startDate') * 1000),
            endDate: new Date(entity.get('endDate') * 1000),
            description: entity.get('description'),
            goals: entity.get('goals'),
            rules: entity.get('rules'),
            registrations: entity.get('registrations'),
            lessons: entity.get('lessons'),
            rulesAlias: entity.get('rulesAlias'),
            prizes: entity.get('prizes'),
            globalProgress: entity.get('globalProgress'),
        };
    }

    public getContestById(id: number): Observable<Contest> {
        const cachedContest = this.contestCache.get(id);

        if (cachedContest) {
            return of(cachedContest);
        }

        return this.octopusConnect.loadEntity('contests', id).pipe(
            take(1),
            map(data => {
                const contest = this.convertToContest(data);
                this.contestCache.set(id, contest);
                return contest;
            }),
            catchError(error => {
                console.error('An error occurred', error);
                return of(null);
            })
        );
    }

    public openContestRegistrationForm(data: RegistrationDialogData): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.panelClass = 'help_close_modal'; // make modal more from bottom not centered
        dialogConfig.backdropClass = 'backdrop-blur';

        return this.dialog.open(RegistrationDialogComponent, {
            width: '70%',
            data: data
        }).afterClosed();
    }

    public registerToContest(contestId: number | string, groups: number[] = null, establishment: string = null, postalCode: string = null, shareableCode: string = null): Observable<DataEntity> {
        const payload: ContestRegistrationRequest = {contestId}; // toujours inclure contestId

        if (groups !== null) {
            payload.groups = groups;
        }

        if (establishment !== null) {
            payload.establishment = establishment;
        }

        if (postalCode !== null) {
            payload.postalCode = postalCode;
        }

        if (shareableCode !== null) {
            payload.shareableCode = shareableCode;
        }

        return this.octopusConnect.createEntity('contest-registration', payload).pipe(
            take(1)
        );
    }

    public getUserRegistrations(contestId: number | string): Observable<ContestRegistrationEntity[]> {
        return this.octopusConnect.loadCollection('contest-registration', {contestId}).pipe(
            take(1),
            map(data => data.entities as ContestRegistrationEntity[]),
            catchError(error => {
                console.error('An error occurred', error);
                return of([]);
            }));
    }

    public checkAndOpenSurvey(contestId?: string): void {
        if (this.authorizationService.currentUserCan(SyncRules.FillSurvey)) {
            if (!contestId) {
                contestId = this.getContestIdFromURL();
            }

            combineLatest([
                this.getContestProgression(contestId)
                    .pipe(
                        map((progressionCollection) => {
                            let progression = 0;

                            if (progressionCollection) {
                                progressionCollection.entities.forEach((progressionEntity) => {
                                    const learner = progressionEntity.get('learners')
                                        .find((learner) => this.authenticationService.isMe(learner.id));
                                    if (learner && learner.progression > progression) {
                                        progression = learner.progression;
                                    }
                                });
                            }

                            return progression;
                        })
                    ),
                this.formDialogService.loadSubmissions()
            ])
                .subscribe(([progression, submissions]) => {
                    if (this.isContest() || this.formDialogService.isContest) {
                        // no dialog in contest case store info to not show at end too
                        // or if we come from contest case
                        this.formDialogService.isContest = true;
                    } else {
                        switch (submissions.length) {
                            case 0:
                                if (progression === 0) {
                                    this.formDialogService.openForm();
                                }
                                break;
                            case 1:
                                if (progression >= 60) {
                                    this.formDialogService.openForm();
                                }
                                break;
                        }
                    }

                });
        }
    }

    public playContest(contest: Contest): void {
        // load lesson
        this.activitiesService.loadLesson$(contest.lessons[0])
            .pipe(
                take(1),
                // load sublessons
                map((lesson) => {
                    if (lesson.get('reference')[0].type === 'lesson') {
                        return combineLatest(lesson.get('reference').map((subLesson) => {
                            return this.activitiesService.loadLesson$(+subLesson.id);
                        }));
                    } else {
                        return combineLatest([of(lesson)]);
                    }
                }),
                mergeMap((lessons$) => lessons$),
                take(1),
                // load or create assignment
                combineLatestWith(this.assignationService.getAssignmentsByLessonId(contest.lessons[0])),
                take(1),
                map(([lessons, assigmentCollection]): Observable<[LessonGranuleEntity[], DataEntity]> => {
                    const assignments = assigmentCollection.entities.filter((assignment: DataEntity) => {
                        return +assignment.get('assignated_user').uid === this.assignationService.currentUserId;
                    });

                    if (assignments.length) {
                        return of([lessons, assignments[0]]);
                    } else {
                        return of(lessons).pipe(
                            combineLatestWith(this.assignationService.createAssignment$(contest.lessons[0]))
                        );
                    }
                }),
                mergeMap((data$) => data$), take(1),
                // load user saves
                map(([lessons, assignment]) => {
                    this.assignationService.setCurrentAssignment(assignment);
                    return of(lessons).pipe(
                        combineLatestWith(this.activitiesService.loadUserSaves())
                    );
                }),
                mergeMap((data$) => data$), take(1),
                // find next activity on each sublesson and keep highest one
                map(([lessons, saves]) => {
                    const nextActivityIndices = lessons.map((lesson) => {
                        let nextActivityIndex = -1;
                        lesson.get('reference').some((activity, index) => {
                            if (nextActivityIndex === -1 && !saves.some((save) => +save.get('granule')[0] === +activity.id && +save.get('grade') > 0)) {
                                nextActivityIndex = index;
                                return true;
                            }
                            return false;
                        });
                        return nextActivityIndex;
                    });
                    const launchIndex = nextActivityIndices.indexOf(Math.max(...nextActivityIndices));

                    return {
                        lesson: lessons[launchIndex],
                        index: Math.max(0, nextActivityIndices[launchIndex])
                    };
                }),
            ).subscribe(({lesson, index}) => {
            this.activitiesService.navigateToLesson(lesson, {
                isActivitiesListMustBeDisplayed: false,
                startOnStepIndex: index,
                exitLessonUrl: this.router.url
            });
        });
    }

    getGroups(): Observable<DataCollection> {
        const groups$ = new ReplaySubject<Observable<DataCollection>>(1);
        this.communicationCenter
            .getRoom('groups-management')
            .next('getGroups', {
                callbackSubject: groups$
            });

        return groups$.pipe(
            mergeMap(obs => obs)
        );
    }

    private getContestIdFromURL(): string {
        let contestId = '';
        let url = this.router.url;
        const urlTree = this.router.parseUrl(this.router.url);

        if (urlTree.queryParams['exitLessonUrl']) {
            url = urlTree.queryParams['exitLessonUrl'];
        }

        if (url.includes('/contests/')) {
            const urlFragments = url.split('/');
            contestId = urlFragments[urlFragments.indexOf('contests') + 1];
        }

        return contestId;
    }

    private isContest(): boolean {
        let url = this.router.url;
        return this.router.url.includes('/contests/');
    }
}