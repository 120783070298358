import {filter, take, tap} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {AuthenticationService} from '@modules/authentication';
import {localizedDate, localizedTime} from 'app/shared/utils/datetime';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from 'fuse-core/animations';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject, Subscription} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {AssignationService} from '@modules/assignation/core/services/assignation.service';
import {FlagService} from 'app/shared/flag.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {UntypedFormControl} from '@angular/forms';
import {AssignmentAdaptativePopinComponent} from '@modules/assignation/core/components/assignment-adaptative-popin/assignment-adaptative-popin.component';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {baseAppUrl} from '../../../../../../settings';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';


@Component({
    selector: 'app-widget-assignments-lesson',
    templateUrl: './widget-assignments-lesson.component.html',
    styleUrls: ['./widget-assignments-lesson.component.scss'],
    animations: fuseAnimations
})
export class WidgetAssignmentsLessonComponent implements OnInit, OnDestroy {

    public settings: { [key: string]: any };
    public displayedColumns: string[] = [];
    public currentUser: DataEntity;
    private unsubscribeInTakeUntil = new Subject<void>();

    corpusId: string;
    resourcesSubscription: Subscription;
    resources;
    selectAll = false;
    isChecked = false;
    files: any;
    dataSource = new MatTableDataSource();
    selected;
    items: any[];
    types: string[];

    private optionsInterface: CollectionOptionsInterface;

    public displayedFilters: string[] = [];
    public titleFilter = new UntypedFormControl();
    public typeFilter = new UntypedFormControl();
    public allTypes = [];
    public allStates = [];
    public displayHeader = false;
    public educationalLevels: DataEntity[] = [];

    countEntities = 50;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];

    selectedResources;
    checkboxes: {};

    learnersList = [];

    entities: any;
    user: any;
    resource: boolean;
    assignments: Array<any> = [];

    @Input() closedOnly = false; // if in <app-dynamic you pass [params]="{closedOnly:true}" the widget will only show closed assignment
    @Input() countCallBack: (count: string) => void;

    constructor(
        public authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private assignationService: AssignationService,
        private flagService: FlagService,
        public assignationConfigurationService: AssignationConfigurationService
    ) {

    }

    ngOnInit(): any {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                    const currentFilter = {};
                    if (this.authService.accessLevel === 'learner') {
                        currentFilter['assignated_user'] = this.currentUser.id;
                    } else {
                        currentFilter['assignator'] = this.currentUser.id;
                    }
                    if (this.closedOnly) {
                        currentFilter['field_progress'] = 100;
                    }
                    this.optionsInterface = {
                        filter: currentFilter,
                        page: 1,
                        range: 5
                    };
                }
            });

        // load this on init to update listing
        this.assignationService.loadAssignationsTypes();

        this.settings = this.assignationService.settings;

        if (this.settings.columnsDashboardWidget[this.authService.accessLevel]) {
            this.displayedColumns = this.settings.columnsDashboardWidget[this.authService.accessLevel];
        } else {
            this.displayedColumns = this.settings.columnsDashboardWidget['default'];
        }

        if (this.settings.noFilterforAssignmentsWidget) {
            this.launchSearch();
        } else {
            this.dataSource = this.assignationService.dataSource;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    launchSearch(): void {
        if (this.resourcesSubscription) {
            this.resourcesSubscription.unsubscribe();
        }
        this.resourcesSubscription = this.refreshList();
    }

    refreshList(): Subscription {
        return this.resourcesSubscription = this.assignationService.loadPaginatedAssignments(this.optionsInterface).pipe(
            take(1))
            .subscribe(resources => {
                this.resources = resources;
                if (this.closedOnly) {
                    this.countCallBack(this.resources.length.toString());
                }

                this.assignationService.assignations = this.resources;
                if (!this.resources) {
                    return;
                }
                this.dataSource.data = this.resources;
            });
    }

    public getTitleAssignment(assignment: DataEntity): string {
        let title = '';
        if (assignment.get('assignated_node')) {
            title = assignment.get('assignated_node').title;
        } else {
            if (assignment.get('title')) {
                title = assignment.get('title');
            } else {
                title = assignment.get('assignated_nodes')[0].title;
            }
        }
        return title;
    }

    public getProgress(entity): number | string {
        if (entity) {
            return Math.round(+entity.attributes.progress * 10) / 10;
        }
        return 0;
    }

    /**
     * if we have hideFeedbacks to false  that mean that before opening assignment we have to open a modal
     * to explain learner he will do a special lesson to evaluated him. it's only for adaptative lesson when IA doesn't
     * know learner before this flag is also use to hide feedback of IA
     * @param entity : assignment
     */
    openAssignment(entity: DataEntity): void {
        const hideFeedbacks = entity.get('hideFeedbacks');
        // hideFeedbacks = false = hide feedback because we are in adaptive lesson with diagnostic and so we doesn't open modal
        // to say learner will do a special lesson
        // eslint-disable-next-line max-len
        if (!this.assignationService.settings.useHideFeedbacksToLaunchAdaptativeModal || hideFeedbacks === null || hideFeedbacks === undefined || hideFeedbacks === 0 || hideFeedbacks === false) {
            // normal lesson without adaptive modal alert
            this.launchAssignement(entity);
        } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                svg: 'adaptive-begin',
                body: 'generic.adaptive.begin',
                button: 'generic.start'
            };
            dialogConfig.panelClass = 'adaptative-popin';
            dialogConfig.disableClose = true;

            const dialogRef = this.dialog.open(AssignmentAdaptativePopinComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(() => {
                this.launchAssignement(entity);
            });
        }
    }

    /**
     * launch assignment
     * @param assignment :assignment to launch
     * @private
     */
    private async launchAssignement(assignment: DataEntity): Promise<void> {
        if (!this.shouldOpenInApp(assignment)) {
            this.assignationService.launchAssignment(assignment).pipe(
                filter(isAllowed => isAllowed),
                tap(() => {
                    let flaggingId;
                    // add value to flaggingId to patch only if current user = flag user
                    if (assignment.get('consulted').consulted_bool && assignment.get('consulted').uid === this.currentUser.id) {
                        flaggingId = assignment.get('consulted').flagging_id;
                    }
                    // create or edit flag only if current user is assignated user
                    if (assignment.get('assignated_user').uid === this.currentUser.id) {
                        this.flagService.updateFlagEntity(assignment, 'assignations', 'consulted_assignation', flaggingId);
                    }
                    this.router.navigate(['../', 'followed', 'assignment', 'lessons', assignment.get('assignated_node').id, 'player'], {relativeTo: this.route});
                }),
                take(1)
            ).subscribe();
        } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                bodyDialog: 'activities.redirect_' + this.authService.accessLevel + '_to_app',
                labelTrueDialog: 'generic.confirm',
                labelFalseDialog: 'generic.cancel'
            };

            const translationMap = await this.translate.get(Object.values(dialogConfig.data)).toPromise();
            for (const term in dialogConfig.data) {
                dialogConfig.data[term] = translationMap[dialogConfig.data[term]];
            }

            const modalRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            const result = await modalRef.afterClosed().toPromise();
            if (result === true) {

                const userToken = localStorage
                    .getItem('http_accessToken')
                    .slice(1, -1);

                window.location.href = `${baseAppUrl}#/session?token=${userToken}&redirectPath=lessons/grade/${assignment.attributes.assignated_node.concepts[0].id}/concept/${assignment.attributes.assignated_node.educationalLevel[0].id}&id=${assignment.id}`;

            } else {

            }
        }
    }

    private shouldOpenInApp(assignment: DataEntity): boolean {
        return (
            assignment.attributes.assignated_node.concepts?.find((c) => c.label === 'Espagnol' || c.label === 'Anglais') &&
            this.assignationConfigurationService.shouldRedirectoToApp() &&
            this.authService.isLearner()
        );
    }

    public getConsultedAssignment(assignment: DataEntity): string {
        return assignment.attributes.consulted.consulted_bool;
    }

    localeDate(date: number): string {
        if (+date) {
            return localizedDate(date);
        }
    }

    localeTime(date: number): string {
        if (+date) {
            return localizedTime(date);
        }
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        if (this.dataSource.data.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}