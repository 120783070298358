import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FuseGroupsFormDialogData
} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/groups-management/core/models/rules';
import {AuthenticationService} from '@modules/authentication';
import {
    GroupManagementConfigurationService
} from '@modules/groups-management/core/services/group-management-configuration.service';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models/auto-unsubscribe-take-until.class';
import {ContextualService} from '@modules/groups-management/core/services/contextual.service';
import {takeUntil} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-groups-inline-modal',
    templateUrl: './groups-inline-modal.component.html',
    styleUrls: ['./groups-inline-modal.component.scss'],
})
export class GroupsInlineModalComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, AfterViewInit {
    public showInlineModal = false;
    public groupFormIsValid = false;
    private saveEmitter?: EventEmitter<boolean> = new EventEmitter();
    public isJoiningExistingLearner = false;
    public nbreLearnerAllowed = '';
    @Input() addEntity?: FuseGroupsFormDialogData;

    // use to flag that we are adding learner from group or workgroup replace by group name or workgroup name to push
    @Input() isAddingLearnerFromGroupOrWorkgroup: boolean = false;
    @Input() groupNameClassroomSelected = ''; // use to force a filter in learner list by classroom
    @Input() groupNameSelected = ''; // use to force a filter in learner list by a group
    @Input() learnersList: any[]; // list of all exiiting learner
    @Output() savedOrCancelOccured: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() joinLearner: FuseGroupsFormDialogData; // to join a learner to a group or a workgroup

    @Output('saveInProgress') saveInProgress: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private authorization: AuthorizationService,
        private authService: AuthenticationService,
        private contextualService: ContextualService,
        private dialog: MatDialog,
        private groupManagementConfigurationService: GroupManagementConfigurationService,
        private communicationCenter: CommunicationCenterService
    ) {
        super();
    }

    ngOnInit(): void {
        this.contextualService.actionGroupAdd$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.openModalFormInline());
    }

    ngAfterViewInit(): void {
        if (!this.authService.isGAR()) {
            this.communicationCenter
                .getRoom('groups-management')
                .getSubject('userLicenses').pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(lic => {
                    if (lic && lic.length > 0) {
                        this.nbreLearnerAllowed = lic[0].get('max_learners');
                    }
                });
        }
    }

    public get addLearnerButtonInfo(): string {
        return this.isAddLearnerButtonInfoAllowed ? this.learnersList.length + '/' + this.nbreLearnerAllowed : '';
    }

    public get isAddLearnerButtonInfoAllowed(): boolean {
        return !this.authService.isGAR() && this.nbreLearnerAllowed !== '' && (this.isgroupOrClassroomFilterActivate() || this.addEntity.data?.typeEntity === 'learner');
    }

    public get isAddLearnerQuotaDoneAndNotGar(): boolean {
        return this.isAddLearnerButtonInfoAllowed && +this.learnersList.length === +this.nbreLearnerAllowed;
    }

    /**
     * show bloc to add new element can be group user or classroom
     * can also be use to join an existing user to a group or workgroup
     */
    public openModalFormInline(isExistingLearner = false): void {
        // if user open in a row to add learners he can choose adding one new or one existing learner
        // if modal is already open and user change this choice we close and open it again for refresh list of existing learners
        if (this.isgroupOrClassroomFilterActivate() && this.showInlineModal === true) {
            this.showInlineModal = false;
            setTimeout(() => {
                this.showInlineModal = true;
                this.isJoiningExistingLearner = isExistingLearner;
            }, 200);
            return;
        }

        // if we have alternatives we check if one of them is valid and we open it
        if (this.addEntity.alternatives && this.addEntity.alternatives.length > 0) {
            for (const alternative of this.addEntity.alternatives) {
                if (alternative.condition()) {
                    if (alternative.component) {
                        this.dialog.open(alternative.component, {
                            data: this.authService.userData
                        });
                    }

                    return;
                }
            }
        }

        // standard case we just show the modal and flag if it's for an exisiting one or a new one
        if (this.showInlineModal && this.groupFormIsValid) {
            this.saveEmitter.next(true);
        }
        this.showInlineModal = true;
        this.isJoiningExistingLearner = isExistingLearner;
        setTimeout(() => {
            const firstElementLearnerCase = document.getElementById('group-form-control-nickname');
            if (firstElementLearnerCase) {
                firstElementLearnerCase.focus();
            }
            const firstElementGroupCase = document.getElementById('group-form-control-color');
            if (firstElementGroupCase) {
                firstElementGroupCase.focus();
            }
        }, 200);

    }

    /**
     * close modal inline
     */
    public closeInlineModal(): void {
        this.showInlineModal = false;
    }

    /**
     * check is form is valid to disabled submit button
     * @param groupFormIsValid
     */
    public isFormValid(groupFormIsValid: boolean): void {
        this.groupFormIsValid = groupFormIsValid;
    }

    /**
     * if it come from inide we can continue to add element
     * if it's from outside adding is finish so we hide the block
     * @param isClickfromOutside
     */
    public submitInside(isClickfromOutside: boolean): void {
        this.savedOrCancelOccured.emit(true);
        if (!isClickfromOutside) {
            this.showInlineModal = false;
        }
    }

    /*
    emit save in progres
     */
    public saveInProgressEmit(): void {
        this.saveInProgress.emit(true);
    }

    /**
     * if group or workgroup exist we are in context of learner directly add from workgroup or group row
     * flag isAddingLearnerFromGroupOrWorkgroup is used to confirm the case
     * because we now pre add classroom of teacher inside learner when adding one
     */
    public isgroupOrClassroomFilterActivate(): boolean {
        return (!!this.groupNameClassroomSelected || !!this.groupNameSelected) && this.isAddingLearnerFromGroupOrWorkgroup;
    }

    public canAddNewLearner(): boolean {
        return this.authorization.currentUserCan(SyncRules.CreateLearnerFromGroups, this.groupManagementConfigurationService.addNewLearnersFromGroup()) && !this.authService.isGAR();
    }

    public isCreateAuthorized(): boolean {
        return this.addEntity.isAuthorized(null);
    }

    public isJoinAuthorized(): boolean {
        return this.joinLearner.isAuthorized(null);
    }
}