import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarDataInjectedInterface} from 'fuse-core/news/snackbar/snack-bar-data-injected.interface';

@Component({
    selector: 'app-lets-try-assignment',
    templateUrl: './lets-try-assignment.component.html',
    styleUrls: ['./lets-try-assignment.component.scss']
})
export class LetsTryAssignmentComponent implements OnInit {

    constructor(private snackBar: MatSnackBar, @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarDataInjectedInterface) {
    }

    ngOnInit(): void {
    }

    dismiss(): void {
        this.snackBar.dismiss();
    }
}