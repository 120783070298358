import {Structures} from 'octopus-model';

export const cards = {
    cardDisplay: {
        displayLessonBreadcrumb: {
            concepts: true,
            chapters: true
        },
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: false,
            lesson: false,
            model: false,
            community: false,
            image: false,
            video: false,
            videoUrl: false,
            audio: false,
            document: false,
            url: false,
        },
        isTextButton: false,
        showProgressLabel: true,
    },
    cardDefaultHeader: '/assets/marqueblanche/images/backgrounds/header.png',
    cardFields: {
        default: {
            lesson: ['keywords', 'title', 'menu', 'image', 'description', 'activitiesCount', 'difficulty', 'assignTextIcon', 'button-assign-as-label-in-action-menu'],
            model: ['keywords', 'title', 'menu', 'image', 'description', 'activitiesCount', 'difficulty', 'assignTextIcon', 'button-assign-as-label-in-action-menu'],
        },
        learner: {
            lesson: ['description', 'image', 'keywords', 'title'],
            model: ['description', 'image', 'keywords', 'title'],
        },
    },
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            default: []
        },
        model: {
            default: []
        }
    },
    moveActionsButtonInMenu: {
        default: {
            default: true,
            lesson: true,
        },
        learner: {
            default: true,
            lesson: false,
        }
    },
    isLaunchLessonAskModalActive: {default: true, learner: false},
    playButtonUseIcon: true,
    showCardHeaderMenu: true,
    useCurrentRouterUrlFromOriginPath: true,
    addExtraDataUrl: true,
    navigateOnInit: false,
    focusOnFileAfterViewInit: true,
    useCtzBackUrlLogic: true,
    usefilterUsageAutonomie: true,
    checkAssignableState: false,
    downloadLessonPdfMethodIsAllowed: false,
    openLessonPageListPdfAllowed: true,
    openCurrentPdfAllowed: true,
    pdfDonwnloadIcon: 'file_download',
    playPreviewIcon: 'visibility',
    playMetadataIconAllowed: false,
    playAsIConIcon: 'play-as-icon',
    playAsLabelIcon: 'play-lesson',
    playAsLabelInActionMenuDynamicClass: true,
    buttonAssignIconConditionNotUseShowButtonAssign: true,
    openLessonPageUseOnlyDatacard: true,
    rootFilterSubscribeToSelectedValue: true,
    useIsLaunchLessonAskModalActiveWithoutParams: true,
};