
export enum API_ENVIRONMENT_TYPES {
    development = "development",
    preprod = "preprod",
    production = "production",
}

export enum APP_ENVIRONMENT_TYPES {
    local = "local",
    development = "development",
    preprod = "preprod",
    production = "production",
}

export const API_URLS = {
    [API_ENVIRONMENT_TYPES.development]: {
        "default": "https://preprod.lms.api.tralalere.com/",
    },
    [API_ENVIRONMENT_TYPES.preprod]: {
        "amazon_python": "https://preprod.lms-futureengineer.api.tralalere.com/",
        "cycle1tnecanope": "https://preprod.api.cycle1tnecanope.tralalere.com/",
        "cycle2et3tnecanope": "https://preprod.api.cycle2et3tnecanope.tralalere.com/",
        "cycle4tnecanope": "https://preprod.api.cycle4tnecanope.tralalere.com/",
        "default": "https://preprod.lms.api.tralalere.com/",
        "humanum": "https://preprod.api.humanum.tralalere.com/",
        "inclusive": "https://preprod.api.inclusive.tralalere.com/",
        "isc_lycee": "https://preprod.api.lycee.internetsanscrainte.education/",
        "sapiens": "https://preprod.api.sapiens.tralalere.com/",
        "mathia": "https://preprod.lms-mathia.api.tralalere.com/",
    },
    [API_ENVIRONMENT_TYPES.production]:{
        "default": "https://preprod.lms.api.tralalere.com/",
    }
} as const;

export const APP_URLS = {
    [APP_ENVIRONMENT_TYPES.local]: {
        "default": "http://localhost:4300/",
    },
    [APP_ENVIRONMENT_TYPES.development]: {
        "default": "https://preprod.lms.tralalere.com/",
    },
    [APP_ENVIRONMENT_TYPES.preprod]: {
        "default": "https://preprod.lms.tralalere.com/",
        "humanum": "https://preprod.app.ilove.tralalere.com/",
    },
    [APP_ENVIRONMENT_TYPES.production]: {
        "default": "https://preprod.lms.tralalere.com/",
    }
}

export const getUrl = (matrix: typeof APP_URLS | typeof API_URLS, brand: string, environment: string): string => {
    const defaultEnvironment = 'development';

    const env = matrix[environment] || matrix[defaultEnvironment];

    return env[brand] || env.default;
}