import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {TimelineComponent} from './timeline/timeline.component';
import {TimelineService} from './timeline.service';
import {AuthenticationService} from '@modules/authentication';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import { SliderComponent } from './slider/slider.component';
import {FormsModule} from '@angular/forms';
import { ViewerComponent } from './viewer/viewer.component';
import { PositionAndStyleDirective } from './directives/position-and-style.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

const routes: Routes = [
    {
        path: 'timeline',
        canActivate: [IsUserLogged],
        component: TimelineComponent,
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        BrowserAnimationsModule,
    ],
    declarations: [TimelineComponent, SliderComponent, ViewerComponent, PositionAndStyleDirective]
})
export class TimelineModule {
    private static isMenuSet = false;

    constructor(private dynamicNavigation: DynamicNavigationService,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<TimelineModule> {
        return {
            ngModule: TimelineModule,
            providers: [
                TimelineService
            ]
        };
    }

    private postAuthentication(): void {
        if (!TimelineModule.isMenuSet && this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {

            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'timeline',
                'title': 'Timeline',
                'translate': 'timeline.title',
                'type': 'item',
                'url': '/timeline',
                'weight': 2
            });
            TimelineModule.isMenuSet = true;
        }
    }

    private postLogout(): void {
        TimelineModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'timeline');
    }
}