import {Observable, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {fuseAnimations} from 'fuse-core/animations';
import {AssignationService} from '@modules/assignation/core/services/assignation.service';
import {AuthenticationService} from '@modules/authentication';
import {CollectionOptionsInterface} from 'octopus-connect';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {localizedDate} from 'shared/utils/datetime';

@Component({
    selector: 'app-followed-log-book',
    templateUrl: './followed-log-book.component.html',
    styleUrls: ['./followed-log-book.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
/**
 * component to show all logs for moment it's a stand alone component
 * data are get from component directly because he is only used from itself without
 * complex logic.
 */
export class FollowedLogBookComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = ['pseudo', 'type', 'title', 'countGoodAnswers', 'pourcentSuccess', 'timeToFinish', 'dateOfEnd'];

    public get displayedFilters(): string[] {
        const role = this.authService.accessLevel;
        let fields = this.assignationService.settings.followedLogBookFields[role];
        if (fields === undefined) {
            fields = this.assignationService.settings.followedLogBookFields['default'];
        }
        return fields;
    }

    resources;
    dataSource = new MatTableDataSource();
    private optionsInterface: CollectionOptionsInterface;
    public allTypes = [];
    private logsBook: ILogBook[] = [];
    public isLoading = true;
    private subscribeData = new Subscription();
    countEntities = 50;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];

    public logsBookPaginated: PaginatedCollection;

    constructor(public assignationService: AssignationService,
                private authService: AuthenticationService,
                private octopusConnect: OctopusConnectService
    ) {
        this.optionsInterface = {
            filter: this.authService.accessLevel === 'learner' ? {'assignated_user': this.authService.userData.id} : {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        };
    }

    ngOnInit(): void {
        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
        });

        if (this.shouldDisplayFilters() === false) {
            return this.fixNoDataRequest();
        }
    }

    ngOnDestroy(): void {
        this.closeSubscribe();
    }

    /**
     * close subscribtion on loading data
     */
    private closeSubscribe(): void {
        if (this.subscribeData) {
            this.subscribeData.unsubscribe();
        }
    }

    /**
     * reload data in regard of new filter
     * @param options : optionsInterface
     */
    launchSearch(options: CollectionOptionsInterface = null): void {
        this.optionsInterface = options;
        this.loadData();
    }

    /**
     * get data in regard of filter
     */
    loadData(): void {
        this.isLoading = true;
        this.closeSubscribe();
        this.subscribeData = this.loadPaginatedLogsBook(this.optionsInterface)
            .subscribe((res: DataEntity[]) => {
                this.formatData(res);
                this.dataSource.data = this.logsBook;
                this.isLoading = false;
                this.setPaginator();
            });
    }

    /**
     * format data
     * @param res dataentity array with data not formated
     */
    private formatData(res: DataEntity[]): void {
        this.logsBook = [];
        res.forEach(log => {
            this.logsBook.push({
                user: log.get('user'),
                type: log.get('type'),
                activity: log.get('activity'),
                goodAnswer: log.get('goodAnswer'),
                percent: log.get('percent'),
                duration: log.get('duration'),
                date: log.get('date')
            });
        });
    }

    /**
     * prepare data and pagination
     * @param filterOptions : filter to apply
     */
    private loadPaginatedLogsBook(filterOptions: CollectionOptionsInterface = {}): Observable<Object[]> {
        this.logsBookPaginated = this.octopusConnect.paginatedLoadCollection('log-book', filterOptions);
        return this.logsBookPaginated.collectionObservable.pipe(map(collection => collection.entities));
    }

    /**
     * set pagination todo :doesn't work why don't see seems to be same as other use
     */
    setPaginator(): void {
        if (this.logsBookPaginated.paginator) {
            this.countEntities = this.logsBookPaginated.paginator.count;
            this.pageIndex = this.logsBookPaginated.paginator.page - 1;
            this.pageRange = this.logsBookPaginated.paginator.range;
        }
    }

    /**
     * change pagination
     * @param event : page where we will go
     */
    onPaginateChange(event): void {
        this.logsBookPaginated.paginator.page = event.pageIndex + 1;
    }

    /**
     * convert timestamp date to a read date
     * @param timestamp
     */
    public timestampToDate(timestamp: string): string {
        return localizedDate(Number.parseInt(timestamp));
    }
    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    /**
     * Send a default request.
     * This method will must not exist we wait a search-filters refacto.
     *
     * Why we need it ?
     * If there's no filters, the filter component is not displayed
     * But it's the one who launch the request (this should be a parent responsability)
     * @private
     */
    private fixNoDataRequest(): void {
        this.launchSearch({
            filter: this.authService.accessLevel === 'learner' ?
                {'assignated_user': this.authService.userData.id} :
                {'assignator': this.authService.userData.id},
            page: 1,
            range: 10
        });
    }
}

interface ILogBook {
    user: string;
    type: string;
    activity: string;
    goodAnswer: number;
    percent: number;
    duration: number;
    date: string;
}