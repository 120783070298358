// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99998;
}
fuse-progress-bar mat-progress-bar .mdc-linear-progress__buffer {
  background-color: #C5C6CB !important;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,cAAA;AADJ;AAKQ;EACI,oCAAA;AAHZ","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n\r\nfuse-progress-bar {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    width: 100%;\r\n    z-index: 99998;\r\n\r\n    mat-progress-bar {\r\n\r\n        .mdc-linear-progress__buffer {\r\n            background-color: #C5C6CB !important;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
