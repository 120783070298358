import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {WidgetMessageService} from '@modules/dashboard/core/widgets/widget-messaging-thread/widget-message-thread.service';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import { MatTableDataSource } from '@angular/material/table';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-dashwidget-message',
    templateUrl: './widget-messaging-thread.component.html',
    styleUrls: ['./widget-messaging-thread.component.scss'],
    animations   : fuseAnimations
})
export class WidgetMessagingComponent implements OnInit, OnDestroy {
    @Input('messageThreadsSubscription') messageThreadsSubject: ReplaySubject<any>;
    displayedColumns = ['viewed', 'label', 'created', 'groups', 'members' ];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    resource: boolean;
    activThread: any;
    private unsubscribeInTakeUntil = new Subject<void>();
    private groups: any[];

    constructor(
        public authService: AuthenticationService,
        private widgetMessageService: WidgetMessageService,
        private router: Router,
        private sanitizer: DomSanitizer,
        private communicationCenter: CommunicationCenterService
    ) {}

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList').pipe(
                tap((groups) => this.groups = groups)
            ).subscribe();

        this.messageThreadsSubject.pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe((resources) => {
                this.dataSource.data = resources.filter((thread) => !thread['archived']).sort((a, b) => +b.lastMessage - +a.lastMessage).slice(0, 5);
                this.resource = resources && resources.length;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     *
     * @param epochMillis
     */
    parseDate(epochSecond: number): Date {
        return new Date(epochSecond * 1000);
    }

    isUnreadThread(thread: any): boolean {
        return !this.widgetMessageService.isArchived(thread) && !this.widgetMessageService.isLastMessageHasBeenViewedByUser(thread);

    }

    public goToMessage(thread): void {
        this.communicationCenter
            .getRoom('messaging')
            .next('thread.activated', thread);
        this.router.navigate(['/messaging']);
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return this.resource;
    }

    public toUserFriendlyGroup(groupId: string): string {
        const group = this.groups.find(g => g.id.toString() === groupId);
        return group ? group.groupname : '';
    }
}