import {Component, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities';
import {DataCollection, DataEntity} from 'octopus-connect';
import {ListOfActivitiesService} from '@modules/activities/core/lessons/services/list-of-activities.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {Subject, Observable} from 'rxjs';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {brand} from '../../../../../settings';
import {Location} from '@angular/common';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {LessonGranuleEntity, LessonReference} from '@modules/activities/core/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-list-of-activities',
    templateUrl: './list-of-activities.component.html',
    styleUrls: ['./list-of-activities.component.scss']
})
export class ListOfActivitiesComponent implements OnInit, OnDestroy {

    public activities: DataEntity[][] = [];
    public subLessons: DataEntity[] = [];
    public loading: boolean;
    public hideAllPageWhileLoading = true;
    public activitiesLoaded : boolean;
    public lesson: DataEntity;
    public selectedIndex = 0; // by default go to index 0 (first in tab)
    private assignment: DataEntity;
    private unsubscribeInTakeUntil: Subject<void>;
    private userSaves: DataEntity[] = [];

    constructor(private listOfActivitiesService: ListOfActivitiesService,
                private lessonsService: LessonsService,
                private activitiesService: ActivitiesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private location: Location,
                private lessonsConfigurationService: LessonsConfigurationService,
                private route: ActivatedRoute,
                private translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.unsubscribeInTakeUntil = new Subject<void>();
        this.activatedRoute.params.pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(_params => this.initialize());
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.loading = false;
    }

    public playEvent($event: { subLesson: LessonGranuleEntity, i: number }): void {
        this.loading = true;
        this.play($event.subLesson, $event.i);
    }

    public play(lesson: LessonGranuleEntity, index): void {
        const options: NavigateToLessonOptions = {
            isActivitiesListMustBeDisplayed: false,
            startOnStepIndex: index || 0,
            exitLessonUrl: this.location.path() // On utilise location parce que la vrai url (this.router.url) ne contient pas forcément le subLessonIdentifier
        };
        this.lessonsService.navigateToLesson(lesson, options, false);
    }

    public afterAssignmentAcquired(assignment): void {
        this.assignment = assignment;
        this.listOfActivitiesService.setAssignment(assignment);
        this.loadUserSaves().subscribe();
    }

    /** check if user has already done the activity - having a grade is enough to know
     * @param activity
     * @returns {boolean}
     */
    public isActivitiesIsDone(activity: DataEntity): boolean {
        return this.userSaves.some((userSave: DataEntity) => +userSave.get('granule')[0] === +activity.id && +userSave.get('grade') > 0);
    }

    /** return activity by according to its lessonType if exists
     * @param activity
     * @returns {string}
     */
    public getActivityTypeImage(activity: DataEntity): string {
        if (activity.get('metadatas').lessonType.length && activity.get('metadatas').lessonType[0]['image']) {
            return activity.get('metadatas').lessonType[0]['image'];
        }
        return '/assets/' + brand + '/images/thumbs/demo@2x-8.png';
    }

    /**
     * navigate to lesson list
     */
    public navigateToLessonList(): void {
        const exitLessonUrl = this.route.snapshot.queryParams['exitLessonUrl'];
        if (exitLessonUrl) {
            const decodedUrl = decodeURIComponent(exitLessonUrl);

            // Séparer l'URL en chemin et paramètres de requête
            const [path, queryString] = decodedUrl.split('?');
            const queryParams = this.parseQueryString(queryString);

            this.router.navigate([path], { queryParams });
        } else {
            // Fallback si exitLessonUrl n'est pas fourni
            this.router.navigate(['lessons', 'list', 'models']);
        }
    }

    private parseQueryString(queryString: string): { [key: string]: string } {
        const pairs = (queryString || "").split('&');
        const result = {};
        pairs.forEach(pair => {
            const [key, value] = pair.split('=');
            if (key) {
                result[key] = value;
            }
        });
        return result;
    }

    public onTabChange($event: number): void {
        // Attention a :
        // - ne pas utiliser this.router.navigate car cela va recharger la page
        // - ne pas utiliser this.router.url car cela ne contient pas forcément le subLessonIdentifier
        // - utiliser location.path() car on est sur que celui ci contient le subLessonIdentifier que l'on remplace
        this.location.replaceState(this.location.path() + '/../' + this.subLessons[$event].get('metadatas')?.title);
    }

    private initialize(): void {
        this.loading = true;
        this.activitiesLoaded = true;
        this.loadActivitiesInLesson(this.activatedRoute.snapshot.params['lessonId']).pipe(
            tap(() => this.setSubLessonIdentifierInUrl()),
            mergeMap(() => this.setSelectedIndexFromUrl()),
            mergeMap(() => this.lessonsService.getAssignmentsByLessonId(this.activatedRoute.snapshot.params['lessonId'])),
            map((assignmentsCollection: DataCollection) => {
                return assignmentsCollection.entities.filter((assignment: DataEntity) => assignment.get('assignated_user').uid === this.lessonsService.currentUser.id);
            }),
            tap((assignments: DataEntity[]) => {
                if (assignments && assignments.length) {
                    this.afterAssignmentAcquired(assignments[0]);
                } else {
                    // la creation d'assignation est faite dans le module assignations via communicationCenter
                    this.listOfActivitiesService.createAssignment(this.activatedRoute.snapshot.params['lessonId'], this.afterAssignmentAcquired);
                }
            }),
        ).subscribe();
    }

    private loadActivitiesInLesson(lessonId: string): Observable<DataEntity[]> {
        return this.lessonsService
            .loadLessonGranuleById(lessonId)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap((lessonEntity: DataEntity) => {
                    this.lesson = lessonEntity;
                    if (this.lesson.get('reference').every((activity: LessonReference) => activity.type === 'activity')){
                        this.play(this.lesson as LessonGranuleEntity, 0);
                    } else {
                        // Si on lance pas direct l'activité (je ne sais pas pourquoi on devrait le faire) il faut afficher la page, si par contre on lance direct une activité, cette page sert a rien alors le spinner permet de la cacher
                        this.hideAllPageWhileLoading = false;
                    }
                }),
                mergeMap((lessonEntity: DataEntity) => this.lessonsService.loadLessonActivities(lessonEntity)),
                tap((activitiesEntities: DataEntity[]) => {
                    if (activitiesEntities[0].get('format').label === 'lesson') {
                        this.loading = false;
                        this.subLessons = activitiesEntities;
                        activitiesEntities.forEach((subLesson: DataEntity) => {
                            this.lessonsService.loadLessonActivities(subLesson).subscribe((activities: DataEntity[]) => {
                                this.activities[subLesson.id] = activities;
                            });
                        });
                        this.activitiesLoaded = false;
                    } else {
                        this.activities[lessonId] = activitiesEntities;
                        this.subLessons = [this.lesson];
                    }
                }),
            );
    }

    private loadUserSaves(): Observable<DataEntity[]> {
        return this.lessonsService.loadUserSaves().pipe(
            tap((userSaves: DataEntity[]) => this.userSaves = userSaves || []),
            tap(() => this.loading = false)
        );
    }

    private setSubLessonIdentifierInUrl(): void {
        if (this.activatedRoute.snapshot.params.hasOwnProperty('subLessonIdentifier') === false) {
            // En utilisant location on ne déclanche pas un rechargement de la page alors que l'url change (on a deja les données, ca ne sert a rien de recharger)
            this.location.replaceState(this.router.url + '/' + this.subLessons[0].get('metadatas')?.title);
        }
    }

    private setSelectedIndexFromUrl(): Observable<Params> {
        return this.activatedRoute.params.pipe(tap((params: Params) => {
            const subLessonIdentifier = params['subLessonIdentifier'];
            if (subLessonIdentifier) {
                this.subLessons.forEach((subLesson: DataEntity, index: number) => {
                    if (subLesson.get('metadatas')?.title === subLessonIdentifier) {
                        this.selectedIndex = index;
                        return;
                    }
                });
            }
        }));
    }

    /**
     * is tips display in regard of settings
     */
    get isTips(): boolean {
        return this.lessonsConfigurationService.isDisplayTipsInListOfActivities();
    }

    public getNoActivitiesMessage(subLessonTitle: string): string {
        const message = this.translate.instant('activities.no-activities');  // Remplacez ceci par la manière dont vous accédez à des chaînes traduites
        return message.replace('{subLesson}', subLessonTitle);
    }

}

