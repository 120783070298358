import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ErrorReportingModalComponent, ErrorReportingDataInterface, ErrorReportingFormDataInterface} from './error-reporting-modal/error-reporting-modal.component';
import {Subject} from 'rxjs';
import {OctopusConnectService} from 'octopus-connect';

interface ErrorReportingModel {
    subject: string;
    message: string;
    granuleId: string;
    mail: string;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorReportingService {

    private formSubmitHandler = (formSubmitData) => {
        const data = this.mapDataForApiSubmit(formSubmitData.formValues);

        this.octopusConnect
            .createEntity('error-reporting', data)
            .subscribe((errorReporting) => {
                formSubmitData.onFormSubmitComplete.next();
            }, (error) => {
                formSubmitData.onFormSubmitComplete.error(error);
            });
    }

    constructor(public dialog: MatDialog,
                private octopusConnect: OctopusConnectService) {
    }

    public openDialog(reportingType: string, defaultValues: {[key: string]: string} = {}): void {
        const data: ErrorReportingDataInterface = {
            reportingType : reportingType,
            defaultValues : defaultValues,
            onFormSubmitObs : new Subject<ErrorReportingFormDataInterface>()
        };

        data.onFormSubmitObs.subscribe(this.formSubmitHandler);

        const dialogRef = this.dialog.open(ErrorReportingModalComponent, {
            data : data
        });
    }

    private mapDataForApiSubmit (rawData): ErrorReportingModel {
        return {
            subject: rawData.subject,
            message: rawData.message,
            granuleId: rawData.exerciseId ? rawData.exerciseId : rawData.resourceId,
            mail: rawData.mail
        }
    };
}