// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  padding: 2rem;
}

main {
  display: flex;
  padding-left: 5rem;
  padding-right: 5rem;
}
main mat-chip-listbox {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-share-to-groups-modal/resource-share-to-groups-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ","sourcesContent":["header {\r\n  padding: 2rem;\r\n}\r\n\r\nmain {\r\n  display: flex;\r\n  padding-left: 5rem;\r\n  padding-right: 5rem;\r\n\r\n  mat-chip-listbox {\r\n    margin-top: 0.5rem;\r\n    margin-bottom: 1rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
