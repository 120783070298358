// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-app-tags-list .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}
fuse-app-tags-list .mat-mdc-radio-button ~ .mat-mdc-radio-button {
  height: 53px;
  width: 53px;
}
fuse-app-tags-list .mat-mdc-radio-button {
  /* this rule for the color of the center of your radio button :*/
}
fuse-app-tags-list .mat-mdc-radio-button .mdc-radio__outer-circle,
fuse-app-tags-list .mat-mdc-radio-button .mdc-radio__inner-circle {
  height: 53px;
  width: 53px;
  border: none;
  background: transparent;
}
fuse-app-tags-list .mat-mdc-radio-button.mat-accent {
  /*circle which appears when you click on the radio button :*/
}
fuse-app-tags-list .mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  background: transparent;
}
fuse-app-tags-list .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  box-shadow: initial;
  box-sizing: initial;
}
fuse-app-tags-list .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__inner-circle {
  background: transparent;
}
fuse-app-tags-list .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component.scss"],"names":[],"mappings":"AAIE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,YAAA;EACA,WAAA;AAJJ;AAOE;EACE,gEAAA;AALJ;AAMI;;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AAJN;AAOI;EAaE,4DAAA;AAjBN;AAKM;EACE,uBAAA;AAHR;AAMQ;EACE,mBAAA;EACA,mBAAA;AAJV;AAMQ;EACE,uBAAA;AAJV;AASQ;EACE,6BAAA;AAPV","sourcesContent":["@import \"../../../scss/fuse\";\r\n\r\nfuse-app-tags-list {\r\n\r\n  .dialog-content-wrapper {\r\n    max-height: 85vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .mat-mdc-radio-button ~ .mat-mdc-radio-button {\r\n    height: 53px;\r\n    width: 53px;\r\n  }\r\n\r\n  .mat-mdc-radio-button {\r\n    /* this rule for the color of the center of your radio button :*/\r\n    .mdc-radio__outer-circle,\r\n    .mdc-radio__inner-circle {\r\n      height: 53px;\r\n      width: 53px;\r\n      border: none;\r\n      background: transparent;\r\n    }\r\n\r\n    &.mat-accent {\r\n      .mdc-radio__inner-circle {\r\n        background: transparent;\r\n      }\r\n      &.mat-mdc-radio-checked {\r\n        .mdc-radio__outer-circle {\r\n          box-shadow: initial;\r\n          box-sizing: initial;\r\n        }\r\n        .mdc-radio__inner-circle {\r\n          background: transparent;\r\n        }\r\n      }\r\n      /*circle which appears when you click on the radio button :*/\r\n      .mat-radio-ripple {\r\n        .mat-ripple-element {\r\n          background-color: transparent;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
