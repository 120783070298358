import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ResourceUploadModalComponent} from '@modules/corpus/core/components/resource-upload-modal/resource-upload-modal.component';
import {ResourceEditionModalComponent} from '@modules/corpus/core/components/resource-edition-modal/resource-edition-modal.component';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {ContextualService} from "@modules/corpus/core/services/contextual.service";

@Injectable()
export class MediaUploadService {

    constructor(
        private dialog: MatDialog,
        private contextualService: ContextualService,
    ) {
        this.contextualService.actionCorpusAdd$
            .subscribe(() => this.openResourceCreationModal());
    }

    openResourceCreationModal() {
        this.dialog.open(ResourceUploadModalComponent, {
            panelClass: 'corpus-create-dialog',
            disableClose: true,
            // width: '600px',
        });
    }

    openResourceEditionModal(resource: CorpusRessource) {

        this.dialog.open(ResourceEditionModalComponent, {
            panelClass: 'corpus-edit-dialog',
            disableClose: true,
            data: {
                resource: resource
            },
            // width: '600px',
        });
    }

}