import {Component, Inject, inject, Input, OnInit, HostBinding} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {ReviewChoices} from 'fuse-core/components/user-review/user-review.service';
import {AuthenticationService} from '@modules/authentication';
import {NpsService} from 'fuse-core/components/nps/nps.service';
import {Validators, FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {map, take} from 'rxjs/operators';
import {CompassResourceEntity} from 'fuse-core/services/compassResourceEntity';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-nps',
    templateUrl: './nps.component.html',
    styleUrls: ['./nps.component.scss'],
    animations: [
        trigger('fadeAndSlideInAndOut', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('600ms 400ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate('600ms 400ms ease-in-out', style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
        trigger('fadeInAndOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('100ms ease-in-out', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('100ms ease-in-out', style({ opacity: 0 })),
            ]),
        ]),
    ],
})

export class NpsComponent implements OnInit {
    private npsService = inject(NpsService);
    private authService = inject(AuthenticationService);
    private nbValue: number = 11;
    public values: number[];
    public selectedValue: number;
    public uid: number;
    public type: string;

    @HostBinding('@fadeAndSlideInAndOut') animate = true; // Apply entry and leave animations on host element

    npsForm = this.formBuilder.group({
        value: ['', Validators.required],
    });

    constructor(
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<NpsComponent>,
        //@Inject(MAT_BOTTOM_SHEET_DATA) data2,
        private _bottomSheetRef: MatBottomSheetRef<NpsComponent>,
    ) {
        this.values = Array(this.nbValue).fill(1).map((x, i) => i); /*[0,1,2,3,4,5,6,7,8,9,10]*/
        this.uid = data.uid;
        //this.type = data.type ? data.type : data2.type;
        this.type = data.type;
    }
    ngOnInit(): void {

    }

    public SaveNPS() {
        const npsToSave = {
            uid: this.authService.loggedUser.id.toString(),
            value: +this.npsForm.value.value
        }
        this.npsService.saveNps(npsToSave).subscribe();

        // close dialog or bottom after save
        if (this.type === 'dialog') {
            this.dialogRef.close('Form submitted');
        } else {
            this._bottomSheetRef.dismiss('Form submitted');
        }
    }
    public notNowAction() {
        // set displayNps to 'update' to process on the backoffice side
        this.authService.loggedUser.set('displayNps', 'update');
        this.authService.loggedUser.save();

        // close dialog or bottom after 'not now' button click
        if (this.type === 'dialog') {
            this.dialogRef.close('Not now button click');
        } else {
            this._bottomSheetRef.dismiss('Not now button click');
        }
    }

    public isUserAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

}