import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CollectionComponent} from 'fuse-core/components/collection/collection.component';
import {PresentationGeneralComponent} from 'fuse-core/components/collection/custom-blocks/presentation-general/presentation-general.component';
import {TagBlockComponent} from 'fuse-core/components/collection/custom-blocks/tag-block/tag-block.component';
import {KeyFiguresComponent} from 'fuse-core/components/collection/custom-blocks/key-figures/key-figures.component';
import { FullWidthCtaComponent } from './custom-blocks/full-width-cta/full-width-cta.component';
import { HalfWidthCtaComponent } from './custom-blocks/half-width-cta/half-width-cta.component';
import {MatChipsModule} from '@angular/material/chips';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LessonTileComponent } from './custom-blocks/lesson-tile/lesson-tile.component';
import {CardModule} from 'fuse-core/components/card/card.module';
import {FuseDirectivesModule} from 'fuse-core/directives/directives';
import {DynamicModule} from 'fuse-core/components';
import {RouterLink} from '@angular/router';
import { FaqBlockComponent } from './custom-blocks/faq-block/faq-block.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule,
        FlexLayoutModule,
        MatChipsModule,
        FuseDirectivesModule,
        RouterLink,
    ],
    declarations: [
        CollectionComponent,
        PresentationGeneralComponent,
        TagBlockComponent,
        KeyFiguresComponent,
        FullWidthCtaComponent,
        HalfWidthCtaComponent,
        LessonTileComponent,
        FaqBlockComponent
    ],
    exports: [
        CollectionComponent,
    ],

})
export class CollectionModule {
}