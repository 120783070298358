import {inject, Injectable} from '@angular/core';
import {ActivitiesService} from "@modules/activities/core/activities.service";
import {
    ActivityContent,
    ActivityContentEntity,
    ActivityGranule,
    ActivityReference, AnswerAppEntity,
    AnswerEntity,
    AnswersContent, CreateAnswerAppInterface,
    UpdateAnswerInterface, UpdatableActivityContent, UpdatableActivityReference,
} from '@modules/activities/core/models';
import {TypologiesService} from '@modules/activities/core/typologies/typologies.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {mergeMap, take} from "rxjs/operators";
import {GranuleEntity, GranuleEntityAttributesInterface} from "shared/models/granule";
import {
    MediaInterface,
    MetadataInterface,
    UpdatableGranuleInterface
} from "@modules/activities/core/lessons/lessons-interfaces.interface";
import {DataEntity, OctopusConnectService} from "octopus-connect";
import * as _ from 'lodash-es';
import {Type} from "@modules/activities/core/lessons/services/lessons.service";
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

// null is used to indicate that the endpoint is not yet known
type activityContentEndpoints = 'app' | 'awareness' | 'media' | 'qcm'  | 'rb' | 'recording' | null;

const activityContentEndpointMapping : Partial<Record<TypologyLabel, activityContentEndpoints>> = {
    [TypologyLabel.awareness]: 'awareness',
    [TypologyLabel.fillBlanks]: 'rb',
    [TypologyLabel.multimedia]: 'media',
    [TypologyLabel.multipleChoice]: 'qcm',
    [TypologyLabel.multipleChoiceUniqueAnswer]: 'qcm',
    [TypologyLabel.recording]: 'recording',
    [TypologyLabel.shortAnswer]: 'qcm',
    [TypologyLabel.sortMat]: 'qcm',
    [TypologyLabel.textMatching]: 'app',
    [TypologyLabel.trueFalse]: 'qcm',
}


@Injectable({
    providedIn: 'root'
})
export class ActivityCreationService {


    private activitiesService = inject(ActivitiesService);
    private typologiesService = inject(TypologiesService);
    private translate = inject(TranslateService);
    private octopusConnect = inject(OctopusConnectService);
    private communicationCenter = inject(CommunicationCenterService);

    private obsEntitiesForDuplicate: Observable<DataEntity[]>;

    /**
     * Create metadata values of one granule
     * @param metadata : metadata values
     * TODO not really good to remove tagModified & theme here
     */
    public createMetadata(
        metadata: MetadataInterface
    ): Observable<DataEntity> {
        delete metadata['tagModified']; // remove tagModified before metadata save
        const metadataTemp = {...metadata};
        delete metadataTemp['theme']; // remove theme before metadata save
        delete metadataTemp['usage']; // remove usage before metadata save
        return this.octopusConnect
            .createEntity('metadatas', metadataTemp)
            .pipe(take(1));
    }

    public createGranule<T extends GranuleEntity<any>>(
        Granule: Partial<GranuleEntityAttributesInterface<T['attributes']>> | (Partial<UpdatableGranuleInterface> & Partial<ActivityReference<any, any>>)
    ) {
        return this.octopusConnect.createEntity('granule', Granule).pipe(take(1)) as Observable<T>;
    }

    public createActivityReference<U, T extends ActivityContent>(
        activity: UpdatableActivityReference<U>
    ) {
        return this.octopusConnect.createEntity('activity', activity).pipe(take(1)) as Observable<TypedDataEntityInterface<ActivityReference<U, T>>>;
    }

    /**
     * Create an activity with the good typology but with potentially no activity_content
     * @param typology should be the typology object or an object with this format {label: string}
     *
     * @param activityReference
     * @param metadatas
     * @remarks Be aware that no activityContent will not work, if you not give an activity content id, you have to add it after.
     */
    public createGenericActivityGranule<U,  T extends ActivityContent>(
        typology: { label: TypologyLabel },
        activityReference?: UpdatableActivityReference<U>,
        metadatas?: Partial<MetadataInterface>
    ) {
        const requiredMetadatasValues = {
            typology: this.typologiesService.getTypologyId(typology.label),
            title: '',
            language: this.translate.currentLang,
        };

        const requiredActivityInterfaceValues = {
            instruction: '',
            activity_content: null,
            config: null,
        };

        const metadataObs = this.createMetadata(
            _.merge(requiredMetadatasValues, metadatas)
        );
        const activityInterfaceObs = this.createActivityReference<U, T>(
            _.merge(requiredActivityInterfaceValues, activityReference)
        );

        return combineLatest([metadataObs, activityInterfaceObs]).pipe(
            mergeMap(([metadatas, activityInterface]) => {
                return this.createGranule<ActivityGranule<T, U>>({
                    format: this.activitiesService.getFormatId('activity'),
                    metadatas: +metadatas.id,
                    reference: +activityInterface.id,
                });
            })
        );
    }

    /**
     * create entity granule of type multimedia with metadatas,
     * activity's interface(reference) and activity content
     * @returns {Observable<DataEntity>}
     */
    public createMultimediaActivity<U, T extends ActivityContent = AnswersContent>(
        activityReference?: UpdatableActivityReference<U>,
        metadatas?: Partial<MetadataInterface>
    ) {
        return this.createMedia({
            granule: [],
        }).pipe(
            mergeMap((mediaEntity: DataEntity) => {
                const newActivityReference = _.merge({}, activityReference, {activity_content: +mediaEntity.id});
                return this.createGenericActivityGranule<U, T>({label: TypologyLabel.multimedia}, newActivityReference, metadatas);
            })
        );
    }

    /**
     * create activity content for activity multimedia
     * @param {MediaInterface} granule
     * @returns {Observable<DataEntity>}
     */
    public createMedia(granule: MediaInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('media', granule).pipe(take(1));
    }

    /**
     * Create an activity `Tool` used for display a custom instruction relative to a tool plugin
     */
    public createToolActivity<U, T extends ActivityContent>(
        activityType: TypologyLabel,
        activityReference?: UpdatableActivityReference<U>,
        metadatas?: Partial<MetadataInterface>
    ): Observable<ActivityGranule> {
        return this.createGenericActivityGranule<U, T>({label: activityType}, activityReference, metadatas);
    }

    public createActivitiesByTypes(types: Type[]) {
        const obsContainer: Observable<GranuleEntity<any>>[] = [];
        for (const type of types) {
            switch (type.type) {
                case 'LESSON':
                    // TODO Deprecate : il faudrait que ce service ne créé que des activité, et le service lessonCreation s'occupe de créer les leçons. Là j'utilise le communication center pour éviter une dépendance circulaire mais c'est pas super propre
                    const obsActivities = [];
                    if (type.dataFromStep) {
                        if (type.summary) {
                            const obsActivity = this.createActivitySummary({type: 'SUMMARY'}, type.config);
                            obsContainer.push(combineLatest([this.obsEntitiesForDuplicate, obsActivity]).pipe(
                                mergeMap(entities => {
                                    const allActivities = [];
                                    allActivities.push(...entities[0], entities[1]);
                                    return this.communicationCenter.getRoom('lessonCreation')
                                        .getSubject('createLessonGranule')
                                        .pipe(
                                            mergeMap(fn => fn({}, {}, 'lesson', false, allActivities) as Observable<GranuleEntity<any>>)
                                        )
                                })));
                        } else {
                            obsContainer.push(obsContainer[type.dataFromStep.stepIndex]);
                        }
                    } else {
                        for (const subLessonType of type.subLessonType) {
                            switch (subLessonType) {
                                case 'POLL':
                                    obsActivities.push(this.createActivityQcm(TypologyLabel.shortAnswer, {
                                        wording: '',
                                        answers: ['']
                                    }, type.config));
                                    break;
                                case 'SUMMARY':
                                    obsActivities.push(this.createActivitySummary({type: 'SUMMARY'}, type.config));
                                    break;
                                default:
                                    break;
                            }
                        }
                        const combine = combineLatest(obsActivities);
                        this.obsEntitiesForDuplicate = combine;
                        obsContainer.push(combine.pipe(mergeMap((entities: DataEntity[]) => {
                            return this.communicationCenter.getRoom('lessonCreation')
                                .getSubject('createLessonGranule')
                                .pipe(
                                    mergeMap(fn => fn({}, {}, 'lesson', false, entities) as Observable<GranuleEntity<any>>)
                                )
                        })));
                    }
                    break;
                case 'VIDEO':
                    obsContainer.push(this.createActivityVideo(type, type.config));
                    break;
                case 'SUMMARY':
                    obsContainer.push(this.createActivitySummary(type, type.config));
                    break;
                case 'MULTI':
                    obsContainer.push(this.createMultimediaActivity( {config: type.config}));
                    break;
                default:
                    obsContainer.push(this.createGenericActivityGranule(type, {
                        config: type.config,
                        activity_content: type.activity_content
                    }));
                    break;
            }
        }

        return obsContainer;
    }

    public createAnswerEntity(Answer: UpdateAnswerInterface): Observable<AnswerEntity> {
        return this.octopusConnect.createEntity('answer', Answer).pipe(take(1)) as Observable<AnswerEntity>;
    }

    public createAnswerAppEntity(Answer: CreateAnswerAppInterface) {
        return this.octopusConnect.createEntity('answer_app', Answer).pipe(take(1)) as Observable<AnswerAppEntity>;
    }

    public createActivityQcm(typology: TypologyLabel , data: {answers: string[], wording: string}, config = null) {
        return combineLatest(
            data.answers
                .map(answer => this.createAnswerEntity({answer}))
        ).pipe(
            mergeMap((answerEntities: DataEntity[]) => this.createActivityContent(typology, {answers: answerEntities.map((entity) => +entity.id)})),
            mergeMap((qcmEntity: DataEntity) => {
                const metadataObs = this.createMetadata({
                    typology: this.typologiesService.getTypologyId(typology)
                });
                const dataObs = this.createActivityReference({
                    instruction: data.wording,
                    activity_content: +qcmEntity.id,
                    config: config
                });

                const combined: Observable<DataEntity[]> = combineLatest(metadataObs, dataObs).pipe(take(1));

                return combined.pipe(mergeMap((entities: DataEntity[]) => {
                    return this.createGranule({
                        format: this.activitiesService.getFormatId('activity'),
                        metadatas: +entities[0].id,
                        reference: +entities[1].id
                    });
                }));
            }),
            take(1)
        );
    }

    public createActivityVideo(typology, config = null) {
        const metadataObs = this.createMetadata({
            typology: this.typologiesService.getTypologyId(typology.type),
            title: ''
        });

        const dataObs = this.createActivityReference({
            instruction: '',
            activity_content: null, // set the media's 'id' fetch from created entity 'media'
            config: config
        });

        return combineLatest(metadataObs, dataObs).pipe(mergeMap((entities: DataEntity[]) => {
            return this.createGranule({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +entities[0].id,
                reference: +entities[1].id
            });
        }));
    }

    public createActivitySummary(typology, config = null) {
        const metadataObs = this.createMetadata({
            typology: this.typologiesService.getTypologyId(typology.type),
            title: ''
        });

        const dataObs = this.createActivityReference({
            instruction: '',
            activity_content: null, // set the media's 'id' fetch from created entity 'media'
            config: config
        });

        return combineLatest([metadataObs, dataObs]).pipe(mergeMap((entities: DataEntity[]) => {
            return this.createGranule<ActivityGranule>({
                format: this.activitiesService.getFormatId('activity'),
                metadatas: +entities[0].id,
                reference: +entities[1].id
            });
        }));
    }

    public createActivityContent<U extends ActivityContentEntity>(typology: TypologyLabel, content: Partial<UpdatableActivityContent>): Observable<U> {
        const endpoint = activityContentEndpointMapping[typology];
        if (!endpoint) {
            throw new Error(`No endpoint mapping for typology ${typology}`);
        }
        return this.octopusConnect.createEntity(endpoint, content) as Observable<U>;
    }
}
