import {Injectable} from '@angular/core';
import {brand} from '../../../../settings';
import {DrawLineDataInterface} from '../models/draw-line-data.interface';
import TEMPLATE_DATA from '../models/draw-line-template-data';
import * as _ from 'lodash-es';

// Url vers le dossier contenant toutes les assets propres a l'activité draw-line (deviendra peut-être plus tard un endpoint pour chopper les données et images du back)
const ASSETS_PATH = `assets/${brand}/draw-line/`;
// Url vers le dossier contenant toutes les images utilisées pour représenter les étapes de l'activité draw-line
const ASSETS_IMAGE_PATH = ASSETS_PATH + '/';

@Injectable({
    providedIn: 'root'
})
export class DrawLineService {

    getRootImageDirectoryPath(): string {
        return ASSETS_IMAGE_PATH;
    }

    getTemplate(templateIdentifier: keyof typeof TEMPLATE_DATA | string): DrawLineDataInterface {
        if ([null, undefined].includes(templateIdentifier) || _.has(TEMPLATE_DATA, templateIdentifier) === false) {
            throw new Error('Cet identifiant de template ne correspond a aucun template');
        }

        return TEMPLATE_DATA[templateIdentifier] as DrawLineDataInterface;
    }
}
