
import {map, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import { MatDialogRef } from '@angular/material/dialog';
import {forkJoin, Observable, Subject} from 'rxjs';
import {MultipleChapters} from '@modules/corpus/core/corpus-interfaces.interface';

@Component({
    selector: 'app-resource-upload-modal-step3',
    templateUrl: './resource-upload-modal-step3.component.html',
    styleUrls: ['./resource-upload-modal-step3.component.scss']
})
export class ResourceUploadModalStep3Component implements OnInit, OnDestroy {

    @Input() mode = 'creation';
    @Input() resource: CorpusRessource;

    @Output() chaptersValidated = new EventEmitter<MultipleChapters[]>();
    @Output() canceled = new EventEmitter<void>();

    public availableMethods: any[];
    public selections: MultipleChapters[] = [];
    public disableSelector = true;
    public isfrozen = false;

    private defaultMethods: any[];
    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<ResourceUploadModalStep3Component>,
        private changeDetectorRef: ChangeDetectorRef,
        private corpusService: CorpusService
    ) {
    }

    ngOnInit(): void {
        this.loadMethods();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public validateStep(): void {
        if (this.mode === 'edition'){
            this.isfrozen = true;
            this.resource.chapters = [].concat(...this.selections.map(selection => selection.chapters));
            this.resource.save().subscribe(() => {
                this.chaptersValidated.emit(this.selections);
                this.isfrozen = false;
            });
        }
        else {
            this.chaptersValidated.emit(this.selections);
        }
    }

    public onChaptersSelected($event: MultipleChapters): void {
        this.selections.push($event);
        this.loadAvailableMethods();
    }

    public onRemoveSelected($event): void {
        this.selections = this.selections.filter(selected => selected !== $event).slice();
        this.loadAvailableMethods();
    }

    private loadMethods(): void {
        this.isfrozen = this.mode === 'edition';
        this.corpusService
            .getMethods().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(data => {
                this.defaultMethods = data.entities.slice();
                if (this.mode === 'edition') {
                    this.redefineSelection().subscribe(() => {
                        this.isfrozen = false;
                        this.loadAvailableMethods();
                    });
                } else {
                    this.loadAvailableMethods();
                }
            });
    }

    private loadAvailableMethods(): void {
        this.availableMethods = this.defaultMethods.filter(method => this.selections.some(selected => selected.method === method) === false).slice();
        this.disableSelector = false;
        this.changeDetectorRef.detectChanges();
    }

    private redefineSelection(): Observable<any> {
        const chaptersIds = this.resource.chapters.map(chapter => chapter.id);
        const observables = this.defaultMethods.map(method => this.corpusService.getChapters(method.id));

        return forkJoin(observables).pipe(map((data) => {
            this.selections = data.map((value, index) => {
                return {
                    method: this.defaultMethods[index],
                    chapters : value.entities.filter(chapter => chaptersIds.includes(chapter.id))
                };
            }).filter(selection => selection.chapters.length > 0);

            return data;
        }));
    }
}