import {Component, Inject, Optional} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-delete-lesson-warning',
    templateUrl: './delete-lesson-warning.component.html'
})
export class DeleteLessonWarningComponent {

    constructor(public selfDialogRef: MatDialogRef<DeleteLessonWarningComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: { count: number }) {
    }

    accept(): void {
        this.selfDialogRef.close(true);
    }

    cancel(): void {
        this.selfDialogRef.close(false);
    }

}