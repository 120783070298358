import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DataCollection, DataEntity} from 'octopus-connect';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CollectionPaginator} from 'octopus-connect';
export interface EditorActivitiesListDialogData {
    chapters: DataEntity[];
    typologiesIds: number[];
    getIconInformation: (data: DataEntity) => { [key: string]: string };
    loadMethods: () => Observable<DataCollection>;
    launchPreview: (id: number | string) => Observable<void>;
    loadActivities: (optionsInterface?: CollectionOptionsInterface) => Observable<{ entities: DataEntity[], paginator: CollectionPaginator }>;
    onPaginateChange: (pageEvent: { [key: string]: any }) => void;
    settings: { [key: string]: any }; // if we need check for role, field or else..
    title: string; // title of the pop in
    useLessonSearchEndpoint: boolean; // used to know if we get granule from basic_search or lesson_search
    initialValues: { [key: string]: any }; // default filters values il app search filter toolbar
}

@Component({
    selector: 'app-editor-activities-list',
    templateUrl: './editor-activities-list.component.html',
})

export class EditorActivitiesListComponent implements OnInit, OnDestroy {

    public activitiesIsReady: boolean;
    public activitySelected: DataEntity;
    public chaptersList: { [any: string]: any }[]; // needed for app-search-filters to get filter chapters
    public countEntities = 0;
    public dataSource = new MatTableDataSource([]);
    public displayedColumns: string[];
    public disableBtnPreview: boolean;
    public filters: string[];
    public initialValues: { [key: string]: any } = {};
    public pageIndex = 0;
    public pageRangeOptions = [10];

    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: Partial<EditorActivitiesListDialogData>,
                public dialogRef: MatDialogRef<ChaptersSelectionComponent>) {
        if (this.data && this.data.loadMethods) {
            this.data.loadMethods().pipe(
                tap((collection: DataCollection) => this.chaptersList = collection.entities.map((entity: DataEntity) => {
                    return {
                        id: entity.id,
                        label: entity.get('label'),
                        name: entity.get('name'),
                    };
                }))
            ).subscribe();
        }
    }

    public get termInterfaceTitleActivity(): string {
        return this.data && this.data.title ? this.data.title : '';
    }

    public get thumbnail(): string {
        return this.activitySelected && this.activitySelected.get('metadatas') &&
            this.activitySelected.get('metadatas').thumbnail && this.activitySelected.get('metadatas').thumbnail.uri;
    }

    ngOnInit(): void {
        this.filters = this.data.settings && this.data.settings.filtersEditorActivitiesList || [];
        this.initialValues = this.data.initialValues || {};
        const initFilter: Partial<{ level: any, chapters: any }> = {};
        if (this.initialValues.hasOwnProperty('educationnalLevelFilter')) {
            initFilter.level = this.initialValues.educationnalLevelFilter;
        }
        if (this.initialValues.hasOwnProperty('chaptersFilter')) {
            initFilter.chapters = this.initialValues.chaptersFilter;
        }
        this.refreshList({filter: initFilter});
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public refreshList(optionsInterface: CollectionOptionsInterface = {filter: {}}): void {
        this.activitiesIsReady = false;
        optionsInterface.filter.typology = this.data.typologiesIds.join();
        optionsInterface.range = 10;
        this.data.loadActivities(optionsInterface)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil)
            ).subscribe((activitiesAndPaginator: { entities: DataEntity[], paginator: CollectionPaginator }) => {
            this.displayedColumns = this.data.settings && this.data.settings.activitiesDisplayedColumns;
            this.dataSource.data = activitiesAndPaginator.entities;
            this.countEntities = activitiesAndPaginator.paginator.count;
            return this.activitiesIsReady = true;
        });
    }

    /**
     * set activitySelected activity
     * @param {DataEntity} entity
     */
    public selectActivity(entity: DataEntity): void {
        this.activitySelected = entity;
    }

    /**
     * check wich activity is selected to add class css "selected"
     * @param {DataEntity} entity
     * @returns {boolean}
     */
    checkSelected(entity: DataEntity): boolean {
        return this.activitySelected && +this.activitySelected.id === +entity.id;
    }

    public getContentTypeIcon(data: DataEntity): { [key: string]: string } {
        return this.data && this.data.getIconInformation ? this.data.getIconInformation(data) : null;
    }

    /**
     * load activities and launch preview of the activity
     * @param {DataEntity} entity
     */
    public loadActivityForPreview(entity: DataEntity): void {
        this.disableBtnPreview = true;
        this.data.launchPreview(entity.id)
            .subscribe(() => this.disableBtnPreview = false);
    }

    /**
     * load next activities page (from drupal)
     * @param event
     */
    public changePage(event: { [key: string]: string }): void {
        if (this.data) {
            this.data.onPaginateChange(event);
        }
    }

    public displayField(field): boolean {
        return this.data.settings &&
            this.data.settings.cardFieldsForPreview && this.data.settings.cardFieldsForPreview.includes(field);
    }

    public skills(resource: DataEntity): string {
        return resource &&
            resource.get('metadatas') &&
            resource.get('metadatas').skills &&
            resource.get('metadatas').skills.map((val) => val.label).join(', ');
    }

    public chapters(entity?: DataEntity): string {
        const activity = entity ? entity : this.activitySelected;
        return activity && activity.get('metadatas') &&
            activity.get('metadatas').chapters &&
            activity.get('metadatas').chapters.map(val => val.label).join(',');
    }

    public concepts(resource: DataEntity): string {
        return resource && resource.get('metadatas') &&
            resource.get('metadatas').concepts &&
            resource.get('metadatas').map(val => val.label).join(',');
    }

    public dataEntitySetFromLesson(): boolean {
        return this.data && this.data.useLessonSearchEndpoint;
    }
}