import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: 'feedback-dialog.component.html'
})

export class FeedbackDialogComponent {


    form = this.fb.group({
        grade: [''],
        recommendation: [1],
        before: [0],
        after: [0],
        feedback: [''],
        genre: '',
    });


    constructor(
        private octopusConnect: OctopusConnectService,
        public selfDialogRef: MatDialogRef<FeedbackDialogComponent>,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: {
            save: (data) => Observable<DataEntity>,
            idLesson?: string,
            activity?: string
        }
    ) {

    }


    onSubmit(): void {
        const myFeedback = {
            grade: this.form.value.grade,
            recommendation: this.form.value.recommendation,
            before: this.form.value.before,
            after: this.form.value.after,
            feedback: this.form.value.feedback,
            genre: this.form.value.genre,
        };

/*        if (this.data?.idLesson && this.data?.activity) {
            myFeedback['activity'] = this.data?.activity;
        }*/

        this.data.save(myFeedback).subscribe((data) => {
            this.selfDialogRef.close();
        }, error => {
            console.log('error = ', error);
        });

    }

    accept(): void {
        this.selfDialogRef.close(true);
    }

    cancel(): void {
        this.selfDialogRef.close(false);
    }


}