import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {OctopusConnectModule} from 'octopus-connect';
import {UiModule} from '@modules/lesson-edition/core/ui/ui.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

const routes: Routes = [
    {
        path: 'lesson-edition',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        OctopusConnectModule,
        RouterModule.forChild(routes),
        UiModule
    ],

    exports: [RouterModule],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
    ]
})
export class LessonEditionModule {

    static forRoot(): ModuleWithProviders<LessonEditionModule> {
        return {
            ngModule: LessonEditionModule,
            providers: []
        };
    }

    constructor(
        // private communicationCenter: CommunicationCenterService,
    ) {
        // this.communicationCenter
        //     .getRoom('authentication')
        //     .getSubject('userData')
        //     .subscribe((data: UserDataEntity) => {
        //         if (data) {
        //             this.postAuthentication();
        //         } else {
        //             this.postLogout();
        //         }
        //     });

    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
    }
}