import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {CustomBlock} from './custom-blocks/custom-block.model';
import {Observable} from 'rxjs/internal/Observable';
import {filter, take, takeUntil} from 'rxjs/operators';
import {OctopusConnectService} from 'octopus-connect';
import {Location} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit, OnDestroy {
    collectionId: string;
    collection$: Observable<any>;
    customBlocks: CustomBlock[] = [];
    public showLoader = true;

    private previousSelectedFilter = '-1';
    private routerSubscription: Subscription;
    public nextUrl: string | null = null;

    constructor(
        private route: ActivatedRoute,
        private octopusConnect: OctopusConnectService,
        private location: Location,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private globalConfigurationService: GlobalConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.collectionId = this.route.snapshot.paramMap.get('alias');
        //ctz-setting
        if (this.globalConfigurationService.getSetRootFilter()) {
            this.routerSubscription = this.router.events
                .pipe(
                    filter(event => event instanceof NavigationStart)
                )
                .subscribe((event: NavigationStart) => {
                    this.nextUrl = event.url;
                });
            this.setRootFilter();
        }

        this.collection$ = this.octopusConnect.loadEntity('collections', this.collectionId).pipe(take(1));

        this.collection$.subscribe(data => {
            this.customBlocks = data.get('blocks');
            this.showLoader = false;
        });
    }

    // in regard of the opened collection page we pass the filter to the same value to
    setRootFilter(reset = false): void {
        const filterStateIndex: { [key: string]: number } = {
            'junior': 0,
            'explorer': 1, //college devient Explorer
            'python': 2
        };
        // get previous choice and reset in local storage if exist or create it
        this.communicationCenter.getRoom('authentication').getSubject('userData')
            .pipe(take(1))
            .subscribe(user => {
                const data = JSON.parse(localStorage.getItem('root-filter-choice'));
                const idCollectionForLocalStorage = this.collectionId.split('_')[0]; // remove _nl
                if (data && data !== undefined && data !== null && data.length > 0) {
                    const d = data.filter(d => d.userId === user?.id)[0];
                    if (!reset) {
                        this.previousSelectedFilter = d.selectedOption; // previous value before set the new
                    }
                    if (d) {
                        //current user as data
                        d.selectedOption = reset ? this.previousSelectedFilter : filterStateIndex[idCollectionForLocalStorage]; // remove _nl at end
                    } else {
                        //current user has no data but data exist ad another line
                        data.push({userId: user?.id, selectedOption: reset ? this.previousSelectedFilter : filterStateIndex[idCollectionForLocalStorage]});
                    }
                    localStorage.setItem('root-filter-choice', JSON.stringify(data));
                } else {
                    localStorage.setItem('root-filter-choice', JSON.stringify([{userId: user?.id, selectedOption: filterStateIndex[idCollectionForLocalStorage]}]));
                }
                // update data
                this.communicationCenter
                    .getRoom('root-filter')
                    .getSubject('selected')
                    .next(reset ? this.previousSelectedFilter : filterStateIndex[idCollectionForLocalStorage]);
            });
    }

    public goBackToPrevPage(): void {
        this.location.back();
    }

    ngOnDestroy(): void {
        // if we go to lesson detail page nothing to do else reset filter to previous value
        if (this.globalConfigurationService.getSetRootFilter() && this.nextUrl && this.nextUrl.includes('lessons') && this.nextUrl.includes('page')) {
            // nothing
        } else if (this.globalConfigurationService.getSetRootFilter() && this.nextUrl && this.nextUrl.includes('lessons') && this.nextUrl.includes('chapters')) {
            // on force le filtre sur 2 on va sur python
            this.communicationCenter.getRoom('root-filter').getSubject('selected').next(2);
        } else if (this.globalConfigurationService.getSetRootFilter()) {
            this.setRootFilter(true);
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
