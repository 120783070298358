import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, mapTo, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GamecodeDataEditorModalComponent} from '@modules/gamecode/core/gamecode-data-editor-modal/gamecode-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {NavigationExtras, Router} from '@angular/router';
import {GamecodeGenericAlertModalComponent} from '@modules/gamecode/core/gamecode-generic-alert-modal/gamecode-generic-alert-modal.component';
import {IGamecodeFormData, GamecodeRepositoryService} from '@modules/gamecode/core/gamecode-repository.service';
import {GamecodeContentViewerModalComponent} from '@modules/gamecode/core/gamecode-content-viewer-modal/gamecode-content-viewer-modal.component';
import {v4 as uuidv4} from 'uuid';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface GamecodeDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on gamecode creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface IGamecodeFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the gamecode business rules of the application
 */
export class GamecodeService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an gamecode edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onGamecodeDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};
    /**
     * Obtain the function to load lessons. This method is the container and undefined, it's filled in the constructor by calling activities-module
     */
    private loadPaginatedLessons: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>;
    private getAllowedRoleIdsForModelsCreation;
    private shareableModel;

    public application: string;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private gamecodeRepoSvc: GamecodeRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .subscribe((callback: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>) => {
                this.loadPaginatedLessons = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .subscribe((callback) => {
                this.getAllowedRoleIdsForModelsCreation = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('shareableModelCallback')
            .subscribe((callback) => {
                this.shareableModel = callback;
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a gamecode from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('gamecode')
            .getSubject('execute')
            .pipe(
                mergeMap((args: GamecodeDataCommunicationCenterInterface) =>
                    this.createOrEditGamecodeIfAlreadyExistForLesson(args.lesson.id).pipe(
                        map(gamecode => args.onComplete.next(gamecode))
                    )
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `gamecodes` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedGamecodes(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.gamecodeRepoSvc.getPaginatedGamecodes(options)),
            take(1)
        );
    }

    /**
     * Open and return the gamecode data editor modal.
     *
     * - It's the same way to create a note ({@link goToGamecodeDataCreation}) but the save method passed to the modal is used to patch gamecode
     *
     * @param gamecode Used to defined which gamecode to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     */
    public goToGamecodeDataEdition(gamecode: DataEntity): MatDialogRef<GamecodeDataEditorModalComponent, DataEntity | null> {
        return this.goToGamecodeDataAndContentEditors({
            saveGamecode: (data) => this.gamecodeRepoSvc.updateGamecode(gamecode, data),
            defaultValues: {
                title: gamecode.get('title'),
                associatedLessonId: _.get(gamecode.get('reference'), 'activity_content[0].associated_nodes[0].id')
            }
        });
    }

    /**
     * Open and return the gamecode data editor modal.
     *
     * - It's the same way to edit a note ({@link goToGamecodeDataCreation}) but the save method passed to the modal is used to create gamecode*
     */
    public goToGamecodeDataCreation(defaultValues?: IGamecodeFormData, options?: IGamecodeFormOptions): MatDialogRef<GamecodeDataEditorModalComponent, DataEntity | null> {
        return this.goToGamecodeDataAndContentEditors({
            saveGamecode: (data) => this.gamecodeRepoSvc.createGamecode(data),
            defaultValues: defaultValues,
            options: options
        });
    }

    /**
     * Redirect to the list of current user's gamecode
     */
    public goToGamecodeList(): Promise<boolean> {
        return this.router.navigate(['gamecode', 'list']);
    }

    /**
     * Obtain the lesson available to be associated to a gamecode
     * An lesson is available because
     * - User have access to it
     * - No current user's gamecode are already associated to it
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociableLessons(): Observable<DataEntity[]> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            mergeMap(currentUser => {
                const allModelsWithoutGamecode$ = this.getLessons({
                    filter: {
                        'removeGamecodeAttachedNode': true,
                        'role': this.getAllowedRoleIdsForModelsCreation(), // get role used for models creation
                        'model': this.shareableModel()
                    }
                });

                const allMyLessonsWithoutGamecode$ = this.getLessons({
                    filter: {
                        'author': currentUser.id,
                        'removeGamecodeAttachedNode': true,
                    }
                });

                return combineLatest([
                    allModelsWithoutGamecode$,
                    allMyLessonsWithoutGamecode$
                ]);
            }),
            map(([notSharedLessons, sharedLessons])  => [...notSharedLessons, ...sharedLessons])
        );
    }

    /**
     * Obtain the lesson already associated to a gamecode o current user
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociatedLessons(): Observable<DataEntity[]> {
        return this.getLessons({
            filter: {
                'removeGamecodeAttachedNode': false
            }
        });
    }

    /**
     * Obtain the all lessons
     * @param filterOptions
     */
    public getLessons(filterOptions: {}): Observable<DataEntity[]> {
        const mergedOptions = _.merge({
            filter: {
                'multi_step': 0
            }
        }, filterOptions);

        return this.loadPaginatedLessons('all', null, '', mergedOptions);
    }

    /**
     * Ask user if he confirm the gamecode deletion and, if it's ok, delete it
     * @param id of the gamecode granule
     */
    public deleteGamecode(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'gamecode.ask_before_delete_alert'}};
        return this.dialog.open(GamecodeGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.gamecodeRepoSvc.destroyGamecode(id))
        );
    }

    /**
     * Display the gamecode content as a read-only modal
     * @param id of the gamecode Granule
     */
    displayGamecode(id: number | string): void {
        this.dialog.open(GamecodeContentViewerModalComponent, {data: {gamecode$: this.gamecodeRepoSvc.getGamecode(id)}});
    }

    /**
     * Open the gamecode editor modal and if there are a gamecode given on closed go to gamecode content editor
     * @param data The saveGamecode method receive the form data TODO give an interface of form data here
     */
    private goToGamecodeDataAndContentEditors(
        data: { saveGamecode: (data) => Observable<DataEntity>; defaultValues: IGamecodeFormData; options?: IGamecodeFormOptions }
    ): MatDialogRef<GamecodeDataEditorModalComponent, DataEntity | null> {
        const modalData = _.merge({
            availableLessons$: this.getAssociableLessons(),
            getLessonById: (id: string | number) => this.getLessonById(id),
            application: this.application
        }, data);

        const dialogRef = this.dialog.open(GamecodeDataEditorModalComponent, {
            data: modalData
        });

        dialogRef.afterClosed().pipe(
            // If is gamecodeGranule empty is because the modal is closed without want to create/edit the gamecode
            filter(gamecodeGranule => !!gamecodeGranule),
            tap(gamecodeGranule => {
                this.goToGamecodeContentEditor(gamecodeGranule);
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the gamecode content editor page.
     *
     * @param gamecodeGranule
     * @param navigationExtras some data to pass by the url, show {@link onGamecodeDataEditionCompleteSubject} for example
     */
    private goToGamecodeContentEditor(gamecodeGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['code', gamecodeGranule.get('application'), gamecodeGranule.id, 'edit'], navigationExtras);
    }

    /**
     * Obtain a lesson from the activities module
     * @param id unique identifier of the lesson DataEntity
     */
    private getLessonById(id: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: id,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    /**
     * Generic way to open a note hypothetically already created
     * @param lessonId unique identifier of lesson granule used to identify if a gamecode is alrealdy associated to this lesson.
     *  Open the gamecode editor if true, the gamecode creator if false but with the lesson associated by default
     */
    private createOrEditGamecodeIfAlreadyExistForLesson(lessonId: number | string): Observable<DataEntity> {
        const onCompleteSubject = new ReplaySubject<DataEntity>(1);
        const onCompleteSubjectId: string = uuidv4();
        this.onGamecodeDataEditionCompleteSubject[onCompleteSubjectId] = onCompleteSubject;

        return this.getCurrentUserPaginatedGamecodes({filter: {associated_node: lessonId}}).pipe(
            // We load the gamecode from the lesson id associated
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable.pipe(
                take(1)
            )),
            map(collection => collection.entities),
            mergeMap(entities => {
                if (entities.length > 1) {
                    throw new Error('a user must have only one note by lesson');
                } else if (entities.length === 0) {
                    // If no gamecode, we creating it
                    return this.goToGamecodeDataCreation({associatedLessonId: lessonId}, {associatedLessonId: {disable: true}}).afterClosed();
                } else {
                    // Else we reload it but from another endpoint to get all datas
                    return this.gamecodeRepoSvc.getGamecode(entities[0].id);
                }
            }),
            // If the user cancel the gamecode creation there are no gamecode
            filter(gamecode => !!gamecode),
            // whether it existed or not, we are modifying the content now
            tap((gamecode: DataEntity) => this.goToGamecodeContentEditor(gamecode, {queryParams: {onComplete: onCompleteSubjectId}, skipLocationChange: false})),
            mergeMap(() => onCompleteSubject)
        );
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }
}