import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModelSchema, Structures} from 'octopus-model';
import {
    brandLogoSvg,
    defaultURL,
    modulesSettings,
} from '../../../../settings';

import {AuthenticationService} from '../authentication.service';
import {DataEntity} from 'octopus-connect';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {currentTimestamp} from 'shared/utils/datetime';
import {fuseAnimations} from 'fuse-core/animations';
import {takeUntil} from 'rxjs/operators';
import {FloatLabelType} from '@angular/material/form-field';
import {Roles} from 'shared/models/roles';
import {LocalCacheService} from '@modules/authentication/core/services/local-cache.service';
import {CachedUser} from '@modules/authentication/core/models/cached-user.type';
import {
    AuthenticationConfigurationService
} from '@modules/authentication/core/services/authentication-configuration.service';
import {CommunicationCenterService} from '@modules/communication-center';

const settingsAuthStructure: ModelSchema = new ModelSchema({
    activeChangePasswordStrategy: Structures.boolean(false),
    askForHelp: Structures.boolean(false),
    displayLoginLogo: Structures.boolean(false),
    enableFormLogin: Structures.boolean(true),
    enableGAR: Structures.boolean(false),
    enableSSO: Structures.boolean(false),
    urlSSO: Structures.object(),
    validateEmailStrategyActivated: Structures.boolean(false),
    licenceLink: Structures.string(''),
    urlGarRegister: Structures.string(''),
    entButtonAllowedWithoutRules: Structures.boolean(true),
    useUrlGarRegister: Structures.boolean(false),
});

export interface AuthenticationServiceSettings {
    firstConnexionRedirection: { [key in Roles | 'default']: string };
    floatLabelControl: FloatLabelType;
    isRegisterLinkTop: boolean;
    overrideDefaultRouteByRole: { [key in Roles | 'default']: string };
    selfSignup: boolean;
    redirectSignup: { url: string, target: string };
    signup: boolean;

}

export interface LoginPageSettings {
    activeChangePasswordStrategy: boolean;
    askForHelp: boolean;
    displayLoginLogo: boolean;
    enableFormLogin: boolean;
    enableGAR: boolean;
    enableSSO: boolean;
    urlSSO: { [lang: string]: string };
    validateEmailStrategyActivated: boolean;
    licenceLink: string;
    urlGarRegister: string;
    entButtonAllowedWithoutRules: boolean;
    useUrlGarRegister: boolean;
}

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FuseLoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loginFormErrors: any;
    hide = true;
    hideNewPassword = true;
    hideConfirmPassword = true;
    private hideSessions = false;
    public brandLogoSvg = brandLogoSvg;
    public renewPassword = false;
    message: any;
    public modeSSO = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    /** @deprecated TODO Utiliser le service de configuration */
    settings: AuthenticationServiceSettings;
    /** @deprecated TODO Utiliser le service de configuration */
    settingsAuth: LoginPageSettings;
    public newLinkSendSuccess = false;
    public newLinkSendError = false;
    public isRegisterLinkTop = false;
    public floatLabelControl: FloatLabelType;
    private unsubscribeInTakeUntil = new Subject<void>();
    public isLoading = false;
    public showMessageForReloadPage = false;
    private tralacodeIdText = ''; // by default add nothing
    private oldPassword = '';
    public entButtonAllowed = false; // only allowed for fr language
    constructor(
        private layoutConfigService: LayoutConfigService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private configuration: AuthenticationConfigurationService,
        private localCache: LocalCacheService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.loginFormErrors = {
            login: {},
            password: {},
            newPassword: {},
            confirmPassword: {},
            emailValidation: {},
            licenceValidation: {},
        };

        this.settings = this.authenticationService.settings as AuthenticationServiceSettings;
        this.settingsAuth = settingsAuthStructure.filterModel(
            modulesSettings.authentication
        ) as LoginPageSettings;
        this.isRegisterLinkTop = this.settings.isRegisterLinkTop;
        this.floatLabelControl = this.settings.floatLabelControl;
    }

    public get selfSignup(): boolean {
        return this.configuration.isSelfSignupEnabled;
    }

    get redirectSignup(): { url: string, target: string } {
        return this.settings.redirectSignup;
    }

    public hasRedirectUrl(): boolean {
        return !!this.settings.redirectSignup.url;
    }

    public get signup(): boolean {
        return this.configuration.isSignupEnabled;
    }

    public get displayGARHelper(): boolean {
        return this.configuration.displayGARHelper;
    }

    public get displaySessions(): boolean {
        return this.localCache.hasCachedUsers && !this.hideSessions && !this.renewPassword;
    }

    public get licenceLink(): string {
        return this.settingsAuth?.licenceLink;
    }

    public get entButtonAllowedWithoutRules(): boolean {
        return this.settingsAuth?.entButtonAllowedWithoutRules;
    }

    public get useUrlGarRegister(): boolean {
        return this.settingsAuth?.useUrlGarRegister;
    }

    ngOnInit(): void {
        //ctz-setting
        if (this.entButtonAllowedWithoutRules) {
            this.entButtonAllowed = true;
        } else {
            //citizen-code case
            this.entButtonAllowed = localStorage.getItem('lang') === 'fr' || localStorage.getItem('lang') === null;
            this.communicationCenter.getRoom('current-language').getSubject('id').subscribe(lng => {
                if (lng === 'fr') {
                    this.entButtonAllowed = true;
                } else {
                    this.entButtonAllowed = false;
                }
            });
        }
        if (this.route.snapshot.queryParams.code) {
            this.modeSSO = true;
            this.authenticationService.loginSSO(this.route.snapshot.queryParams.code);
        }

        const loginValidators = this.authenticationService.onlyLoginWithMail()
            ? [Validators.required, Validators.email]
            : [Validators.required];

        this.loginForm = this.formBuilder.group(
            {
                login: ['', loginValidators],
                password: ['', Validators.required]
            }
        );

        this.loginForm.valueChanges.subscribe(formValues => {
            this.onLoginFormValuesChanged();
        });

        this.authenticationService.errorHttpAuthentication
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((errorHttp) => {
                this.isLoading = false;
                setTimeout(() => {
                    if (errorHttp.code === 401) {
                        const controlpassword = this.loginForm.get('password');

                        // On ne met pas l'identifiant en erreur, uniquement le mot de passe
                        // Il ne faut pas que l'utilisateur puisse savoir si l'identifiant est bon ou pas (sécurité)
                        controlpassword.setErrors({invalid: true});

                        this.loginFormErrors['password'] = controlpassword.errors;
                    } else if (errorHttp.code === 403) {
                        // email was not validated by email link
                        const controlLogin = this.loginForm.get('login');
                        controlLogin.setErrors({invalid: true});
                        if (errorHttp.data.response.title === 'No valid Licence found.' || errorHttp.data.response.title === 'Expired licence.' || errorHttp.data.response.title === 'Licence start on ') {
                            this.loginFormErrors['licenceValidation'] = controlLogin.errors;
                        } else {
                            this.loginFormErrors['emailValidation'] = controlLogin.errors;
                        }
                    } else {
                        this.showMessageForReloadPage = true;
                    }
                }, 500);
            });
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * login user : with optionnal change password possibility force by rules
     */
    public login(label?: string, pass?: string): void {
        this.isLoading = true;
        this.resetEmailSendMessages();

        if (!label) {
            label = this.tralacodeIdText + this.loginForm.value['login'];
            this.tralacodeIdText = ''; // we renew password after trying to log with account who user tralacodeid
        }
        if (!pass) {
            pass = this.loginForm.value['password'] !== undefined ? this.loginForm.value['password'] : this.oldPassword; //password store if renew password
        }

        if (label && pass) {
            this.authenticationService
                .authenticateIn(
                    'http',
                    label,
                    pass
                )
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((user: UserDataEntity) => {
                    if (
                        this.settingsAuth.validateEmailStrategyActivated &&
                        !user.get('email_status')
                    ) {
                        this.authenticationService.logoutFrom('http');
                        this.isLoading = false;
                        return;
                    }

                    // set value is need for pass form on valid state
                    this.loginForm.controls.login.setValue(label);

                    if (this.isNewPasswordToSet()) {
                        this.addPasswordResetAndRenewControl();
                        this.updatePassword();
                        return;
                    }
                    // must be after condition this.isNewPasswordToSet()
                    if (this.isExpirePassword(user)) {
                        this.oldPassword = pass; // store old password for update password
                        this.addPasswordResetAndRenewControl();
                        this.loginForm.removeControl('password');
                        if (this.loginForm.get('login').value.includes('tralacodeid')) {
                            this.tralacodeIdText = 'tralacodeid'; // we add it later again to login
                        }
                        this.loginForm.patchValue({login: (this.loginForm.get('login').value).replace('tralacodeid', '')});
                        this.renewPassword = true;
                        this.authenticationService.logoutFrom('http');
                        this.isLoading = false;
                        return;
                    }

                    const isTrainer = !!(
                        user.get('role').findIndex((role) => role === 5) >= 0
                    ); // tester autrement qu'avec un numero (voir authService)
                    // it's fisrts user trainer connexion and help is active
                    if (
                        user.get('access') &&
                        user.get('first_access') === true &&
                        this.settingsAuth.askForHelp &&
                        isTrainer
                    ) {
                        this.help();
                    } else {
                        this.authenticationService.navigateAfterLogin();
                    }
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
        }
    }

    addPasswordResetAndRenewControl(): void {
        this.loginForm.addControl('newPassword', this.formBuilder.control('', Validators.required));
        this.loginForm.addControl('confirmPassword', this.formBuilder.control('', Validators.required));
        this.loginForm.setValidators(this.mustMatchAndBeNewPassword('password', 'newPassword', 'confirmPassword'));
    }

    loginSSO(): void {
        if (this.settingsAuth.enableSSO) {
            window.location.href =
                this.settingsAuth.urlSSO[this.translate.currentLang] +
                defaultURL +
                'login';
        }
    }

    public loginSession(user: CachedUser): void {
        if (user?.codeid) {
            this.login(`tralacodeid${user.label}`, user.codeid);
        } else {
            if (user) {
                this.loginForm.controls.login.setValue(user.label);
            }
            this.hideSessions = true;
        }
    }

    onLoginFormValuesChanged(): void {
        this.resetEmailSendMessages();

        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    /**
     * user haven't valdiate is email send a new link to register email
     */
    sendLinkToValidateEmail(): void {
        this.authenticationService
            .sendNewLinkEmailValidation(this.loginForm.value['login'])
            .subscribe(
                (email) => {
                    this.newLinkSendSuccess = true;
                    const controlLogin = this.loginForm.get('login');
                    controlLogin.setErrors({});
                    this.loginFormErrors['emailValidation'] = controlLogin.errors;
                    this.loginFormErrors['licenceValidation'] = controlLogin.errors;
                    setTimeout(() => {
                        this.newLinkSendSuccess = false;
                    }, 3000);
                },
                (error: Object) => {
                    this.newLinkSendError = true;
                    console.error(error);
                }
            );
    }

    /**
     * custom validator to check that two fields match and are différent of original password
     *
     */
    private mustMatchAndBeNewPassword(
        passwordControlName: string,
        newPasswordcontrolName: string,
        confirmPasswordControlName: string
    ): any {
        return (formGroup: UntypedFormGroup) => {
            const controlOriginalPasword = formGroup.controls[passwordControlName];
            const controlNewPassword = formGroup.controls[newPasswordcontrolName];
            const controlConfirmPassword =
                formGroup.controls[confirmPasswordControlName];

            if (
                controlConfirmPassword.errors &&
                !controlConfirmPassword.errors.notMatch
            ) {
                return;
            }

            if (controlOriginalPasword && controlOriginalPasword?.value && controlOriginalPasword.value === controlNewPassword.value) {
                controlNewPassword.setErrors({samePasswordAsOrigine: true});
            } else {
                controlNewPassword.setErrors(null);
            }

            if (controlNewPassword.value !== controlConfirmPassword.value) {
                controlConfirmPassword.setErrors({notMatch: true});
            } else {
                controlConfirmPassword.setErrors(null);
            }
        };
    }

    /**
     * reset messages success and error
     * @private
     */
    private resetEmailSendMessages(): void {
        this.newLinkSendSuccess = false;
        this.newLinkSendError = false;
    }

    /**
     * open modal asking if user need help at first connexion
     */
    private help(): void {
        const data = {
            titleDialog: 'generic.ask.help',
            bodyDialog: 'generic.ask.help.content',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };

        this.translate
            .get(data.titleDialog)
            .subscribe((translation: string) => (data.titleDialog = translation));
        this.translate
            .get(data.bodyDialog)
            .subscribe((translation: string) => (data.bodyDialog = translation));
        this.translate
            .get(data.labelTrueDialog)
            .subscribe((translation: string) => (data.labelTrueDialog = translation));
        this.translate
            .get(data.labelFalseDialog)
            .subscribe(
                (translation: string) => (data.labelFalseDialog = translation)
            );

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data,
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // help page
                this.router.navigate(['home']);
            } else {
                // normal navigate
                this.authenticationService.navigateAfterLogin();
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * update password and go to program if no error
     */
    private updatePassword(): void {
        this.authenticationService.loggedUser.set(
            'password',
            this.loginForm.value['newPassword']
        );
        this.authenticationService.loggedUser.save().subscribe(
            (userUpdate: DataEntity) => {
                this.renewPassword = false;
                this.authenticationService.navigateAfterLogin();
            },
            (error) => {
                console.log(error);
                this.isLoading = false;
            }
        );
    }

    /**
     * is user expiration password date is passed
     * @param user: DataEntity
     */
    private isExpirePassword(user: DataEntity): boolean {
        return (
            this.settingsAuth.activeChangePasswordStrategy &&
            user.get('expirePassword') &&
            currentTimestamp() >= user.get('expirePassword') &&
            user.get('expirePassword') !== null &&
            user.get('expirePassword') !== undefined
        );
    }

    /**
     * is the new password need to be set
     */
    private isNewPasswordToSet(): boolean {
        return (
            this.settingsAuth.activeChangePasswordStrategy &&
            this.loginForm.value['newPassword'] &&
            this.loginForm.value['newPassword'] !== null &&
            this.loginForm.value['newPassword'] !== undefined &&
            this.loginForm.value['newPassword'] !== ''
        );
    }

    get urlGarRegister(): string {
        return this.settingsAuth?.urlGarRegister ? this.settingsAuth?.urlGarRegister : '';
    }

    /**
     * open modal to confirm exit
     * if confirm then exit else stay here
     */
    private displayENTHelp(): void {
        if (this.settingsAuth?.useUrlGarRegister) {
            window.open(this.urlGarRegister, '_blank');
        } else {
            this.translate.get('cookie.dismiss').subscribe((translation: string) => {
                this.dialog.open(ModalPageComponent, {
                    panelClass: 'entity-form-dialog',
                    data: {alias: 'me-connecter-ent', labelConfirmDialog: translation},
                });
            });
        }
    }
}