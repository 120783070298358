import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {takeUntil} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';

@Component({
    templateUrl: 'only-model-lessons-list.component.html',
    selector: 'app-only-model-lessons-list',
    encapsulation: ViewEncapsulation.None
})
export class OnlyModelLessonsListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public creatorsRolesFilter: number[] = this.lessonService.getAllowedRoleIdsForModelsCreation();
    public selectedOption = null;
    public isLearner = false;

    constructor(public lessonService: LessonsService,
                public communicationCenter: CommunicationCenterService) {
        super();
        this.communicationCenter
            .getRoom('root-filter')
            .getSubject('selected')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(res => {
                this.selectedOption = res;
            });
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(user => {
                this.isLearner = user.get('role').indexOf(6) !== -1;
            });
    }

    ngOnInit(): any {
        this.lessonService.isShareableCommunity = false;
    }
}

