// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
}
:host .mat-mdc-tab-body-wrapper {
  flex: 1 0 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-body-wrapper {
  flex: 1 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-management.component.scss"],"names":[],"mappings":"AAAA;EACE,2HAAA;AACF;AAAE;EACE,cAAA;AAEJ;;AACA,2HAAA;AACA;EACE,cAAA;AAEF","sourcesContent":[":host {\r\n  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */\r\n  .mat-mdc-tab-body-wrapper {\r\n    flex: 1 0 auto;\r\n  }\r\n}\r\n/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */\r\n.mat-mdc-tab-body-wrapper {\r\n  flex: 1 0 auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
