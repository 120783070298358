import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserReviewComponent} from 'fuse-core/components/user-review/user-review.component';
//import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';

/*const routes: Routes = [
    {
        path     : 'user-review',
        component: UserReviewComponent
    }
];*/

@NgModule({
    declarations: [
        UserReviewComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        //RouterModule.forChild(routes),
        MatIconModule,
        MatDialogModule,
    ]
})
export class UserReviewModule { }