import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import {CommonModule} from '@angular/common';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {FormsModule} from '@angular/forms';
import {RewardsDialogComponent} from '@modules/gamification/core/rewards-dialog/rewards-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        RewardsDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        ErrorReportingModule,
        FormsModule,
        MatInputModule,
        TranslateModule,
        MatTooltipModule
    ],
})
export class RewardsDialogModule
{
}