// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-navigation {
  display: flex;
  flex: 1 0 auto;
  max-height: 100%;
  height: 100%;
}
fuse-navigation > .nav {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.folded:not(.unfolded) fuse-navigation .menu-link > .nav-link-title {
  opacity: 0;
  transition: opacity 200ms ease;
}
fuse-navigation .menu-link {
  text-decoration: none !important;
  display: flex;
  align-content: center;
  align-items: center;
}
fuse-navigation .menu-link > .nav-link-title {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
}
fuse-navigation .menu-link > .mat-icon {
  margin: 5px 20px;
  color: white;
}
fuse-navigation .menu-link-block {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/navigation.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;AADF;AAGE;EACE,SAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AADJ;AAMM;EACE,UAAA;EACA,8BAAA;AAJR;AASE;EACE,gCAAA;EAaA,aAAA;EACA,qBAAA;EACA,mBAAA;AAnBJ;AAMI;EACE,0BAAA;EACA,+BAAA;EACA,iBAAA;AAJN;AAOI;EACE,gBAAA;EACA,YAAA;AALN;AAaE;EACI,WAAA;AAXN","sourcesContent":["@import \"/src/@fuse/scss/fuse\";\r\n\r\nfuse-navigation {\r\n  display: flex;\r\n  flex: 1 0 auto;\r\n  max-height: 100%;\r\n  height: 100%;\r\n\r\n  > .nav {\r\n    margin: 0;\r\n    padding: 0;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n  }\r\n\r\n  .folded:not(.unfolded) & {\r\n    .menu-link {\r\n      > .nav-link-title {\r\n        opacity: 0;\r\n        transition: opacity 200ms ease;\r\n      }\r\n    }\r\n  }\r\n\r\n  .menu-link {\r\n    text-decoration: none !important;\r\n\r\n    > .nav-link-title {\r\n      text-decoration: underline;\r\n      color: rgba(255, 255, 255, 0.5);\r\n      font-weight: bold;\r\n    }\r\n\r\n    > .mat-icon {\r\n      margin: 5px 20px;\r\n      color: white;\r\n    }\r\n\r\n    display: flex;\r\n    align-content: center;\r\n    align-items: center;\r\n  }\r\n\r\n  .menu-link-block {\r\n      width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
