import {Component, OnDestroy, OnInit, Input, inject} from '@angular/core';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ActivityGranule, MediaContent} from '@modules/activities/core/models';
import {Subject} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataCollection, DataEntity} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {take, tap} from 'rxjs/operators';


@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit, OnDestroy {

    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private lessonsService = inject(LessonsService);
    private activitiesService = inject(ActivitiesService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private dialog = inject(MatDialog);

    @Input() activitySummary: ActivityGranule<MediaContent[]>;
    @Input() config: unknown;

    private assignedCount = 20; // default value, same as in short-answer.component.ts
    private unsubscribeInTakeUntil = new Subject<void>();
    private params: Params;
    private activity: ActivityGranule;
    private userSaves: DataEntity[];
    private summaryCallback: (...data) => void;
    private videoConfig = {};

    public markers: { id: string|number }[] = [];
    public mode: 'marker' | 'poll' | 'qcmu';
    public showSpinner: boolean;
    public polls: unknown[];
    public outsidePollColor: string;
    public insidePollColor: string;
    public answers : unknown[] = [];
    public warningsAllowed: boolean;
    public warnings: DataEntity[] = [];
    public warningControl: UntypedFormControl;
    public isSummaryInPlayer: boolean;

    ngOnInit() {
        this.warningsAllowed = this.activitiesService.settings.warnings;
        this.route.queryParams.subscribe(params => {
            this.params = params;
        });

        if (!this.activitySummary && !this.lessonsService.getCurrentActivity() ||
            this.lessonsService.getCurrentActivity() &&
            this.params.activityId && +this.params.activityId !== +this.lessonsService.getCurrentActivity().id) {
            this.showSpinner = true;
            this.activitiesService.loadActivitiesFromId(this.params.activityId.toString()).pipe(
                take(1))
                .subscribe(activity => {
                    this.lessonsService.setCurrentActivity(activity);
                    this.initialize();
                    this.showSpinner = false;
                });
        } else {
            this.isSummaryInPlayer = true;
            this.initialize();
        }

    }

    ngOnDestroy() {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private initialize(): void {
        if (this.config) {
            this.userSaves = this.config['userSaves'];
            this.summaryCallback = this.config['callback'];
        }
        if (this.activitySummary) {
            this.activity = this.activitySummary;
        } else {
            this.activity = this.lessonsService.getCurrentActivity();
        }

        if (this.activitiesService.currentAssignment) {
            this.assignedCount = +this.activitiesService.currentAssignment.get('assignatedCount');
        }

        if (this.activity && typeof this.activity.get('reference') === 'object') {
            if (this.warningsAllowed) {
                this.activitiesService.loadWarnings()
                    .pipe(
                        take(1),
                        tap((commentsCollection: DataCollection) => this.warnings = commentsCollection.entities),
                        tap(() => {
                            if (this.activity.get('metadatas').comment && this.activity.get('metadatas').comment !== '') {
                                return this.warningControl = new UntypedFormControl(this.warnings
                                    .find((warning: DataEntity) => warning.id.toString() === this.activity.get('metadatas').comment));
                            } else {
                                return this.warningControl = new UntypedFormControl(this.warnings[0]);
                            }
                        })
                    ).subscribe();
            }

            if (this.activity.get('reference').config.markers) {
                this.mode = 'marker';
                this.processMarker();
            }
            if (this.activity.get('reference').config.poll) {
                this.mode = 'poll';
                this.processPoll();
            }
            if (this.activity.get('reference').config.qcmu) {
                this.mode = 'qcmu';
                this.processQcmu();
            }
        }

    }

    processMarker(): void {
        this.markers = this.activity.get('reference').activity_content[0].granule[0].reference.activity_content[0].marker;

        this.videoConfig = {
            config: {
                src: this.activity.get('reference').activity_content[0].granule[0].reference.activity_content[0].granule[0].reference.url
            }
        };
    }

    /**
     *we process the usersaves to display a correction of the exercises
     * this.answers contain class css : correct or wrong
     */
    private processQcmu(): void {
        const activityReferenced = this.activity.get('reference').activity_content[0].granule.slice();
        if (this.config && this.config['userSaves'] && this.config['userSaves'].length) {
            const usersaves = activityReferenced.map((activity) => {
                return this.config['userSaves'].find((usersave) => usersave && +usersave.get('granule') === +activity.id);
            });

            this.answers = activityReferenced.map((activity, index) => {
                const answersAvailable = activity.reference.activity_content.answers;
                if (usersaves[index] && answersAvailable) {
                    const answer = answersAvailable
                        .find((availableAnswer) => availableAnswer.id === usersaves[index].get('userActivity').entitySave.answers[0].id);

                    // answer can be '0' or '1' or true or false
                    return answer && +answer.correct_answer ? 'correct' : 'wrong';
                }
                return 'wrong';
            });
        } else {
            this.answers = activityReferenced.map(() => 'wrong');
        }
    }

    processPoll(): void {
        const lessonStepSettings = this.lessonsConfigurationService.getLessonStep()
        this.outsidePollColor = lessonStepSettings?.outsidePollColor;
        this.insidePollColor = lessonStepSettings?.insidePollColor;

        this.polls = this.activity.get('reference').activity_content[0].granule
            .map((activity, index) => {
                let activityReferenced: any[];
                if (this.config && this.config['userSaves']) {
                    activityReferenced = this.config['userSaves'].filter((userSave) => {
                        return Array.isArray(userSave.get('granule')) ? +userSave.get('granule')[0] === +activity.id : +userSave.get('granule') === +activity.id;
                    }).sort((a, b) => {
                        return +a.get('step') - +b.get('step');
                    });
                }
                const legend = this.activity.get('reference').activity_content[0].granule[index] &&
                typeof this.activity.get('reference').activity_content[0].granule[index].reference === 'object' ?
                    this.activity.get('reference').activity_content[0].granule[index].reference.instruction : '';
                const poll0 = activityReferenced && activityReferenced[0].get('userActivity').entitySave.answers[0] && +activityReferenced[0].get('userActivity').entitySave.answers[0].answer ? +activityReferenced[0].get('userActivity').entitySave.answers[0].answer : 0;
                const poll1 = activityReferenced && activityReferenced[1].get('userActivity').entitySave.answers[0] && +activityReferenced[1].get('userActivity').entitySave.answers[0].answer ? +activityReferenced[1].get('userActivity').entitySave.answers[0].answer : 0;
                return {
                    poll1: Math.round(poll0 * 100 / this.assignedCount),
                    poll2: Math.round(poll1 * 100 / +this.assignedCount),
                    legend: legend
                };
            });
    }

    /**
     * open modal with video-markers details.
     * if usersave exist, we are in the player,
     * so we hide the details of the markers and show only the second panel with the user answer
     * @param selectedMarker
     */
    showMarkersDetail(selectedMarker): void {
        if (this.userSaves && this.userSaves[0] && this.videoConfig) {
            const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
            // 0 == false return true
            if (index && index !== -1 || index === 0) {
                const answer = this.userSaves[0].get('userActivity').entitySave.answers[index];
                this.dialog.open(VideoMarkersComponent, {
                    data: {
                        lesson: this.lessonsService.currentLesson.get('metadatas').title,
                        markerType: selectedMarker.marker_type ? selectedMarker.marker_type : {label: 'activities.personalize'},
                        marker: selectedMarker,
                        isEditor: false,
                        answer: answer,
                        step: 1,
                        videoConfig: this.videoConfig,
                        callback: (marker, data) => this.summaryCallback(marker, data),
                    }
                });
            }
        }
    }


    public exit(): void {
        this.router.navigate(['lessons', 'list']);
    }

    public getIcon(marker): string {
        const label = marker && marker.marker_type ? marker.marker_type.label : '';
        switch (label) {
            case 'Cadrage':
                return 'cadrage';
            case 'Contexte d\'usage':
                return 'contexte';
            case 'Illusion d\'optique':
            case 'Illusion optique':
                return 'illusion_optique';
            case 'Légende':
                return 'legende';
            case 'Ligne éditoriale':
                return 'ligne_editoriale';
            case 'Retouche':
                return 'retouche';
            case 'Source image':
                return 'source_image';
            case 'Source texte':
                return 'source_texte';
            default:
                return 'personnalisable';
        }
    }

    /**
     * Get type of marker
     * @param marker
     *
     * @return marker type by default except personalized marker, in this case return marker title
     */
    public getTitleMarkerType(marker): string {
        if (marker && marker.marker_type) {
            return marker.marker_type.localized;
        }

        return marker.title;
    }

    public saveWarnings(): void {
        if (this.activity) {
            this.showSpinner = true;
            this.activitiesService.editMetadatas({comment: this.warningControl.value.id}, this.activity)
                .pipe(
                    tap(() => this.showSpinner = false)
                )
                .subscribe();
        }
    }

}