import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import { MatTableDataSource } from '@angular/material/table';
import {DataEntity} from 'octopus-connect';
import {ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {fuseAnimations} from 'fuse-core/animations';

@Component({
  selector: 'app-widget-form-models',
  templateUrl: './widget-form-models.component.html',
  styleUrls: ['./widget-form-models.component.scss'],
  animations: fuseAnimations
})
export class WidgetFormModelsComponent implements OnInit {

    @Input('formModelsSubscription') formModelsSubscription: ReplaySubject<any>;

    displayedColumns = ['nameFormModels', 'author'];
    dataSource = new MatTableDataSource();
    resource: boolean;
    templateCollection: {[key: number]: DataEntity} = {};

    constructor(
        private router: Router,
        public authService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.formModelsSubscription.subscribe((data) => {
            if (data) {
                this.dataSource.data = data.slice(0, 3);
            }
            this.resource = data && data.length;
        });

    }

    getLinkFormModels(data): any{
        this.router.navigate(['forms', data.id, 'player']);
    }

    public get empty(): boolean {
        return !this.resource;
    }

    public get align(): string {
        if (!this.empty) {
            return 'start ';
        }
        return 'center ';
    }

}