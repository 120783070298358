import {Component, Inject, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import {SnackBarDataInjectedInterface} from 'fuse-core/news/snackbar/snack-bar-data-injected.interface';

@Component({
    selector: 'app-go-buy-licence-news',
    templateUrl: './go-buy-licence-news.component.html',
    styleUrls: ['./go-buy-licence-news.component.scss']
})
export class GoBuyLicenceNewsComponent {
    public role: string;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private authService: AuthenticationService,
        @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarDataInjectedInterface
    ) {
        this.role = this.authService.accessLevel;
    }

    public dismiss(): void {
        this.snackBar.dismiss()
    }
}