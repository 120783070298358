import {mergeMap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {Observable} from 'rxjs';
import * as _ from 'lodash-es';
import {secondsToHoursMinutesSeconds} from 'shared/utils';
import {VideoInfo} from 'js-video-url-parser/lib/urlParser';
import urlParser from 'js-video-url-parser';
import {CorpusMetadataFilesInterface} from '@modules/corpus/core/corpus-interfaces.interface';

export class CorpusRessource {
    public metadatasEntity: DataEntity;
    public referenceEntity: DataEntity;
    private formatEntity: DataEntity;

    bookmarks: boolean;
    chapters: DataEntity[];
    concepts: any[];
    consulted: any;
    description: string;
    difficulty: Array<any>;
    difficultyString: string;
    duration: string;
    educationalLevel: Array<any>;
    educationalLevelString: string = '';
    format: string;
    groupes: string = '';
    groups = [];
    groupsName: Array<any>;
    id: number | string;
    indexation: Array<any>;
    indexationString: string;
    level: string;
    locked: boolean = false;
    output: any;
    ressourceType: string;
    scolomDate: string;
    skills: Array<any>;
    skillsString: string = '';
    subTheme = '';
    source: string;
    sourceAuthor: string;
    title: string;
    files: CorpusMetadataFilesInterface[];

    constructor(
        public ressourceEntity: DataEntity,
        private corpusService: CorpusService
    ) {
        this.id = ressourceEntity.id;
        this.formatEntity = <DataEntity>ressourceEntity.getEmbed('format');
        this.metadatasEntity = <DataEntity>ressourceEntity.getEmbed('metadatas');
        this.referenceEntity =
            corpusService.getResourceReferenceEntity(ressourceEntity);
        this.getData();
    }

    getData() {
        this.consulted = this.ressourceEntity.get('consulted');
        this.format = this.formatEntity.get('label');
        this.ressourceType = this.getType();

        this.title = this.metadatasEntity.get('title');

        this.files = this.metadatasEntity.get('files');

        this.bookmarks = this.ressourceEntity.get('bookmarks');

        this.locked = this.ressourceEntity.get('locked');

        this.scolomDate = this.metadatasEntity.get('scolomDate');

        /* if (this.metadatasEntity && this.metadatasEntity.get('levels') && this.metadatasEntity.get('levels').length > 0){
                this.level = this.metadatasEntity.get('levels')['0'].label;
            } */

        if (
            this.metadatasEntity &&
            this.metadatasEntity.get('difficulty') &&
            this.metadatasEntity.get('difficulty').length > 0
        ) {
            this.difficulty = this.metadatasEntity.get('difficulty');
            this.difficultyString = this.metadatasEntity
                .get('difficulty')
                .map((x) => x?.label ? x?.label : '')
                .join(', ');
        } else {
            this.difficultyString = '';
        }

        if (
            this.metadatasEntity &&
            this.metadatasEntity.get('indexation') &&
            this.metadatasEntity.get('indexation').length > 0
        ) {
            this.indexation = this.metadatasEntity.get('indexation');
            this.indexationString = this.metadatasEntity
                .get('indexation')
                .map((x) => x?.label ? x?.label : '')
                .join(', ');
        } else {
            this.indexationString = '';
        }

        if (
            this.metadatasEntity &&
            this.metadatasEntity.get('skills') &&
            this.metadatasEntity.get('skills').length > 0
        ) {
            this.skills = this.metadatasEntity.get('skills');
            this.skillsString = this.metadatasEntity
                .get('skills')
                .map((x) => x?.label ? x?.label : '')
                .join(', ');
        } else {
            this.skillsString = '';
        }

        this.description = this.metadatasEntity.get('description');
        this.duration = secondsToHoursMinutesSeconds(
            +this.metadatasEntity.get('learningTime')
        );
        this.sourceAuthor = this.metadatasEntity.get('source-author');
        this.source = this.metadatasEntity.get('source');
        this.groups.push(
            ...this.corpusService.getWorkgroups().filter((globalGroup) => {
                return this.ressourceEntity
                    .get('groups')
                    .some((resourceGroup) => +resourceGroup.id === +globalGroup.id);
            })
        );

        if (this.ressourceEntity.get('workgroups')) {
            this.groupes = this.ressourceEntity
                .get('workgroups')
                .filter((workgroup) => workgroup.archived === false)
                .map((x) => x?.label ? x?.label : '')
                .join(', ');
        }

        if (
            this.metadatasEntity &&
            this.metadatasEntity.get('levels') &&
            this.metadatasEntity.get('levels').length > 0
        ) {
            this.educationalLevel = this.metadatasEntity.get('levels');
            this.educationalLevelString = this.metadatasEntity
                .get('levels')
                .map((x) => x?.label ? x?.label : '')
                .join(', ');
        } else {
            this.educationalLevelString = '';
        }

        this.chapters = this.metadatasEntity.get('chapters');
        this.concepts = this.metadatasEntity.get('concepts');
    }

    getTypeByMime(mime: string): string {
        return;
    }

    getMimeIcon(mime: string): string {
        switch (mime) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/gif':
                return 'photo';
            case 'video/mp4':
            case 'video/mpeg':
                return 'video';
            case 'audio/mp3':
            case 'audio/mpeg':
                return 'music';
            case 'application/pdf':
                return 'pdf';
            default:
                return 'book';
        }
    }

    get videoEmbedUrl(): string {
        return this.getVideoLinkInfo('embed');
    }

    get videoLongUrl(): string {
        return this.getVideoLinkInfo('long');
    }

    get videoShortImage(): string {
        if (this.getType() === 'video' || this.getType() === 'videoUrl') {
            const url = this.getVideoLinkInfo('long');

            if (url && url.includes('vimeo')) {
                this.output = 'https://vimeo.com/api/oembed.json?url=' + url;
            } else {
                this.output = this.getVideoLinkInfo('shortImage');
            }
        } else {
            this.output = 'noThumb';
        }
        return this.output;
    }

    get ownerName(): string {
        return this.ressourceEntity.get('owner-name');
    }

    get changedDate(): string {
        return this.metadatasEntity.get('changed');
    }

    getVideoLinkInfo(type: string): string {
        if (this.ressourceType === 'video') {
            const url: string =
                this.referenceEntity.get('url') || this.referenceEntity.get('uri');
            const parsed: VideoInfo | undefined = urlParser.parse(url);

            if (parsed) {
                return urlParser.create({
                    videoInfo: parsed,
                    format: type,
                });
            } else if (
                this.corpusService.settings.urlVideoException.some((urlException) =>
                    url.includes(urlException)
                )
            ) {
                return url;
            } else {
                return '';
            }
        }
    }

    getType(): string {
        switch (this.format) {
            case 'text':
                return this.format;

            case 'activity':
                return this.format;

            case 'lesson':
                return this.format;

            case 'sequence':
                return this.format;

            case 'url':
            case 'videoUrl':
                const url: string = this.referenceEntity.get('url') || '';
                const parsed: Object = urlParser.parse(url);

                return parsed ||
                this.corpusService.settings.urlVideoException.some((urlException) =>
                    url.includes(urlException)
                )
                    ? 'video'
                    : 'link';

            case 'divider':
                return this.format;
            case 'media':
            case 'audio':
            case 'video':
            case 'document':
            case 'image':
                const mime: string = this.referenceEntity?.get('filemime');
                if(mime) {
                    return this.getMimeIcon(mime);
                }
        }
    }

    setData(forCopy = false): Observable<DataEntity> {
        this.metadatasEntity.set('title', this.title);
        this.metadatasEntity.set('description', this.description);
        if (this.getType() !== 'divider') {
            this.metadatasEntity.set('source-author', this.sourceAuthor);
            this.metadatasEntity.set('source', this.source);
            this.ressourceEntity.set('groups', this.getIdFromGroup());
            this.metadatasEntity.set('skills', this.skills);
            this.metadatasEntity.set('difficulty', this.difficulty);
            this.metadatasEntity.set('educationalLevel', this.educationalLevel);
            this.metadatasEntity.set('scolomDate', this.scolomDate);
            this.metadatasEntity.set(
                'chapters',
                this.chapters.map((chapter) => chapter.id)
            );

            this.metadatasEntity.set('concepts', [this.concepts[0]]);
            this.metadatasEntity.set(
                'files',
                this.files && this.files[0].id ? this.files[0].id : null
            ); // set id only for the save

            return this.corpusService.createTags(this.indexation, forCopy).pipe(
                mergeMap((tags: DataEntity[]) => {
                    this.metadatasEntity.set(
                        'indexation',
                        this.indexation.map((tag) => +tag.id)
                    );
                    const obs = this.metadatasEntity.save();
                    this.metadatasEntity.set('indexation', this.indexation);
                    this.metadatasEntity.set('files', this.files);
                    return obs;
                })
            );
        } else {
            return this.metadatasEntity.save();
        }
    }

    getIdFromGroup(): any[] {
        if (this.groups && this.groups.length > 0) {
            const groupsId = this.groups.map((group) => group.id);
            return groupsId;
        }
        return [];
    }

    getNameFromGroup() {
        if (this.groups && this.groups.length > 0) {
            const groupsName = this.groups.map((group) => group.workgroupname);
            return groupsName.join(',');
        }
        return '';
    }

    save(): Observable<DataEntity> {
        const resourceObs = this.setData().pipe(
            mergeMap(() => {
                const entityTemp = _.cloneDeep(this.ressourceEntity);
                entityTemp.type = 'granule';
                return entityTemp.save();
            })
        );
        resourceObs.subscribe((meta) => {
            this.metadatasEntity.set('chapters', this.chapters);
            this.metadatasEntity.set('concepts', meta.get('metadatas').concepts);
            this.corpusService.onFileSelected.next(this);
        });
        return resourceObs as Observable<DataEntity>;
        /* entityTemp.save().subscribe(() => {
                if (this.getType() !== 'divider') {
                    this.groupes = this.getNameFromGroup();
                }
            });*/
        // return this.metadatasEntity.save();
    }

    duplicate(): Observable<CorpusRessource> {
        // duplication des metatdata
        // this.metadatasEntity.

        // duplication de la reference

        // duplication du media potentiel

        // puis pour terminer duplication de la ressource

        return;
    }

    remove(): void {
        this.ressourceEntity.remove();
    }

    reset(): void {
        this.getData();
    }
}
