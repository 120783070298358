import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarRecallButtonComponent } from './snackbar/snackbar-recall-button/snackbar-recall-button.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        SnackbarRecallButtonComponent
    ],
    exports: [
        SnackbarRecallButtonComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule
    ]
})
export class NewsModule { }