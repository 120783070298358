import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResourceshareToGroupModalService } from "./resource-share-to-groups-modal.service";
import { DataEntity } from "octopus-connect";
import { FormControl } from "@angular/forms";
import { Group } from "./resource-share-to-groups-modal.interfaces";

@Component({
  selector: "app-resource-share-to-groups-modal",
  templateUrl: "./resource-share-to-groups-modal.component.html",
  styleUrls: ["./resource-share-to-groups-modal.component.scss"],
  providers: [ResourceshareToGroupModalService],
})
export class ResourceShareToGroupsModalComponent {
  groupList$ = this.service.select(({ groupList }) => groupList);
  selectedGroupList$ = this.service.select(
    ({ selectedGroupList }) => selectedGroupList
  );
  myControl = new FormControl("");

  constructor(
    public service: ResourceshareToGroupModalService,
    @Inject(MAT_DIALOG_DATA) public data: { resource: DataEntity }
  ) {
    this.service.fetchUserGroups();
    this.service.setSelectedGroups(this.data.resource.attributes.groups);
  }

  validate() {
    this.service.validate(this.data.resource);
  }

  displayFn(group: Group): string {
    return group.label;
  }
}