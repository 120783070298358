import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CorpusRessource } from "@modules/corpus/core/corpus-ressource.class";
import { ResourceCreationSteps } from "@modules/corpus/core/resource-creation-steps.class";
import { CorpusService } from "@modules/corpus/core/corpus.service";

@Component({
  selector: "app-resource-edition-modal",
  templateUrl: "./resource-edition-modal.component.html",
  styleUrls: ["./resource-edition-modal.component.scss"],
})
export class ResourceEditionModalComponent implements OnInit {
  resource: CorpusRessource;

  maxStep = this.corpusService.settings.resourceCreationStepsMax;
  currentStep: ResourceCreationSteps = ResourceCreationSteps.STEP2; // On n'edit pas ce qu'il y a en step1

  constructor(
    private corpusService: CorpusService,
    private ref: MatDialogRef<ResourceEditionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object
  ) {}

  goToNextStep(): void {
    if (this.currentStep === this.maxStep) {
      this.close();
    } else {
      const previousStep = this.currentStep;
      this.currentStep = <number>previousStep + 1;

      if (this.corpusService.currentStepIsAllowed(this.currentStep) === false) {
        this.goToNextStep();
        return;
      }
    }
  }

  ngOnInit(): void {
    this.resource = this.data["resource"];
  }

  close(): void {
    this.ref.close();
  }

  onDetailsValidated(metadata: any): void {
    this.goToNextStep();
  }
}