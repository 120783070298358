
import {take, mergeMap, map, filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import { MatDialog } from '@angular/material/dialog';
import {IdeaEditionModalComponent} from './idea-edition-modal/idea-edition-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../authentication';
import {IdeasWallEditionModalComponent} from './ideas-wall-edition-modal/ideas-wall-edition-modal.component';
import {ReplaySubject} from 'rxjs';
import {modulesSettings} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';

const settingsStructure: ModelSchema = new ModelSchema({
    gettingStarted: Structures.string(''),
    isRemoteAllowed: Structures.boolean(true),
    isIdeaWallOnlyForUCurrentUser: Structures.boolean(false),
    displayCreateWallHelper: Structures.boolean(false),
    displayHeader: Structures.boolean(true),
    displayMatMenuIcon: Structures.boolean(true),
    ideasWallDialogFields: Structures.object({
        default: ['name', 'description']
    }),
    lockedOptionEnabled: Structures.boolean(true)
});

@Injectable({
    providedIn: 'root'
})
export class IdeasWallService {

    currentWallSubscription: Subscription;
    currentWall: DataEntity;

    availableWallsSubscription: Subscription;
    availableWalls: DataEntity[];

    currentDisplayMode = 'mixed';

    currentIdeasFilterMode = 'collective';
    currentTeacherIdeasFilterMode = 'collective';
    currentCategoriesFilterMode = 'collective';
    currentTeacherCategoriesFilterMode = 'collective';

    currentTeacherMode = 'collective';

    ideasByColumn: { [key: number]: DataEntity[] };
    completeIdeasList: DataEntity[];
    completeCategoriesList: DataEntity[];

    ideasInterval = 10000;

    currentUser: DataEntity;

    projects: any[];

    lessons: { id: number, label: string }[] = [];
    steps: { id: any, label: string }[] = [];

    remoteControllerDisplay = false;

    parentId: number;
    locked = false;
    public lockedIdeas = false;
    public lockedCategories = false;
    public lockToggle = new ReplaySubject<any>(1);
    workGroups: any[] = [];

    public settings: { [key: string]: any };

    constructor(
        private octopusConnect: OctopusConnectService,
        private dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                    this.postAuthentication();
                }
            });

        const userObs: Observable<DataEntity> = this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData');

        const wgObs: Observable<any[]> = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');

        combineLatest(userObs, wgObs).subscribe((resp: [DataEntity, any[]]) => {
            this.currentUser = resp[0];
            this.workGroups = resp[1];
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(routeScope => {
                while (routeScope.firstChild) {
                    routeScope = routeScope.firstChild;
                }
                return routeScope;
            }),
            filter(routeScope => routeScope.outlet === 'primary'),
            mergeMap(routeScope => {
                if (routeScope.params) {
                    return routeScope.params;
                } else {
                    return null;
                }
            }),)
            .subscribe((params: Params) => {
                this.parentId = params['projectId'];
            });

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject<any[]>('projectsList').subscribe(projects => {
            this.projects = projects;
        });

        this.settings = settingsStructure.filterModel(modulesSettings.ideasWall);
    }

    /**
     * get all the lesson title and id for list after user authentification
     */
    private postAuthentication(): void {
        this.communicationCenter
            .getRoom('activities')
            .getSubject<DataEntity[]>('lessonsList').subscribe(lessons => {
            this.lessons = lessons.map(lesson => {
                return {id: +lesson.id, label: lesson.get('metadatas').title};
            });
        });
    }

    /**
     * Ideas walls entities loading
     * @param {number} parentId - Parent: project or other
     * @returns {Observable<DataEntity[]>}
     */
    loadAndStoreIdeasWalls(): Observable<DataEntity[]> {
        const params: Object = {};

        if (this.parentId) {
            params['parent'] = String(this.parentId);

        }
        // if settings is true we can only see wall we have created
        if (this.settings.isIdeaWallOnlyForUCurrentUser) {
            params['uid'] = this.currentUser.id;
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('ideas-wall', params).pipe(map(collection => collection.entities));

        if (this.availableWallsSubscription) {
            this.availableWallsSubscription.unsubscribe();
            this.availableWallsSubscription = null;
        }

        this.availableWallsSubscription = obs.subscribe(entities => this.availableWalls = entities);

        return obs;
    }

    /**
     * Gets wall data and set them as current
     * @param {number} wallId
     * @returns {Observable<DataEntity>}
     */
    getWallAndSetItAsCurrent(wallId: number): Observable<DataEntity> {
        let obs: Observable<DataEntity>;
        let stored: DataEntity;

        if (this.availableWalls) {
            stored = this.availableWalls.find(entity => entity.id === wallId);
        }

        this.octopusConnect.createEntity('join-wall', {id: this.currentUser.id, myType: 'join-wall', room: wallId}).pipe(take(1));

        obs = this.octopusConnect.loadCollection('ideas-wall', {
            wid: String(wallId)
        }).pipe(map(coll => coll.entities[0]));

        if (this.currentWallSubscription) {
            this.currentWallSubscription.unsubscribe();
            this.currentWallSubscription = null;
        }

        this.currentWallSubscription = obs.subscribe(entity => {
            this.currentWall = entity;

            this.locked = entity.get('locked') || false;
            this.lockedIdeas = entity.get('locked_item');
            this.lockedCategories = entity.get('locked_category');

            const parentId: number = entity.get('parent');
            const project = this.projects.find(mproject => +mproject.id === +parentId);
        });

        return obs;
    }


    get isLocked(): boolean {
        if (this.isTeacher) {
            return false;
        } else {
            return this.locked;
        }
    }

    /**
     * Creates a new ideas walls entity
     * @returns {Observable<DataEntity>}
     */
    public createIdeasWall(): void {
        if (this.fieldsAllowedIdeaWallModal().indexOf('steps') > -1) {
            this.loadStepList().pipe(take(1)).subscribe(jobDone => {
                this.loadIdeasWallCreateModal();
            });
        } else {
            this.loadIdeasWallCreateModal();
        }
    }

    /**
     * load all step for the list of step is use for loading steps before launching modal
     * containing the list in create and edit mode
     */
    public loadStepList(): Observable<any> {
        return this.loadSteps().pipe(map(steps => {
            this.steps = steps;
        }));
    }

    /**
     * open the create ideasWall Modal
     */
    public loadIdeasWallCreateModal(): void {
        this.dialog.open(IdeasWallEditionModalComponent,
            {
                data: {
                    name: '',
                    text: '',
                    phase: 'creation',
                    lessonsTitles: this.lessons || null,
                    sessionSteps: this.steps || null,
                    fieldsAllowed: this.fieldsAllowedIdeaWallModal(),

                }
            }).beforeClosed().subscribe((resp: Object) => {
            if (resp !== undefined) {
                const params: Object = {
                    name: resp['name'] || '',
                    description: resp['text'] || '',
                    // @ts-ignore
                    attached_nodes: [resp['idLessonTitle']] || null,
                    idea_wall_category: resp['idSessionStep'] || null
                };

                if (this.parentId) {
                    params['parent'] = +this.parentId;
                }

                this.octopusConnect.createEntity('ideas-wall', params);
            }
        });
    }

    /**
     * check the fields allowed in the edit create modal of ideas wall
     */
    public fieldsAllowedIdeaWallModal(): string[] {
        const role = this.authService.accessLevel;
        let fields = this.settings.ideasWallDialogFields[role];
        if (fields === undefined) {
            fields = this.settings.ideasWallDialogFields['default'];
        }
        return fields;
    }

    /**
     * Loads ideas entities
     * @returns {Observable<DataEntity[]>}
     */
    loadIdeas(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('wall-idea', {
            wall: this.currentWall.id
        }).pipe(map(collection => collection.entities));
    }


    /**
     *
     * @param {number} columnNumber
     * @returns {number}
     */
    getCountByColumnNumber(columnNumber: number): number {
        if (!this.ideasByColumn || !this.ideasByColumn[columnNumber]) {
            return 0;
        }

        return this.ideasByColumn[columnNumber].length;
    }


    /**
     * Creates a new empty idea
     * @returns {Observable<DataEntity>}
     */
    createIdea(): void {
        this.dialog.open(IdeaEditionModalComponent, {
            data: {
                type: 'idea',
                phase: 'creation'
            },
            autoFocus: false
        }).beforeClosed().subscribe((text: string) => {
            if (text !== '' && text !== undefined) {
                return this.octopusConnect.createEntity('wall-idea', {
                    wall: this.currentWall.id,
                    column: 0,
                    position: this.maxPosition(this.completeIdeasList) + this.ideasInterval,
                    category: [],
                    text: text
                });
            }
        });
    }


    /**
     *
     * @returns {number}
     */
    maxPosition(list: DataEntity[]): number {
        let max = 0;

        list.forEach(entity => {
            max = Math.max(max, entity.get('position'));
        });

        return max;
    }


    /**
     *
     * @param {DataEntity} currentEntity
     * @param {DataEntity[]} list - Reference list
     * @returns {number}
     */
    getClosestPreviousPosition(currentEntity: DataEntity, list: DataEntity[]): number {
        const currentPosition: number = currentEntity.get('position');
        let max = 0;

        list.forEach(entity => {
            const entityPosition: number = entity.get('position');

            if (entityPosition < currentPosition) {
                max = Math.max(max, entityPosition);
            }
        });

        return max;
    }


    /**
     *
     * @param {DataEntity} currentEntity
     * @param {DataEntity[]} list - Reference list
     * @returns {number}
     */
    getClosestNextPosition(currentEntity: DataEntity, list: DataEntity[]): number {
        const currentPosition: number = currentEntity.get('position');
        let min: number = Number.MAX_VALUE;

        list.forEach(entity => {
            const entityPosition: number = entity.get('position');

            if (entityPosition > currentPosition) {
                min = Math.min(min, entityPosition);
            }
        });

        return min;
    }


    /**
     *
     * @param {DataEntity} currentEntity
     * @param {DataEntity} referenceEntity
     * @param {DataEntity[]} list - Reference list
     * @returns {number}
     */
    getPreviousMeanPosition(currentEntity: DataEntity, referenceEntity: DataEntity, list: DataEntity[]): number {
        const currentPosition: number = currentEntity.get('position');
        const previousPosition: number = this.getClosestPreviousPosition(currentEntity, list);

        const referencePosition: number = referenceEntity.get('position');

        if (referencePosition === previousPosition) {
            return referencePosition;
        }

        return (currentPosition + previousPosition) / 2;
    }


    /**
     *
     * @param {DataEntity} currentEntity
     * @param {DataEntity} referenceEntity
     * @param {DataEntity[]} list - Reference list
     * @returns {number}
     */
    getNextMeanPosition(currentEntity: DataEntity, referenceEntity: DataEntity, list: DataEntity[]): number {
        const currentPosition: number = currentEntity.get('position');
        const nextPosition: number = this.getClosestNextPosition(currentEntity, list);

        const referencePosition: number = referenceEntity.get('position');

        if (referencePosition === nextPosition) {
            return referencePosition;
        }

        if (nextPosition === Number.MAX_VALUE) {
            return currentPosition + this.ideasInterval;
        }

        return (currentPosition + nextPosition) / 2;
    }


    /**
     * Load categories entities
     * @returns {Observable<DataEntity[]>}
     */
    loadCategories(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('wall-category', {
            wall: this.currentWall.id
        }).pipe(map(collection => collection.entities));
    }

    /**
     * Load steps form back store in ideas_wall_category
     * @returns { id: number, label: string }[]
     */
    public loadSteps(): Observable<{ id: any, label: string }[]> {
        return this.octopusConnect.loadCollection('ideas_wall_category').pipe(
            map(collection => {
                return collection.entities.map(step => {
                    return {id: step.id, label: step.attributes.label};
                });
            }));
    }

    /**
     * Creates a new empty category
     * @returns {Observable<DataEntity>}
     */
    createCategory(): void {
        this.dialog.open(IdeaEditionModalComponent, {
            data: {
                type: 'category',
                phase: 'creation'
            }
        }).beforeClosed().subscribe((text: string) => {
            if (text !== '' && text !== undefined) {
                this.octopusConnect.createEntity('wall-category', {
                    wall: this.currentWall.id,
                    position: this.maxPosition(this.completeCategoriesList) + this.ideasInterval,
                    name: text
                });
            }
        });

    }


    /**
     * isTeacher getter
     * @returns {boolean}
     */
    get isTeacher(): boolean {
        return this.authService.isAdministrator() || this.authService.isTrainer();
    }


    /**
     * isLearner getter
     * @returns {boolean}
     */
    get isLearner(): boolean {
        return this.authService.isLearner();
    }


    get notArchivedGroups(): any[] {
        return this.workGroups.filter((group) => {
            return !group.archived;
        });
    }

    /**
     * Returns true if entity owner (idea or category) is in my group
     * @param {DataEntity} entity - Entity to check
     * @returns {boolean}
     */
    isInMyGroup(entity: DataEntity): boolean {
        const entityGroups: number[] = entity.get('userGroups') || [];
        let userGroups: number[] = this.currentUser.get('groups') || [];

        userGroups = userGroups.filter(groupId => {
            for (const group of this.workGroups) {
                if (+group.id === +groupId) {
                    return true;
                }
            }

            return false;
        });

        for (const entityGroup of entityGroups) {
            for (const userGroup of userGroups) {
                if (+entityGroup === +userGroup) {
                    return true;
                }
            }
        }

        return false;
    }


    /**
     * Merge two categories into one
     * @param {DataEntity} from
     * @param {DataEntity} to
     */
    mergeCategories(from: DataEntity, to: DataEntity): void {
        this.completeIdeasList.forEach(idea => {
            const ideaCategories: number[] = (idea.get('category') || []).map(g => +g);

            if (ideaCategories.indexOf(+from.id) !== -1) {

                // si une idée peut être dans plusieurs catégories, la ligne suivante est à revoir
                idea.set('category', [to.id]);

                idea.save(true);
            }
        });

        from.remove();
    }
}