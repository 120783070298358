import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorReportingModalComponent } from './error-reporting-modal/error-reporting-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import { ErrorReportingTriggerButtonComponent } from './error-reporting-trigger-button/error-reporting-trigger-button.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatCardModule,
        MatTooltipModule
    ],
    declarations: [
        ErrorReportingModalComponent,
        ErrorReportingTriggerButtonComponent]
    ,
    exports: [
        ErrorReportingTriggerButtonComponent,
    ],
})
export class ErrorReportingModule { }