import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {DataSource} from '@angular/cdk/collections';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {FuseGroupsFormDialogData} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../models/rules';
import {Institution} from '@modules/groups-management/core/definitions';
import {filterType, GroupManagementConfigurationService, roleList} from '@modules/groups-management/core/services/group-management-configuration.service';
import {GroupService} from '@modules/groups-management/core/services/group.service';
import {ChooseGroupComponent} from '@modules/groups-management/core/choose-group/choose-group.component';
import {GroupsListingOptions} from '@modules/groups-management/core/models/groups-listing-options';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit, OnDestroy {
    static readonly componentIdentifier = 'GroupComponent';
    public levels: DataEntity[] = [];
    public displayedColumns = ['color', 'groupname', 'level', 'code'];
    public displayedFilters: string[] = []; // filter to show
    public dataSource: GroupDataSource | null;
    public archiveGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_archive_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.archiveGroupCallback(group),
        callbackWithMultiple: (list) => this.archiveListCallback(list),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.ArchiveGroup, undefined, group)
    };
    public deArchiveGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_dearchive_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deArchiveGroupCallback(group),
        callbackWithMultiple: (list) => this.dearchiveListCallback(list),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.ArchiveGroup, undefined, group)
    };
    public activateMetacognitionGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.metacognition',
            bodyDialog: 'groups-management.sure_activate_metacognition_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.activateMetacognitionGroupCallback(group),
        callbackWithMultiple: (list) => this.activateMetacognitionListCallback(list),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.ActiveMetacognitionOnGroup, undefined, group),
    };
    public deactivateMetacognitionGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'generic.metacognition',
            bodyDialog: 'groups-management.sure_deactivate_metacognition_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deactivateMetacognitionGroupCallback(group),
        callbackWithMultiple: (list) => this.deactivateMetacognitionListCallback(list),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.ActiveMetacognitionOnGroup, undefined, group),
    };
    public deleteGroup: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deleteGroupCallback(group),
        callbackWithMultiple: (list) => this.deleteListCallback(list),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.DeleteGroup, undefined, group)
    };
    private groupColors = this.groupsManagementService.settings.primaryColors;
    public newGroup: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'add_class',
            fields: this.groupManagementConfigurationService.newGroupField(),
            colors: this.groupColors.slice(),
            selects: {
                class: () => this.groupService.getGroups(),
                schoolYears: () => this.groupsManagementService.getSchoolYearEntities(),
                levels: () => this.groupManagementConfigurationService.mustUseAnArray() ? this.groupService.getLevels() : this.levels, //ctz-setting
                educationalLevels: () => this.groupService.getEducationalLevels()
            }
        },
        alternatives: [
            {
                condition: () => this.authService.isGAR(),
                component: ChooseGroupComponent,
            }
        ],
        callback: (response) => this.newGroupCallback(response),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.CreateGroup, undefined, group),
    };
    public editGroup: FuseGroupsFormDialogData = {
        data: {
            action: 'edit',
            title: 'edit_class',
            fields: this.groupManagementConfigurationService.editGroupField(),
            colors: this.groupColors.slice(),
            selects: {
                class: () => this.groupService.getGroups(),
                schoolYears: () => this.groupsManagementService.getSchoolYearEntities(),
                levels: () => this.groupManagementConfigurationService.mustUseAnArray() ? this.groupService.getLevels() : this.levels,//ctz-setting
                educationalLevels: () => this.groupService.getEducationalLevels()
            }
        },
        callback: (response) => this.editGroupCallback(response),
        isAuthorized: (group) => this.authorizationService.currentUserCan(SyncRules.EditGroup, undefined, group),
    };
    public options: GroupsListingOptions = {
        expandRow: () => this.authorizationService.currentUserCan(SyncRules.SeeLearnersInGroup),
    };
    private noParentObject: Institution;

    constructor(
        private groupService: GroupService,
        private translate: TranslateService,
        private groupsManagementService: GroupsManagementService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private groupManagementConfigurationService: GroupManagementConfigurationService
    ) {
        try {
            this.displayedFilters = this.groupManagementConfigurationService.displayFilterByOriginAndRole(filterType.groups, roleList[this.authService.accessLevel]);
        } catch (ex) {
            console.error('erreur getting settings group component 126 ' + ex);
        }

        this.displayedColumns = this.groupsManagementService.settings.group.columns[this.authService.accessLevel] ?
            this.groupsManagementService.settings.group.columns[this.authService.accessLevel] : this.groupsManagementService.settings.group.columns['default'];
        if (this.groupsManagementService.settingsAccess.institutionsGroupAccess) {
            if (!this.displayedColumns.includes('institutions')) {
                this.addInstitutionsColumn();
            }

            if (this.displayedColumns.includes('schoolyear')) {
                this.addSchoolYear();
            }

            this.newGroup.data.fields.push('parent');
            this.editGroup.data.fields.push('parent');

            this.translate.get('groups-management.no_institution').subscribe((res) => {
                this.noParentObject = {field: res, hideCode: res, code: res, name: res, id: null};
            });

            this.newGroup.data.selects.parent = () => {
                return this.getGroupParents();
            };
            this.editGroup.data.selects.parent = () => {
                return this.getGroupParents();
            };
        }
    }

    ngOnInit(): any {
        this.dataSource = new GroupDataSource(this.groupService);
        this.groupsManagementService.setHeaderTitle('groups-management.classes');
        this.groupsManagementService.gettingStarted = this.groupsManagementService.settings.gettingStarted.groups;
        //ctz-code not to launch
        if (this.groupManagementConfigurationService.mustUseAnArray() === false) {
            let obs = <Observable<DataEntity[]>>this.groupService.getLevels();
            obs.subscribe((levels: DataEntity[]) => {
                this.levels = levels;
            });
        }
    }

    ngOnDestroy(): void {
        this.groupsManagementService.gettingStarted = '';
    }

    public newGroupCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.groupService.addGroup(response.getRawValue());
        }
    }

    public editGroupCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.groupService.saveGroup(response.getRawValue());
        }
    }

    public archiveGroupCallback(group: any): Observable<DataEntity> {
        group.archived = true;
        return this.groupService.saveGroup(group);
    }

    public deArchiveGroupCallback(group: any): Observable<DataEntity> {
        group.archived = false;
        return this.groupService.saveGroup(group);
    }

    public activateMetacognitionGroupCallback(group: any): Observable<DataEntity> {
        group.metacognitionActive = true;
        return this.groupService.saveGroup(group);
    }

    public deactivateMetacognitionGroupCallback(group: any): Observable<DataEntity> {
        group.metacognitionActive = false;
        return this.groupService.saveGroup(group);
    }

    public deleteGroupCallback(group: any): Observable<boolean> {
        return this.groupService.deleteGroup(group);
    }

    public archiveListCallback(groups: any): void {
        for (const group of groups) {
            group.archived = true;
            this.groupService.saveGroup(group);
        }
    }

    public dearchiveListCallback(groups: any): void {
        for (const group of groups) {
            group.archived = false;
            this.groupService.saveGroup(group);
        }
    }

    public activateMetacognitionListCallback(groups: any): void {
        for (const group of groups) {
            group.metacognitionActive = true;
            this.groupService.saveGroup(group);
        }
    }

    public deactivateMetacognitionListCallback(groups: any): void {
        for (const group of groups) {
            group.metacognitionActive = false;
            this.groupService.saveGroup(group);
        }
    }

    public deleteListCallback(groups: any): void {
        for (const group of groups) {
            this.groupService.deleteGroup(group);
        }
    }

    private addInstitutionsColumn(): void {
        if (this.displayedColumns.includes('buttons')) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('buttons'), 0, 'institutions');
        } else {
            this.displayedColumns.push('institutions');
        }
    }

    private addSchoolYear(): void {
        this.newGroup.data.fields.push('schoolyear_term');
        this.editGroup.data.fields.push('schoolyear_term');
    }

    private getGroupParents(): { id: string, name: string; field: string; hideCode: boolean; code: string }[] {
        const parents = this.groupService.getParents();
        if (!parents.find(parent => parent['id'] === this.noParentObject.id)) {
            parents.unshift(this.noParentObject);
        }
        return parents;
    }
}

export class GroupDataSource extends DataSource<any> {
    data: BehaviorSubject<void>;

    constructor(public groupsService: GroupService) {
        super();
        this.data = new BehaviorSubject<any>(this.groupsService.groups);

        this.groupsService.onGroupsChanged.subscribe((data) => {
            this.data.next(data);
        });
    }

    get groupService(): any {
        return this.groupsService;
    }

    connect(): Observable<any[]> {
        return this.groupService.onGroupsChanged;
    }

    disconnect(): any {
    }
}
