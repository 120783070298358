import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService, OrderDirection, PaginatedCollection} from 'octopus-connect';
import {filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {CommunicationCenterService} from '@modules/communication-center';

/**
 * Used to visualize editable mindmap data.
 * Sometimes it's only used for `text` fields, sometimes only the others
 */
export interface IMindmapFormData {
    title?: string;
    associatedLessonIds?: (string | number)[];
    associatedLessonId?: string | number;
}

const granuleEndpoint = 'granule';
const mindmapEndpoint = 'mindmaps';
const searchEndpoint = 'basic_search';

const mindmapTypologyLabel = 'mindmap';

@Injectable({
    providedIn: 'root'
})
/**
 * Define the exchange between front & backend in matter of mindmap
 */
export class MindmapRepositoryService {

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService
    ) {
    }

    /**
     * Delete a mindmap defined by the granule id. The metadatas, activity & activity_content are deleted on cascade
     * @param id
     */
    public destroyMindmap(id: number | string): Observable<boolean> {
        // Hack: no need to reload the entity, we can mock it :)
        const entity = new DataEntity(granuleEndpoint, {}, this.octopusConnect, id);
        return entity.remove();
    }

    /**
     * Return the mindmap {@link DataEntity} as a granule (contains granule, medatada, activity & activityContent)
     * @param id
     */
    public getMindmap(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(granuleEndpoint, id);
    }

    /**
     * Path a mindmap entity and return it
     *
     * @param mindmapId the id of the mindmap
     * @param data the data to path, each data are not required, only the given key will be patched.
     * Some data are in metadata of granule, other in activity_content. This method know what to do.
     * @param returnUpdatedGranule If true or by default, returns the patched mindmap granule (but a request is made),
     * otherwise returns the granule in the same state as before patching (and no request are done)
     */
    public updateMindmap(mindmapId: string | number, data: IMindmapFormData, returnUpdatedGranule = true): Observable<DataEntity> {
        const updateActivityContent = (mindmapGranule: DataEntity) => {
            return this.getMindmapActivityContent(mindmapGranule.get('reference').activity_content[0].id).pipe(
                mergeMap(mindmapActivityContent => {
                        if (data.hasOwnProperty('associatedLessonIds')) {
                            const lessonIds = data.associatedLessonIds.map(id => +id);
                            const distinctLessonIds = _.uniq(lessonIds);
                            mindmapActivityContent.set('associated_nodes', distinctLessonIds);
                        }
                        return mindmapActivityContent.save();
                    }
                ),
                take(1)
            );
        };

        const updateMetadata = (mindmapGranule: DataEntity) => {
            const metadata = <DataEntity>mindmapGranule.getEmbed('metadatas');
            metadata.set('title', data.title);
            return metadata.save();
        };

        return this.getMindmap(mindmapId).pipe(
            mergeMap(mindmapGranule => {
                const obs: Observable<DataEntity | any>[] = [of([])];
                if (data.hasOwnProperty('text') || data.hasOwnProperty('associatedLessonIds')) {
                    obs.push(updateActivityContent(mindmapGranule));
                }
                if (data.hasOwnProperty('title')) {
                    obs.push(updateMetadata(mindmapGranule));
                }

                return combineLatest(obs).pipe(
                    mergeMap(() => returnUpdatedGranule ? this.getMindmap(mindmapId) : of(mindmapGranule))
                );
            }),
        );
    }

    /**
     * Obtains the paginated list of notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `mindmaps` but `BasicSearch` endpoint
     */
    public getPaginatedMindmaps(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.getMindmapActivityTypologyId().pipe(
            map(typologyId => {
                filterOptions = _.merge({
                    filter: {
                        typology: typologyId,
                    },
                    orderOptions: [
                        {field: 'created', direction: OrderDirection.DESC}
                    ]
                }, filterOptions);
                return this.octopusConnect.paginatedLoadCollection(searchEndpoint, filterOptions);
            }),
            take(1)
        );
    }

    /**
     * Create and obtain a new mindmap activity
     *
     * - The activity is create by the generic activity creation of activity module.
     * - This way to create an activity don't set an activity content, that's why an activity_content are created and associated here.
     * - This way to create an activity don't allow to set default values, that's why the empty activity are updated here just after creation.
     *
     * @param mindmapData
     */
    public createMindmap(mindmapData: IMindmapFormData): Observable<DataEntity> {
        const onActivityCreated = new ReplaySubject<Observable<DataEntity>[]>(1);

        this.octopusConnect.createEntity(mindmapEndpoint, {config: ''})
            .pipe(
                tap(activityContent => {
                    this.communicationCenter
                        .getRoom('activities')
                        .next('createActivities', {
                            types: [{label: mindmapTypologyLabel, activity_content: activityContent.id, metadata: {title: mindmapData.title}}],
                            callbackSubject: onActivityCreated
                        });
                })
            )
            .subscribe();

        return onActivityCreated.pipe(
            // We can do that because here there is only one mindmap to create
            mergeMap(obs => obs[0]),
            mergeMap(mindmapGranule => this.updateMindmap(mindmapGranule.id, mindmapData)),
            take(1)
        );
    }

    /**
     * Obtains the `activity_content` from `mindmaps` endpoint
     *
     * @param id
     */
    public getMindmapActivityContent(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(mindmapEndpoint, id);
    }

    /**
     * Obtain the id of the only one activity typology with `mindmap` as label
     */
    private getMindmapActivityTypologyId(): Observable<string | number> {
        return this.octopusConnect.loadCollection('activity-types')
            .pipe(
                filter(collection => collection.entities.length > 0),
                map(collection => collection.entities),
                map(activityTypes =>
                    activityTypes.filter(activityType =>
                        activityType.get('label') === mindmapTypologyLabel
                    ).map(activityType => activityType.id)[0]
                ),
                take(1)
            );
    }
}
