import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AssignationModalDataInterface} from 'fuse-core/components/card/assign-modal/assignation-modal-data.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {forEach} from 'lodash-es';
export interface Group {
    id: string | number;
    label: string;
}

export interface Learner {
    id: string | number;
    label: string;
    groups: any[];
}

interface State {
    learnerList: Learner[];
    groupList: Group[];
    selectedGroupList: Group[];
}

@Component({
    selector: 'app-assign-modal',
    templateUrl: './assign-modal.component.html',
    styleUrls: ['./assign-modal.component.scss']
})
export class AssignModalComponent implements OnInit{
    state$ = new BehaviorSubject<State>({
        learnerList: [],
        groupList: [],
        selectedGroupList: [],
    });

    public isUserEditAssignment = false;
    public title = '';
    public comment: string;
    public numberOfViewers: number;

    public askNumberOfViewers: boolean;
    public askTitle: boolean;
    public askComment: boolean;

    public header = 'cards.assignment_modal_title';
    public validLabel = 'activities.lesson.dialog_confirm';
    public body = '';
    public commentErrorLengthMessage = '';
    public commentMaxLength?: number;

    public groupList$ = this.select(({ groupList }) => groupList);
    public selectedGroupList$ = this.select(
        ({ selectedGroupList }) => selectedGroupList
    );

    public placeholder = '';

    /**
     * Constructor
     */
    constructor(
        private domSanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<AssignModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AssignationModalDataInterface) {
        this.isUserEditAssignment = !!this.data?.assignment;
        if (!!this.data.askTitle) {
            this.askTitle = this.data.askTitle;
        }
        if (!!this.data?.assignment?.get('title').length) {
            this.title = this.data.assignment.get('title');
        }
        if (!!this.data.askComment) {
            this.askComment = data.askComment;
            this.comment = data.commentDefault;
        }

        if (!!this.data.commentMaxLength) {
            this.commentMaxLength = data.commentMaxLength;
        }
        if (this.title === '' && !!this.data.lesson) {
            const lesson = this.data.resourceInDataCard?.id === this.data.lesson?.id ? this.data.lesson : this.data.resourceInDataCard;
            const sequenceTitle = lesson.get('metadatas').title || '';
            const chapterTitle = lesson.get('metadatas').chapters[0]?.label || '';
            if (chapterTitle && chapterTitle !== '') {
                this.title = chapterTitle;
            }
            if (sequenceTitle && sequenceTitle !== '') {
                if (!this.title || this.title === '') {
                    this.title = sequenceTitle;
                } else {
                    this.title += ' | ' +  sequenceTitle;
                }
            }
        }

        ['validLabel', 'body', 'header', 'commentErrorLengthMessage']
            .filter(key => !!data[key])
            .forEach(key => this[key] = data[key]);
    }
    ngOnInit() {
        const classes = this.data.classes || [];
        const workgroups = this.data.workgroups || [];
        const learners = this.data.learners || [];
        this.setState({
            groupList: [...classes, ...workgroups].map((group) => ({id: group.id, label: group.groupname || group.workgroupname})),
            selectedGroupList: this.data.assignment ? this.data.assignment.get('groups') : [],
            learnerList: learners.map((learner) => {
                const allGroups = [...learner.groupsWithData.map((group) => group.id), ...learner.workgroupsWithData.map((group) => group.id)];
                return {
                    id: learner.id,
                    label: learner.username,
                    groups: allGroups
                }
            })});
    }

    validate(): void {
        const groups = this.state.selectedGroupList.map((group) => group.id.toString());
        const learners = this.state.learnerList.filter((learner) => learner.groups
            .some((groupId) => groups.includes(groupId.toString())));
        const result = {
            title: this.title,
            numberOfViewers: learners.length,
            comment: this.comment,
            groups: groups
        };

        this.dialogRef.close(result);
    }

    disableValidateBtn(): boolean {
        if (this.isUserEditAssignment) {
            return !this.title || this.title === '';
        }
        return this.state.selectedGroupList.length === 0;
    }

    public cancel(): void {
        this.dialogRef.close(null);
    }

    /**
     * E , . + - is used for scientific notation (10E3) we don't want to use it here
     * and max number 999
     * @param event
     */
    public numberOfViewerOnKeyPress(event: KeyboardEvent) {
        const inputChar = String.fromCharCode(event.charCode);
        if ((this.numberOfViewers && this.numberOfViewers.toString().length > 2) || inputChar.toLowerCase() === 'e' || inputChar.toLowerCase() === ',' || inputChar.toLowerCase() === '.' || inputChar.toLowerCase() === '-' || inputChar.toLowerCase() === '+') {
            event.preventDefault();
        }
    }

    public get state(): State {
        return this.state$.getValue();
    }

    public select<T>(mapFn: (state: State) => T): Observable<T> {
        return this.state$.asObservable().pipe(
            map((state: State) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    private setState(newState: Partial<State>) {
        this.state$.next({
            ...this.state,
            ...newState,
        });
    }


    /**
     * Handles the selection of a group from an autocomplete dropdown list.
     * Adds the selected group to the selectedGroupList state if it is not already in the list.
     * @param group - The MatAutocompleteSelectedEvent object representing the selected group.
     * @returns void
     */
    public selectGroup(group: MatAutocompleteSelectedEvent): void {
        if (this.state.selectedGroupList.length > 0) {
            const isGroupAlreadyAdded = this.state.selectedGroupList.filter(
                (g) => g.id === group.option.value.id
            );

            if (isGroupAlreadyAdded.length > 0) {
                return;
            }
        }

        this.setState({
            selectedGroupList: [...this.state.selectedGroupList, group.option.value],
        });
    }

    public removeGroup(group: Group) {
        this.setState({
            selectedGroupList: this.state.selectedGroupList.filter(
                (g) => g !== group
            ),
        });
    }

    public displayFn(group: Group): string {
        return group.label;
    }
}