import {ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CardsConfigurationService} from 'fuse-core/components/card/cards-configuration.service';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {FlagService} from '../../../app/shared/flag.service';
import {brand} from '../../../app/settings';
import {Router} from '@angular/router';
import {IContentDetails, ITypeOfCard} from 'fuse-core/components/card/models/card.models';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {isArray} from 'lodash-es';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {combineLatest, Observable, of, ReplaySubject, shareReplay} from 'rxjs';
import {filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {MetadataCardInterface} from 'fuse-core/components/card/metadata-card.interface';
import {BreacrumbFields} from 'fuse-core/components/card/lesson-card-breadcrumb/breadcrumb.interface';
import {Usage} from 'fuse-core/components/card/lesson-card-breadcrumb/usage.interface';
import {SettingsCardsDisplayInterface} from 'fuse-core/components/card/settings-cards-display.interface';
import {FileType} from 'fuse-core/components/card/fileType';
import {ButtonListDialogComponent, ButtonListDialogData} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {MatMenu} from '@angular/material/menu';
import {Roles, RolesOrDefault} from 'shared/models/roles';
import {DataService} from 'fuse-core/services/data.service';
import {v4 as uuidv4} from 'uuid';

interface DataCardActionInterface {
    id: string,
    title?: string,
    function: ($event: MouseEvent) => void,
    disabled?: () => Observable<boolean>,
    displayCondition: () => Observable<boolean>,
    matButtonType: 'mat-mini-fab' | 'mat-button' | 'mat-raised-button' | 'mat-menu-item',
    icon?: string,
    classes?: (() => string) | string,
    matMenuTriggerFor?: string,
    /** @deprecated Devrait être géré par le theme (scss, non le html)*/
    fxLayout?: 'row' | 'column',
    /** @deprecated Devrait être géré par le theme (scss, non le html) */
    fxLayoutAlign?: string,
    displayLabel?: string,
}

// Attention, on a theme & themes (semble une erreur, mais les theme ne sont plus supporter)
type DataCardType = 'lesson' | 'model' | 'theme' | 'themes' | 'community'
    | 'video' | 'videoUrl' | 'image' | 'audio' | 'parcours' | 'document'
    | 'url'

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class CardComponent implements OnInit {

    @Input() dataCard: DataCardInterface<DataEntity>;
    @ViewChild('menuPdf', {static: true}) menuPdf: MatMenu;
    @ViewChild('menuBottom', {static: true}) menuBottom: MatMenu;

    public uuid: string = uuidv4();
    private router = inject(Router);
    private authService = inject(AuthenticationService);
    public cardsService = inject(CardsService);
    public cardsConfigurationService = inject(CardsConfigurationService);
    public flagService = inject(FlagService);
    private http = inject(HttpClient);
    private dialog = inject(MatDialog);
    private communicationCenter = inject(CommunicationCenterService);
    private octopusConnect = inject(OctopusConnectService);
    private translate = inject(TranslateService);
    private sharedDataWithOtherModuleService = inject(DataService);

    public headerTitleIcon: string;
    public thumb: string;
    public headerTitle: string;
    private _headerClasses: string[];
    public isCommunityPage: boolean;
    public lessons: string;
    public brand: string = brand;
    public isShareableCommunity: boolean;
    public isShareableModel: boolean;
    public sharedCommunity: boolean;
    public sharedAsModel: boolean;
    public usageLabels: string[];
    public learners = [];
    public classes = [];
    public workgroups = [];
    public isEditable$: Observable<boolean>;
    public settings: {
        cardDisplay: SettingsCardsDisplayInterface,
        cardFields: { [key in RolesOrDefault]: string[] },
        chooseSubLessonFeatureActive: { [key in RolesOrDefault]: boolean },
        displayMenuWithPreviewOption: boolean
        isLaunchLessonAskModalActive: { [key in RolesOrDefault]: boolean },
        isLaunchLessonAskTitle: boolean,
        isLaunchLessonAskComment: boolean,
        launchLessonCommentMaxLength: number,
        playButtonUseIcon: boolean,
        menuLinks: {
            [key in DataCardType]: {
                [key in RolesOrDefault]: string[]
            }
        },
        multiPdfDownload: boolean
    };

    public difficultyDisplaySteps: number[];
    /**
     * Array of menu links to display in the card menu.
     * Each link contains a display condition, an icon, a label, and a function to execute on click.
     * The links should be displayed in the order they are listed in the array.
     */
    public readonly menuLinks = [
        {
            label: 'generic.edit',
            icon: 'edit',
            function: () => this.dataCard.openEditor(this.dataCard.resource.id),
            displayCondition: () => this.isEditableAndErasable$.pipe(map(isEditable => isEditable && this.displayMenuLink('edit')))
        },
        {
            label: 'generic.editAndDeleteAssignments',
            icon: 'editAndDeleteAssignments',
            function: () => this.dataCard.editAndDeleteAssignments(this.dataCard.resource),
            displayCondition: () => of(this.displayMenuLink('editAndDeleteAssignments'))
        },
        {
            label: 'generic.assign',
            icon: 'assignment_ind',
            function: () => this.dataCard.openAssign(this.dataCard.resource),
            displayCondition: () => this.dataCard.isAssignable$.pipe(map(isAssignable => isAssignable && this.displayMenuLink('assign')))
        },
        {
            label: 'generic.duplicate_and_assign',
            icon: 'duplicateAndAssign',
            function: () => this.dataCard.duplicateAndAssign(this.dataCard.resource.id),
            displayCondition: () => this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicateAndAssign')))
        },
        {
            label: 'generic.duplicate_and_edit',
            icon: 'duplicateAndEdit',
            function: () => this.dataCard.duplicateAndEdit(this.dataCard.resource.id),
            displayCondition: () => combineLatest([
                this.dataCard.isDuplicable$(this.dataCard.resource),
                this.dataCard.isEditableAndErasable$(null),
                of(this.displayMenuLink('duplicateAndEdit'))
            ])?.pipe(
                map(([isDuplicable, isEditionAllowed, isSettingOk]) => isDuplicable && isEditionAllowed && isSettingOk)
            ),
        },
        {
            label: 'generic.duplicate_if_needed_and_edit',
            icon: 'duplicateIfNeededAndEdit',
            function: () => this.dataCard.duplicateIfNeededAndEdit(this.dataCard.resource.id),
            displayCondition: () => combineLatest([
                this.dataCard.isDuplicable$(this.dataCard.resource),
                this.dataCard.isEditableAndErasable$(this.dataCard.resource),
                of(this.displayMenuLink('duplicateIfNeededAndEdit'))
            ])?.pipe(
                map(([isDuplicable, isEditionAllowed, isSettingOk]) => (isDuplicable && isEditionAllowed) && isSettingOk)
            ),
        },
        {
            label: 'generic.clone',
            icon: 'duplicate',
            function: () => this.dataCard.openDuplicate(this.dataCard.resource.id),
            displayCondition: () => this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicate')))
        },
        {
            label: 'generic.delete',
            icon: 'delete',
            function: () => this.dataCard.openDialog(this.dataCard.resource),
            displayCondition: () => of(this.displayMenuLink('delete') && this.isEditableAndErasable$)
        },
        {
            label: 'generic.deleteWithAssignments',
            icon: 'delete',
            function: () => this.dataCard.deleteWithAssignments(this.dataCard.resource),
            displayCondition: () => of(this.displayMenuLink('deleteWithAssignments'))
        }
    ];
    public readonly actions: DataCardActionInterface[] = [
        {
            id: 'edit',
            function: () => this.dataCard.openEditor(this.dataCard.resource.id),
            displayCondition: () => this.isEditableAndErasable$?.pipe(map(isEditable => isEditable && this.displayMenuLink('edit'))),
            classes: 'button-theme',
            matButtonType: 'mat-mini-fab',
            icon: 'duplicateAndEdit',
            title: 'generic.edit',
            displayLabel: 'generic.edit',
        },
        {
            id: 'duplicate-and-edit',
            function: () => this.dataCard.duplicateAndEdit(this.dataCard.resource.id),
            displayCondition: () => combineLatest([
                this.dataCard.isDuplicable$(this.dataCard.resource),
                this.dataCard.isEditableAndErasable$(null),
                of(this.displayMenuLink('duplicateAndEdit'))
            ])?.pipe(
                map(([isDuplicable, isEditionAllowed, isSettingOk]) => isDuplicable && isEditionAllowed && isSettingOk)
            ),
            classes: 'button-theme',
            matButtonType: 'mat-mini-fab',
            icon: 'edit',
            title: 'generic.duplicate_and_edit',
            displayLabel: 'generic.duplicate_and_edit',
        },
        {
            id: 'duplicate_if_needed_and_edit',
            function: () => this.dataCard.duplicateIfNeededAndEdit(this.dataCard.resource.id),
            displayCondition: () => combineLatest([
                this.dataCard.isDuplicable$(this.dataCard.resource),
                this.dataCard.isEditableAndErasable$(this.dataCard.resource),
                of(this.displayMenuLink('duplicateIfNeededAndEdit'))
            ])?.pipe(
                map(([isDuplicable, isEditionAllowed, isSettingOk]) => (isDuplicable || isEditionAllowed) && isSettingOk)
            ),
            classes: 'button-theme',
            matButtonType: 'mat-mini-fab',
            icon: 'edit',
            title: 'generic.duplicate_if_needed_and_edit',
            displayLabel: 'generic.duplicate_if_needed_and_edit',
        },
        {
            id: 'go-to-theme',
            function: () => this.dataCard.playAlt(this.dataCard.resource),
            displayCondition: () => of(this.settingsCardsDisplay.showButtonThemeLesson && this.dataCard.displayTheme(this.dataCard.resource) && (this.isLessonOrSequence)),
            classes: 'button-theme',
            matButtonType: 'mat-mini-fab',
            icon: 'book_open',
            title: 'go to theme'
        },
        {
            id: 'go-to-lesson',
            function: () => this.dataCard.playAlt(this.dataCard.resource),
            displayCondition: () => of(this.settingsCardsDisplay.showButtonThemeLesson && this.dataCard.displayLesson(this.dataCard.resource) && (this.isLessonOrSequence)),
            classes: 'button-course',
            matButtonType: 'mat-mini-fab',
            title: 'play.lesson',
            icon: 'layers',
        },
        {
            id: 'play-lesson-0',
            function: () => this.playLesson(),
            displayCondition: () => of(this.isLessonAllowedAndExist),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'play.lesson',
            icon: 'collections_bookmark'
        }, {
            id: 'download-theme-pdf-pedago',
            function: () => this.downloadFile(this.fileType.themePedagoDocPdf),
            displayCondition: () => of(this.isPdfAllowedAndExist && !this.typeOfCard?.isTheme),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'download.student.pdf',
            icon: 'file_download',
        }, {
            id: 'download-lesson-pdf',
            function: () => this.isPdfMustBeOpenedDirectly ? this.downloadFile(this.fileType.lessonPdf) : this.openLessonPage(this.dataCard, {selectorClassFocus: '.lesson-files'}),
            displayCondition: () => of(this.isPdfLessonAllowedAndExist && this.cardsConfigurationService.downloadLessonPdfMethodIsAllowed()),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'lesson.download.pdf',
            icon: 'download-pdf',
            displayLabel: 'generic.fiches_accompagnement',
        },
        {
            id: 'open-lesson-page-list-pdf',
            function: () => this.manageMultipleFilesCase(),
            displayCondition: () => of(this.isPdfLessonAllowedAndExist && this.isMultiplePdfFile() && this.cardsConfigurationService.openLessonPageListPdfAllowed()),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'lesson.download.pdf',
            icon: 'file_download',
            displayLabel: 'generic.fiches_accompagnement',
        },
        {
            id: 'open-current-pdf',
            function: () => this.downloadFile(this.fileType.lessonPdf),
            displayCondition: () => of(this.isPdfLessonAllowedAndExist && !this.isMultiplePdfFile() && this.cardsConfigurationService.openCurrentPdfAllowed()),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'lesson.download.pdf',
            icon: 'file_download',
            displayLabel: 'generic.fiches_accompagnement',
        },
        {
            id: 'download-lesson-pdf-list',
            function: () => null,
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.isMultiPdfLessonAllowedAndExist() && this.pdfList().length > 0),
            matButtonType: 'mat-button',
            title: 'lesson.download.pdf.menu',
            icon: this.cardsConfigurationService.pdfDonwnloadIcon(),
            matMenuTriggerFor: 'menuPdf',
        }, {
            id: 'download-lesson-pdf-list-as-label-in-action-menu',
            function: () => this.openLessonPage(this.dataCard),
            displayCondition: () => of(this.moveActionsButtonInMenu && this.isMultiPdfLessonAllowedAndExist() && this.pdfList().length > 0),
            matButtonType: 'mat-button',
            title: 'lesson.download.pdf.menu',
            displayLabel: 'lesson.download.pdf.menu',
            icon: this.cardsConfigurationService.pdfDonwnloadIcon(),
            matMenuTriggerFor: null,
        },
        {
            id: 'download-theme-pdf',
            function: () => this.downloadFile(this.fileType.themePdf),
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.isPdfThemeAllowedAndExist()),
            classes: 'button-download-theme',
            matButtonType: 'mat-mini-fab',
            title: 'lesson.download.theme',
            icon: 'book_open'
        }, {
            id: 'bookmark',
            function: () => this.bookmark(),
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.showFavoriteButton),
            classes: () => [this.isFavorite() ? 'active' : '', 'button-favorite', 'flag'].join(' '),
            matButtonType: 'mat-raised-button',
            title: 'generic.favoris',
            icon: 'heart',
            fxLayout: 'row',
            fxLayoutAlign: 'start center'
        }, {
            id: 'play-preview',
            function: () => this.playPreview(this.dataCard),
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.dataCard.isPreviewAccessible),
            classes: 'button play-preview',
            matButtonType: 'mat-raised-button',
            title: 'generic.preview',
            icon: this.cardsConfigurationService.playPreviewIcon(),
        }, {
            id: 'play-preview-as-label',
            function: () => this.playPreview(this.dataCard),
            displayCondition: () => of(this.moveActionsButtonInMenu && this.dataCard.isPreviewAccessible),
            classes: 'button play-preview',
            matButtonType: 'mat-raised-button',
            title: 'generic.preview',
            displayLabel: 'generic.preview',
            icon: 'visibility'
        }, {
            id: 'play-metadata-icon',
            function: () => this.cardsService.play(this.dataCard),
            displayCondition: () => of(!this.displayField('playMetadata') && this.isLessonOrSequence && !this.settingsCardsDisplay.isTextButton && (this.cardsConfigurationService.playMetadataIconAllowed())),
            matMenuTriggerFor: 'menuBottom',
            classes: 'button-play',
            matButtonType: 'mat-mini-fab',
            title: 'play.lesson',
            icon: 'play'
        }, {
            id: 'open-meta-dialog',
            function: ($event) => this.dataCard.openMetaDialog($event, this.metadatas),
            displayCondition: () => of(this.displayField('playMetadata')),
            classes: 'button-play',
            matButtonType: 'mat-mini-fab',
            title: 'play.lesson',
            icon: 'play'
        }, {
            id: 'play-with-preview',
            function: ($event) => this.cardsService.play(this.dataCard),
            displayCondition: () => of(this.isLessonOrSequence && this.settingsCardsDisplay.isTextButton && this.displayMenuWithPreviewOption() === true),
            matMenuTriggerFor: 'menuBottom',
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'play.lesson',
            displayLabel: 'generic.lesson.play',
        }, {
            id: 'play-as-icon',
            function: ($event) => this.cardsService.play(this.dataCard),
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.isLessonOrSequence && !this.settingsCardsDisplay.isTextButton && this.displayMenuWithPreviewOption() === false && this.dataCard.isPlayAccessible),
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'play.lesson',
            icon: this.cardsConfigurationService.playAsIConIcon(),
        }, {
            id: 'play-as-label',
            function: ($event) => {
                if (!this.cardsService.isOneLessonIsAlreadyLaunch === true) {
                    this.cardsService.isOneLessonIsAlreadyLaunch = true;
                    this.cardsService.play(this.dataCard);
                }
            },
            displayCondition: () => of(!this.moveActionsButtonInMenu && this.isLessonOrSequence && this.settingsCardsDisplay.isTextButton && this.displayMenuWithPreviewOption() === false && this.dataCard.isPlayAccessible),
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'play.lesson',
            icon: this.cardsConfigurationService.playAsLabelIcon(),
            displayLabel: 'generic.lesson.play',
        },
        {
            id: 'play-quiz',
            function: ($event) => {
                if (!this.cardsService.isOneLessonIsAlreadyLaunch === true && !!this.dataCard.isQuizAccessible) {
                    this.cardsService.isOneLessonIsAlreadyLaunch = true;
                    this.cardsService.play(this.dataCard, {quizEnabled: true});
                }
            },
            displayCondition: () => of(this.dataCard.isQuizAccessible),
            disabled: () => of(!this.dataCard.isQuizEnable),
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'play.quiz',
            icon: 'play-quiz',
            displayLabel: 'generic.play_quiz',
        }, {
            id: 'play-as-label-in-action-menu',
            function: ($event) => this.cardsService.play(this.dataCard), // modale
            displayCondition: () => this.playAsLabelInActionMenu(),
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'play.lesson',
            icon: 'play-in-class',
            displayLabel: this.cardsConfigurationService.playAsLabelInActionMenuDynamicClass() ? `lessons.${this.authService.accessLevel}_play_in_class` : 'generic.lesson.play-in-class',
        }, {
            id: 'play-original-resource',
            function: ($event) => this.dataCard.play(this.dataCard.originalResource),
            displayCondition: () => of(!this.typeOfCard?.isTheme && !this.typeOfCard?.isLesson && !this.typeOfCard?.isSequence),
            classes: 'button-play',
            matButtonType: 'mat-mini-fab',
            title: 'play.lesson',
            icon: 'play'
        }, {
            id: 'button-assign-icon',
            function: ($event) => this.dataCard.openAssign(this.dataCard.resource),
            displayCondition: () => this.cardsConfigurationService.buttonAssignIconConditionNotUseShowButtonAssign() ? !this.moveActionsButtonInMenu && this.displayField('assignIcon') ? this.dataCard.isAssignable$ : of(false) : this.showButtonAssign(),
            classes: 'button-assign-icon',
            matButtonType: 'mat-mini-fab',
            icon: 'assign',
            title: 'lesson.assign',
        }, {
            id: 'button-assign-text-icon',
            function: ($event) => this.dataCard.openAssign(this.dataCard.resource),
            displayCondition: () => !this.moveActionsButtonInMenu && this.displayField('assignTextIcon') ? this.dataCard.isAssignable$ : of(false),
            classes: 'button-assign-text-icon',
            matButtonType: 'mat-mini-fab',
            icon: 'assign',
            displayLabel: 'generic.assign',
            title: 'lesson.assign',
        }, {
            id: 'button-assign-as-label-in-action-menu',
            function: ($event) => this.dataCard.openAssign(this.dataCard.resource),
            displayCondition: () => this.moveActionsButtonInMenu && this.displayField('button-assign-as-label-in-action-menu') ? this.dataCard.isAssignable$ : of(false),
            classes: 'button-assign-icon',
            matButtonType: 'mat-menu-item',
            icon: 'assign',
            title: 'lesson.assign',
            displayLabel: 'lesson.assign',
            matMenuTriggerFor: null,
        }, {
            id: 'download',
            function: ($event) => this.download(),
            displayCondition: () => of(this.isAllowedDownloadLesson && !this.settingsCardsDisplay.isTextButton),
            classes: 'button-play',
            matButtonType: 'mat-mini-fab',
            icon: 'play',
            title: 'lesson.play'
        }, {
            id: 'downloadTextButton',
            function: ($event) => this.download(),
            displayCondition: () => of(this.isAllowedDownloadPedagoDoc && this.settingsCardsDisplay.isTextButton && this.dataCard?.resource?.get('reference')?.length),
            classes: 'button-play',
            matButtonType: 'mat-raised-button',
            title: 'download.pedago.doc',
            displayLabel: 'generic.theme.play'
        },
        {
            id: 'deleteOrDisable',
            title: 'generic.delete_or_disable',
            displayLabel: 'generic.delete_or_disable',
            matButtonType: 'mat-raised-button',
            icon: 'delete',
            function: () => this.dataCard.deleteWithAssignments(this.dataCard.resource),
            displayCondition: () => of(this.displayMenuLink('deleteOrDisable')),
            disabled: () => this.isEditable$.pipe(map(isEditable => !isEditable))
        }];
    private readonly DISPLAY_FIELDS = [
        'author',
        'chaptersParent',
        'chapters',
        'concepts',
        'description',
        'difficulty',
        'assignation_type',
        'activitiesCount',
        'educationalLevel',
        'target-age',
        'period',
        'skills',
        'source-author',
        'theme',
        'usage',
    ];

    constructor() {
        this.difficultyDisplaySteps = Array(this.cardsConfigurationService.difficultyDisplaySteps()).fill(1).map((x, i) => i + 1);
    }

    private _bookmarksParams: { endPoint: string, type: string } = {endPoint: 'bookmarks', type: 'node'};

    /***
     * params for calling bookmarks
     */
    get bookmarksParams(): { endPoint: string, type: string } {
        return this._bookmarksParams;
    }

    get fileType(): typeof FileType {
        return FileType;
    }

    private _typeOfCard: ITypeOfCard = {
        isTheme: false,
        isLesson: false,
        isSequence: false,
        isVideo: false,
        isVideoUrl: false,
        isImage: false,
        isAudio: false,
        isDocument: false,
        isForm: false,
        isShared: false,
        isModel: false,
        isUrl: false
    };

    /***
     * type of element in card if is inside = true
     */
    get typeOfCard(): ITypeOfCard {
        return this._typeOfCard;
    }

    private _contentDetails: IContentDetails = {
        document: '',
        reference: [],
        skills: [],
        educationalLevel: [],
        difficulty: '',
        image: {id: null, uri: ''},
        theme: {id: null, label: '', document: ''},
        usage: [],
        files: [],
        author: ''
    };


    /***
     * contentDetails infos
     */
    get contentDetails(): IContentDetails {
        return this._contentDetails;
    }

    public get showFavoriteButton(): boolean {
        return this.settingsCardsDisplay.showButtonBookmarks && this.settingsCardsDisplay.showButtonBookmarks[this.getLabelToUse()];
    }

    public get assignedCount(): number {
        return this.dataCard.resource.get('assignatedCount');
    }

    public get duplicationCount(): number {
        return this.dataCard.resource.get('duplicationCount');
    }

    public get settingsCardsDisplay() {
        return this.cardsConfigurationService.cardDisplay();
    }

    public get defaultHeader() {
        return this.cardsConfigurationService.cardDefaultHeader();
    }

    public get headerClasses(): string[] {
        return this._headerClasses;
    }

    public get metadatas(): MetadataCardInterface {
        return this.dataCard.resource.get('metadatas');
    }

    /***
     * filter by lessons or sequence element
     */
    get isLessonOrSequence(): boolean {
        return this.typeOfCard?.isSequence || this.typeOfCard?.isLesson;
    }

    /***
     * is pdf student is allowed and url of pdf exist
     */
    public get isPdfAllowedAndExist(): boolean {
        return (this.typeOfCard?.isSequence || this.typeOfCard?.isTheme || this.typeOfCard?.isLesson)
            && this.contentDetails.reference
            && this.contentDetails.reference.length > 0
            && this.contentDetails.reference[0].media !== ''
            && this.contentDetails.reference[0].media !== undefined
            && this.contentDetails.reference[0].media !== null;
    }

    /***
     * is pdf in lesson is allowed and url of pdf exist
     * and there is only  one pdf
     */
    public get isPdfLessonAllowedAndExist(): boolean {
        if (this.cardsConfigurationService.multiPdfDownload()) {
            return false;
        }
        if ((this.cardsConfigurationService.roleForbiddenToDownloadPdfLesson() as string[]).includes(this.authService.accessLevel)) {
            return false;
        }
        return (this.typeOfCard?.isLesson
                && this.contentDetails.files.filter(file => file.filemime === 'application/pdf').length
                && this.contentDetails.files.filter(file => file.filemime === 'application/pdf')[0].uri !== '')
            || (this.dataCard.resource.get('file') && this.dataCard.resource.get('file').filemime === 'application/pdf'
                && this.dataCard.resource.get('file').uri && this.dataCard.resource.get('file').uri !== '');
    }

    public get isPdfMustBeOpenedDirectly(): boolean {
        return this.cardsConfigurationService.isPdfMustBeOpenedDirectly();
    }

    /***
     * check if isEditableAndErasable$ method exist and if exist return result from this ressource
     */
    public get isEditableAndErasable$(): Observable<boolean> {
        return this.dataCard?.isEditableAndErasable$ && this.dataCard?.isEditableAndErasable$(this.dataCard.resource);
    }

    /***
     * is a pdf of the lesson exist and is allowed to download
     */
    public get isAllowedDownloadLesson(): boolean {
        return this.typeOfCard?.isTheme && this.isContentDetailContainReference();
    }

    /**
     * in theme is pedago doc exist
     */
    public get isAllowedDownloadPedagoDoc(): boolean {
        return this.typeOfCard?.isTheme && this.isContentDetailContainDocument();
    }

    /***
     * is a lesson to play is asociate with the card in themes
     */
    public get isLessonAllowedAndExist(): boolean {
        return this.typeOfCard?.isTheme && this.isContentDetailContainReference();
    }

    /***
     * favorites is selected or not if it's a theme use flag bookmarks-theme
     */
    public isFavorite(): boolean {
        if (this.typeOfCard?.isTheme) {
            return this.dataCard.resource.get('bookmarks-theme') === true;
        } else {
            return this.dataCard?.resource.attributes.bookmarks === true;
        }
    }

    public get skills(): Array<object> {
        return this.dataCard?.resource?.get('metadatas').skills;
    }

    public get chapters(): Array<object> {
        return this.dataCard?.resource?.get('metadatas').chapters;
    }

    public get chaptersParent(): string {
        return this.dataCard && this.dataCard.resource &&
            this.dataCard.resource.get('metadatas') &&
            this.dataCard.resource.get('metadatas').chaptersParent &&
            this.dataCard.resource.get('metadatas').chaptersParent.map(val => val.label).join(', ');
    }


    public get usage(): string {
        if (this.dataCard
            && this.dataCard.resource
            && this.dataCard.resource.get('usage')
            && this.dataCard.resource.get('usage')[0]
            && this.dataCard.resource.get('usage')[0]['label']) {
            this.usageLabels = [];
            this.dataCard.resource.get('usage').map(val => val.label).forEach((label) => {
                this.translate
                    .get(label)
                    .subscribe((translation: string) => (this.usageLabels.push(translation)));
            });
            return this.usageLabels.join(', ');
        } else {
            return '';
        }
    }

    public get concepts(): Array<object> {
        return this.dataCard?.resource?.get('metadatas')?.concepts;
    }

    public get usages(): Usage[] {
        let usages: Usage[] = [];
        this.dataCard?.resource?.get('usage').map((usage) => {
            let newUsage: Usage = {
                id: usage.id,
                label: ''
            };
            newUsage.label = this.translate
                .instant(this.localizedType(usage.label)) || '';
            usages.push(newUsage);
        });
        return usages;
    }

    public get isLessonUnlocked(): boolean {
        return !this?.dataCard?.resource?.get('requirementsAccess') || this?.dataCard?.resource?.get('requirementsAccess')?.access;
    }

    public get isLessonAssigned(): boolean {
        return this?.dataCard?.resource?.get('requirementsAccess')?.assigned;
    }

    public get lessonProgress(): number {
        return this?.dataCard?.resource?.get('requirementsAccess')?.progress;
    }

    public get requirementsToUnlockLesson(): string[] {
        return this?.dataCard?.resource?.get('requirementsAccess')?.requirements?.join() || [];
    }

    public get cardFieldsSettings(): string[] {
        const role = this.authService.accessLevel;
        let typeDataCard: DataCardType = this.getLabelToUse();

        if (this._typeOfCard.isLesson) {
            typeDataCard = 'lesson';
        }
        if (this._typeOfCard.isModel) {
            typeDataCard = 'model';
        }
        if (this._typeOfCard.isTheme) {
            typeDataCard = 'theme';
        }
        if (this._typeOfCard.isShared) {
            typeDataCard = 'community';
        }

        if (!this.cardsConfigurationService.cardFields()[role]) {
            return this.cardsConfigurationService.cardFields()['default'][typeDataCard];
        }
        return this.cardsConfigurationService.cardFields()[role][typeDataCard];
    }

    /***
     * is multi pdf element downloadable in lesson activated
     * and almost one pdf exist
     */
    public isMultiPdfLessonAllowedAndExist(): boolean {
        if (!this.cardsConfigurationService.multiPdfDownload()) {
            return false;
        }
        return ((this.typeOfCard?.isLesson || this.dataCard?.isInFavoritePage)
            && this.contentDetails.files.filter(file => file.filemime === 'application/pdf').length
            && this.contentDetails.files.filter(file => file.filemime === 'application/pdf')[0].uri !== ''
            && this.contentDetails.files.filter(file => file.filemime === 'application/pdf').length >= 1);
    }

    ngOnInit(): void {
        combineLatest([this.sharedDataWithOtherModuleService.loadLearners(), this.sharedDataWithOtherModuleService.loadClasses(), this.sharedDataWithOtherModuleService.loadWorkgroups()])
            .pipe(
                take(1),
                tap(([learners, classes, workgroups]) => {
                    this.learners = learners;
                    this.cardsService.learners = learners;
                    this.classes = classes;
                    this.cardsService.classes = classes;
                    this.workgroups = workgroups;
                    this.cardsService.workgroups = workgroups;
                })
            )
            .subscribe();
        this.isCommunityPage = !!this.router.url.includes('community');
        this.setTypeOfCard();
        this.initValueByLabel();
        this.setContentDetailsInfo();
        this.setBookmarksInfos();
        // add bookmarks-theme because service use api name : bookmark-theme => patch to make it exists
        this.dataCard.resource.set('bookmarks-theme', this.dataCard.resource.get('bookmarks'));
        this.headerTitle = this.dataCard.resource.get('metadatas').title;
        this._headerClasses = this.dataCard.headerClasses ? this.dataCard.headerClasses(this.dataCard.resource) : [];

        this.isShareableCommunity = this.dataCard.isShareableCommunity;
        this.isShareableModel = this.dataCard.isShareableModel;
        if (this.isShareableCommunity) {
            this.sharedCommunity = !!this.dataCard.resource.get('shared');
        }

        if (this.isShareableModel) {
            this.sharedAsModel = !!this.dataCard.resource.get('model');
        }


        this.isEditable$ = this.isCardEditable$().pipe(
            shareReplay(1)
        );

    }

    public toggleShare(evt, type): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: type === 'shared' ? 'activities.confirm_share_community_lesson' : 'activities.confirm_share_model_lesson',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.cancel'
        };

        if (!evt.checked) {
            dialogConfig.data.bodyDialog = type === 'shared' ? 'activities.confirm_not_share_community_lesson' : 'activities.confirm_not_share_model_lesson';
        }

        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataCard.share(this.dataCard.resource, {[type]: evt.checked ? '1' : '0'});
                if (type === 'shared') {
                    this.sharedCommunity = evt.checked;
                }
                if (type === 'model') {
                    this.sharedAsModel = evt.checked;
                }
            } else {
                evt.source.checked = !evt.checked;
                if (type === 'shared') {
                    this.sharedCommunity = !evt.checked;
                }
                if (type === 'model') {
                    this.sharedAsModel = !evt.checked;
                }
            }
        });
    }

    public getLabelToUse(): DataCardType {
        if (this.typeOfCard?.isTheme) {
            return 'themes';
        }
        return this.dataCard.resource.get('format').label;
    }


    /* refacto to do all of this method are to set in the init only once **/
    public lessonCount(): number {
        return this.dataCard.resource.get('infos').lessons;
    }

    public activityCount(): number {
        return this.dataCard.resource.get('infos').activities;
    }

    public otherCount(): number {
        return this.dataCard.resource.get('infos').others;
    }

    displayField(name: string): boolean {
        return this.cardFieldsSettings.includes(name);
    }

    /**
     * to distinct same button but in different context defined as is a model or not
     * We can add, for each button, a label ':[type]' like 'model' or 'lesson'
     *
     * @example
     *     // init
     *     displayMenuLink('duplicate') // true if datacard is a model, false if not
     *
     */
    displayMenuLink(action: string): boolean {
        const role = this.authService.accessLevel;
        const type = this._typeOfCard.isModel ? 'model' : 'lesson';
        let menuLinks = this.cardsConfigurationService.menuLinks()[type][role];
        if (menuLinks === undefined) {
            menuLinks = this.cardsConfigurationService.menuLinks()[type]['default'];
        }
        return menuLinks.includes(action);
    }

    /**
     * is lesson models and have a theme pdf to download
     */
    public isPdfThemeAllowedAndExist(): boolean {
        return this.typeOfCard?.isLesson && !!this._contentDetails.theme && !!this._contentDetails.theme.document &&
            !this._typeOfCard.isShared && this._typeOfCard.isModel;
    }

    public openAssignments(): void {
        this.router.navigate(['followed', 'list'], {queryParams: {title: this.headerTitle}});
    }

    /**
     * download document not at the same place in case of theme or sequence
     * is the old use? not sure if not put it in themeservice
     */
    public download(_path?: string): void {
        if (this.typeOfCard?.isTheme) {
            this.dataCard.downloadDoc(this.contentDetails.reference[0].media);
        } else if (this.typeOfCard?.isLesson) {
            this.dataCard.downloadDoc(this.contentDetails.document);
        } else {
            // make a play old implementation always use
            this.dataCard.download(this.dataCard.resource);
        }
    }

    public pdfList() {
        return this.contentDetails.files.filter(file => file.filemime === 'application/pdf')
            .map(c => ({
                uri: c.uri
            }));
    }

    /**
     * download file use the good data regarding of origin of call lesson theme etc.
     */
    public downloadFile(idFileType: number): void {
        switch (idFileType) {
            case FileType.lessonPdf:
                if (this.dataCard.resource.get('file')) {
                    this.dataCard.downloadDoc(this.dataCard.resource.get('file').uri);
                } else {
                    this.dataCard.downloadDoc(this.contentDetails.files.filter(file => file.filemime = 'application/pdf')[0].uri);
                }
                break;
            case FileType.themePedagoDocPdf:
                this.dataCard.downloadDoc(this.contentDetails.reference[0].media);
                break;
            case FileType.themePdf:
                this.dataCard.downloadDoc(this.contentDetails.theme.document);
                break;
            default:
                console.error('error type is not existing');
                break;
        }
    }

    public isMultiplePdfFile(): boolean {
        return !this.dataCard?.resource?.get('file') && this.contentDetails?.files?.length > 1;
    }

    /**
     * play lesson link to current theme
     */
    public playLesson(): void {
        this.dataCard.play(this.contentDetails.reference[0].id);
    }

    public bookmark(): void {
        this.flagService.flagEntity(this.dataCard.resource, this.bookmarksParams.type, this.bookmarksParams.endPoint)
            .subscribe(() => {
                this.cardsService.settingBookmark.next();
            });
    }

    public openLessonPage<T extends DataEntity>(dataCard: DataCardInterface<T>, queryParams ?: { [key: string]: string | number }): void {
        this.cardsConfigurationService.openLessonPageUseOnlyDatacard() ? this.cardsService.openLessonPage(dataCard) : this.cardsService.openLessonPage(dataCard, this.router.url, queryParams);
    }

    public get moveActionsButtonInMenu(): boolean {
        let obj = {};
        if (this.authService.isLearner() && this.cardsConfigurationService.moveActionsButtonInMenu()['learner']) {
            obj = this.cardsConfigurationService.moveActionsButtonInMenu()['learner'];
        } else {
            obj = this.cardsConfigurationService.moveActionsButtonInMenu()['default'];
        }

        let cardType = this.getLabelToUse();
        if (Object.keys(obj).find((key) => key === cardType)) {
            if (this.authService.isLearner() && this.cardsConfigurationService.moveActionsButtonInMenu()['learner']) {
                return this.cardsConfigurationService.moveActionsButtonInMenu()['learner'][cardType];
            } else {
                return this.cardsConfigurationService.moveActionsButtonInMenu()['default'][cardType];
            }
        } else {
            return this.cardsConfigurationService.moveActionsButtonInMenu()['default'];
        }
    }

    public get displayOnlyMediaIconsType() {
        return this.cardsConfigurationService.displayOnlyMediaIconsType();
    }

    /**
     * open pop up to set the number of player
     */
    public openAssignmentWithUserDataFormModal<T extends DataEntity>(entity: T, datacard: DataCardInterface<T>): void {
        this.cardsService.openAssignmentWithUserDataFormModal(entity, datacard, this.learners, this.classes, this.workgroups);
    }

    public getOrder(field: string): number {
        return this.cardFieldsSettings && this.cardFieldsSettings.indexOf(field);
    }

    /**
     * show submenu for launch lesson and preview on click on button play
     */
    public displayMenuWithPreviewOption(): boolean {
        return this.cardsConfigurationService.displayMenuWithPreviewOption() && (this.typeOfCard?.isLesson || this.typeOfCard?.isModel);
    }

    /**
     * test if image exists
     */
    public getEducationalLevel(resource): string {
        try {
            return resource.get('metadatas').educationalLevel[0].label;
        } catch (e) {
            return undefined;
        }
    }

    /**
     * return educational level array as string
     */
    public getEducationalLevelsString(resource): string {
        try {
            return resource.get('metadatas').educationalLevel.map(x => '<span>' + x.label + '</span>').join('<span class="comma">, </span>');
        } catch (e) {
            return '';
        }
    }

    /**
     * Get levels array and return it as string
     */
    public getLevels(resource): string {
        try {
            return resource.get('metadatas').levels.map(x => '<span>' + x.label + '</span>').join('<span class="comma">, </span>');
        } catch (e) {
            return '';
        }
    }

    public getType(resource): string {
        try {
            return resource.get('metadatas').assignation_type.label;
        } catch (e) {
            return undefined;
        }
    }

    public getDynamicLevelTypeImage(resource): string {
        if (this.getEducationalLevel(resource) && this.getType(resource)) {
            return '/assets/' + this.brand + '/images/thumbs/' + resource.get('metadatas').educationalLevel[0].label + '-' + resource.get('metadatas').assignation_type.label + '.png';
        } else {
            return '/assets/' + this.brand + '/images/thumbs/default.jpg';
        }
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    public isMenuIsNecessary$(): Observable<boolean> {
        const isShowCardHeader$ = of(this.cardsConfigurationService.isShowCardHeaderMenu());
        const isAssignable$ = combineLatest([of(this.displayMenuLink('assign')), this.dataCard.isAssignable$])
            .pipe(map(allMustBeTrue => allMustBeTrue.every(mustBeTrue => mustBeTrue === true)));

        const isMenuNotEmpty$ = combineLatest([
            isAssignable$,
            of(this.displayMenuLink('edit') && this.isEditableAndErasable$),
            of(this.displayMenuLink('editAndDeleteAssignments')),
            this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicateAndAssign'))),
            this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicateAndEdit'))),
            this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicateIfNeededAndEdit'))),
            this.dataCard.isDuplicable$(this.dataCard.resource).pipe(map(isDuplicable => isDuplicable && this.displayMenuLink('duplicate'))),
            of(this.displayMenuLink('delete') && this.isEditableAndErasable$),
            of(this.displayMenuLink('deleteWithAssignments')),
        ]).pipe(map(allShouldBeTrue => allShouldBeTrue.some(shouldBeTrue => shouldBeTrue === true)));

        return combineLatest([
            of(this.displayField('menu')),
            isShowCardHeader$,
            of(this.isLessonOrSequence),
            isMenuNotEmpty$
        ]).pipe(map(allMustBeTrue => allMustBeTrue.every(mustBeTrue => mustBeTrue === true)));
    }

    public getDifficulties(): string {
        return (this.dataCard.resource.get('metadatas')?.difficulty || []).map(d => d.label).join(', ');
    }

    public getActivitiesCount(): string {
        return (this.dataCard.resource.get('infos')?.activities || '');
    }

    public hasSomethingToShow(data: (string | number) | (string | number)[] | Array<object>): boolean {
        if (typeof data !== 'number' && isArray(data)) {
            return data.length > 0;
        }

        return data !== null && data !== undefined && data !== '';
    }

    /**
     * Determines whether any of the fields defined in DISPLAY_FIELDS should be displayed
     * @returns A boolean value indicating whether any of the fields should be displayed
     */
    shouldDisplayFields(): boolean {
        return this.getDisplayedFields().length > 0;
    }

    /**
     * Returns an array of all displayed fields based on the `cardFieldsSettings` property.
     * @returns {string[]} An array of strings representing the displayed fields.
     */
    getDisplayedFields(): string[] {
        return this.DISPLAY_FIELDS.filter(field => this.displayField(field));
    }

    /**
     * Returns the translated title for a given field.
     * @param field - The field for which to get the title.
     * @returns The translated title for the given field.
     */
    getFieldTitle(field: string): string {
        const FIELD_TITLES = {
            description: 'activities.lesson.short_description',
            usage: 'generic.usage',
            difficulty: 'generic.field_difficulty',
            activitiesCount: 'generic.field_activities_count',
            'assignation_type': 'generic.type',
            'target-age': 'activities.target_age',
            educationalLevel: 'generic.level',
            'source-author': 'generic.source_author',
            concepts: 'generic.concepts',
            chaptersParent: 'generic.field_chapters_parents',
            chapters: 'generic.field_chapters',
            theme: 'generic.theme',
            skills: 'activities.period',
            'share-lesson': 'generic.author',
        };

        return FIELD_TITLES[field] || '';
    }

    /**
     * Returns the content for a given field, based on its name.
     * @param field The name of the field to retrieve the content for.
     * @returns The content of the field as a string.
     */
    getFieldContent(field: string): string {
        switch (field) {
            case 'description':
                const description = this.dataCard.resource.get('metadatas')?.description || '';

                let limitedDescription = this.cardsService.viewLessonPage && description.length > 140
                    ? description.substring(0, 140).split(' ').slice(0, -1).join(' ') + '...'
                    : description;

                return limitedDescription;
            case 'usage':
                return this.hasSomethingToShow(this.usage) ? this.usage : '';
            case 'difficulty':
                if (this.difficultyDisplaySteps && this.hasSomethingToShow(this.dataCard.resource.get('metadatas')?.difficulty?.label)) {
                    let difficultySteps = '';
                    for (let i = 1; i <= this.difficultyDisplaySteps.length; i++) {
                        const stepClass = i <= +this.dataCard.resource.get('metadatas')?.difficulty?.label ? 'active' : '';
                        difficultySteps += `<span class="difficultyStep ${stepClass}"></span>`;
                    }
                    return difficultySteps;
                } else if (this.hasSomethingToShow(this.getDifficulties())) {
                    return this.getDifficulties();
                } else {
                    return '';
                }
            case 'activitiesCount':
                return this.hasSomethingToShow(this.getActivitiesCount()) ? this.getActivitiesCount() : '';
            case 'assignation_type':
                return this.translate.instant(this.localizedType(this.dataCard.resource.get('metadatas')?.assignation_type?.label)) || '';
            case 'target-age':
                return this.getLevels(this.dataCard.resource) || '';
            case 'educationalLevel':
                return this.getEducationalLevelsString(this.dataCard.resource) || '';
            case 'source-author':
                return this.dataCard.resource.get('metadatas')['source-author'] || '';
            case 'concepts':
                return this.hasSomethingToShow(this.concepts.join(', ')) ? this.concepts.join(', ') : '';
            case 'chaptersParent':
                return this.hasSomethingToShow(this.chaptersParent) ? this.chaptersParent : '';
            case 'chapters':
                return this.hasSomethingToShow(this.chapters) ? this.chapters.join(', ') : '';
            case 'theme':
                return this.dataCard?.resource?.get('theme')?.label || '';
            case 'skills':
                return this.hasSomethingToShow(this.skills) ? this.skillsTransformed() : '';
            case 'share-lesson':
                return this.isCommunityPage && this.hasSomethingToShow(this.dataCard?.resource?.get('owner-name'))
                    ? this.dataCard?.resource?.get('owner-name')
                    : '';
            default:
                return '';
        }
    }

    /**
     * skills can be string number object etc.. we manage the object case with a field label here
     * @private
     */
    private skillsTransformed(): string {
        if (Array.isArray(this.skills) &&
            this.skills.every(item => typeof item === 'object' && item.hasOwnProperty('label'))) {
            const labels = this.skills.map((item: any) => item.label);
            return labels.join(', ');
        } else if (Array.isArray(this.skills) &&
            this.skills.every(item => typeof item === 'object' && !item.hasOwnProperty('label'))) {
            return ''; //// to avoid object object on user screen if no label in object
        } else {
            return this.skills.join(', ');
        }
    }

    public downloadDoc(uri: string): void {
        // window.open(uri, '_blank');
        this.cardsService.downloadDoc(uri);
    }

    public getMenuTriggerFor(matMenuTriggerFor: string): any {
        if (matMenuTriggerFor === 'menuPdf') {
            return this.menuPdf;
        } else if (matMenuTriggerFor === 'menuBottom') {
            return this.menuBottom;
        }

        console.error('card.component::1258::getMenuTriggerFor', 'menu not found', matMenuTriggerFor);
    }

    public getClasses(action: DataCardActionInterface): string {
        if (typeof action.classes === 'string') {
            return action.classes;
        } else if (typeof action.classes === 'function') {
            return action.classes();
        }
        return '';
    }

    private setBookmarksInfos(): void {
        if (this.typeOfCard?.isTheme) {
            this._bookmarksParams.endPoint = 'bookmarks-theme';
            this._bookmarksParams.type = 'taxonomy_term';
        }
    }

    private setContentDetailsInfo(): void {
        try {
            this._contentDetails.difficulty = this.dataCard.resource.get('metadatas').difficulty?.label;
            this._contentDetails.educationalLevel = this.dataCard.resource.get('metadatas').educationalLevel?.label;
            this._contentDetails.skills = this.dataCard.resource.get('metadatas').skills;
            this._contentDetails.reference = this.dataCard.resource.get('reference');
            this._contentDetails.document = this.dataCard.resource.get('document');
            this._contentDetails.image = this.dataCard.resource.get('image');
            this._contentDetails.theme = this.dataCard.resource.get('theme');
            this._contentDetails.author = this.dataCard.resource.get('owner-name');

            if (this.getLabelToUse() === 'lesson') {
                const file = this.dataCard.resource.get('file');
                if (file) {
                    this._contentDetails.document = file.uri;
                }
                if (this.dataCard.resource.get('metadatas') && this.dataCard.resource.get('metadatas').thumbnail) {
                    // image set by user in back
                    this._contentDetails.image = this.dataCard.resource.get('metadatas').thumbnail;
                } else {
                    // image by default
                    this._contentDetails.image = {id: 0, uri: this.defaultHeader};
                }
            }

            if (this.dataCard.resource.get('metadatas') && this.dataCard.resource.get('metadatas').files !== null
                && this.dataCard.resource.get('metadatas').files !== undefined && this.dataCard.resource.get('metadatas').files.length > 0) {
                this._contentDetails.files = this.dataCard.resource.get('metadatas').files;
            }

        } catch (ex) {
            console.error('cardComponent error-158');
        }
    }

    private setTypeOfCard(): void {
        if (this.dataCard.resource.type === 'theme_search') {
            this._typeOfCard.isTheme = true;
        }
        this._typeOfCard.isLesson = this.isFormat('lesson');
        this._typeOfCard.isSequence = this.isFormat('sequence');
        try {
            this._typeOfCard.isShared = this.dataCard.resource.get('shared') === 1;
            this._typeOfCard.isModel = this.dataCard.resource.get('model') === 1
                || this.dataCard.isContextDefinedItAsModel === true;
        } catch (ex) {
            console.error('cardcomponent214' + ex);
        }
    }

    private initValueByLabel(): void {
        switch (this.getLabelToUse()) {
            case 'video':
                this._typeOfCard.isVideo = true;
                this.headerTitleIcon = 'movies';
                this.thumb = this.dataCard.resource.get('reference').uri;
                break;
            case 'videoUrl':
                this._typeOfCard.isVideoUrl = true;
                this.headerTitleIcon = 'movies';
                if (this.dataCard.originalResource.videoShortImage.includes('vimeo')) {
                    const vimeoApiUrl = this.dataCard.originalResource.videoShortImage;
                    this.http
                        .get(vimeoApiUrl)
                        .subscribe(res => {
                            this.thumb = res['thumbnail_url'];
                        });
                } else {
                    if (this.dataCard.originalResource.videoShortImage !== 'noThumb') {
                        this.thumb = this.dataCard.originalResource.videoShortImage;
                    } else {
                        // if there is no video thumbnail then display url link icon since it is an external video
                        this.thumb = 'assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                    }
                }
                break;
            case 'image':
                this._typeOfCard.isImage = true;
                this.headerTitleIcon = 'photo';
                this.thumb = this.dataCard.resource.get('reference').uri;
                break;
            case 'audio':
                this._typeOfCard.isAudio = true;
                this.headerTitleIcon = 'headset';
                this.thumb = 'assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            case 'parcours':
                this.headerTitleIcon = 'lessons';
                this.thumb = 'no format';
                break;
            case 'themes':
                this.headerTitleIcon = 'book_open';
                this.thumb = 'no format';
                break;
            case 'lesson':
                this.headerTitleIcon = 'lessons';
                this.thumb = 'no format';
                break;
            case 'document':
                this._typeOfCard.isDocument = true;
                this.headerTitleIcon = 'file_text';
                this.thumb = 'assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            case 'url':
                this._typeOfCard.isUrl = true;
                this.headerTitleIcon = 'link';
                this.thumb = 'assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            default :
                this.headerTitleIcon = 'layers';
                this.thumb = 'no format' + this.dataCard.resource.get('format').label;
        }
    }

    /***
     * @param format : 'videoUrl' or 'video' or 'image' or 'sequence' or 'lesson' or 'form'
     */
    private isFormat(format: string): boolean {
        return (this.getLabelToUse() === format);
    }

    private isContentDetailContainReference(): boolean {
        return this.contentDetails.reference !== null &&
            this.contentDetails.reference !== undefined &&
            this.contentDetails.reference.length > 0;
    }

    /**
     * get pdf from lesson associated to the theme.
     */
    private isContentDetailContainDocument(): boolean {
        return this.isContentDetailContainReference() &&
            this.contentDetails.reference[0].media && this.contentDetails.reference[0].media !== '';
    }

    /**
     * prepare data and launch create user assignment in assignation
     * @param user to assign
     * @param dataCard datacard info
     * @param nbrePeople number of people who will see the lesson
     * @param comment
     * @param title if not set, the backend will generate a title
     */
    private createUserAssignment<T extends DataEntity>(user: UserDataEntity, dataEntity: T, dataCard: DataCardInterface<T>, nbrePeople?: number, comment?: string, title?: string): void {
        // TODO - get out of card component, should be passed as parameter
        const assignmentData = {
            learners: [
                {
                    id: user.id,
                }
            ],
            assignatedCount: nbrePeople,
            comment,
            title,
            project: null,
            rating_base: 4,
            startDate: Date.now(),
            startTime: null,
            dueDate: null,
            dueTime: null,
            nodeId: dataEntity.id,
            group: []
        };

        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignment', {
                assignment: assignmentData, callback: assignment => {
                    // lock lesson
                    if (dataEntity.get('locked') !== '1') {
                        const entity = new DataEntity('granule-lesson', dataEntity.attributes, this.octopusConnect, dataEntity.id);
                        entity.set('locked', true);
                        entity.save();
                    }
                    // launch assignment
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('launchAssignment', assignment);
                    // run lesson
                    this.dataCard.play(dataEntity);
                }
            });
    }


    private openSubLessonSelectionModal(): Observable<string | number> {
        return this.dialog.open<ButtonListDialogComponent, ButtonListDialogData>(ButtonListDialogComponent, {
            data: {
                title: this.dataCard.resource.get('metadatas').title,
                list: this.dataCard.resource.get('reference').map((subLesson: { id: string | number, title: string }) => {
                    return ({label: subLesson.title, value: subLesson.id});
                })
            }
        }).afterClosed();
    }

    private playPreview<T extends DataEntity>(datacard: DataCardInterface<T>) {
        this.cardsService.playPreview(datacard);
    }

    private loadLessonById(subLessonId: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: subLessonId,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    public get displayLessonBreadcrumb(): boolean {
        return this.cardsService.displayLessonBreadcrumb?.concepts
            || this.cardsService.displayLessonBreadcrumb?.chapters
            || this.cardsService.displayLessonBreadcrumb?.skills;
    }

    public get hasLessonBreadcrumb(): boolean {
        return ((this.cardsService.displayLessonBreadcrumb?.concepts && this.LessonBreadcrumbFields.concepts != null)
            || (this.cardsService.displayLessonBreadcrumb?.chapters && this.LessonBreadcrumbFields.chapters != null)
            || (this.cardsService.displayLessonBreadcrumb?.skills && this.LessonBreadcrumbFields.skills != null));
    }

    public get LessonBreadcrumbFields(): BreacrumbFields {
        let breacrumbFields: BreacrumbFields = {
            usages: this.cardsService.displayLessonBreadcrumb?.usages && this.hasSomethingToShow(this.usages) ? this.cardsService.defaultUsage(this.usages) : null,
            usagesLabels: this.headerTitle,
            concepts: this.cardsService.displayLessonBreadcrumb?.concepts && this.hasSomethingToShow(this.concepts) ? this.concepts : null,
            chapters: this.cardsService.displayLessonBreadcrumb?.chapters && this.hasSomethingToShow(this.chapters) ? this.chapters : null,
            skills: this.cardsService.displayLessonBreadcrumb?.skills && this.hasSomethingToShow(this.skills) ? this.skills : null
        };
        return breacrumbFields;
    }

    public get showProgressLabel(): boolean {
        return this.settingsCardsDisplay?.showProgressLabel;
    }

    private showButtonAssign(): Observable<boolean> {
        return !this.moveActionsButtonInMenu && this.displayField('assignIcon') ? this.dataCard.isAssignable$ : of(false);
    }

    private playAsLabelInActionMenu(): Observable<boolean> {
        if (this.cardsConfigurationService.rootFilterSubscribeToSelectedValue()) {
            return this.communicationCenter
                .getRoom('root-filter')
                .getSubject('selected')
                .pipe(
                    switchMap(selectedCollection => {
                        if (selectedCollection === 2) {
                            return of(false); // amazon python choice
                        } else {
                            return of(
                                this.moveActionsButtonInMenu &&
                                this.isLessonOrSequence &&
                                this.displayMenuWithPreviewOption() === false &&
                                this.dataCard.isPlayAccessible
                            );
                        }
                    })
                );
        } else {
            return of(this.moveActionsButtonInMenu && this.isLessonOrSequence && this.settingsCardsDisplay.isTextButton && this.displayMenuWithPreviewOption() === false && this.dataCard.isPlayAccessible);
        }
    }

    public get isLearner(): boolean {
        return this.authService.isLearner();
    }

    public isLaunchLessonAskModalActive() {
        return this.cardsConfigurationService.useIsLaunchLessonAskModalActiveWithoutParams() ? this.cardsService.isLaunchLessonAskModalActive() : this.cardsConfigurationService.isLaunchLessonAskModalActive(this.authService.accessLevel as Roles);
    }

    public isEditOrDisableButtonActive() {
        // Pour l'instant ce bouton n'existe pas pour les modèles
        if (this.typeOfCard?.isModel) {
            return false;
        }
        return this.cardsConfigurationService.isEditOrDisableButtonActive(this.authService.accessLevel as Roles);
    }

    public onEditClick($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.dataCard.openEditor(this.dataCard.resource.id);
    }

    public isCardEditable$() {

        // Est ce que dans l'absolu la leçon est éditable
        const isEditFeatureOkay$ = this.dataCard.isEditableAndErasable$ ? this.dataCard.isEditableAndErasable$(this.dataCard.resource) : of(false);

        // Est ce que le module assignation approuve l'édition
        const isAssignmentOkay$ = this.communicationCenter.getRoom('assignment').getSubject('isEditable$').pipe(
            mergeMap((isEditable: (id) => Observable<boolean>) => isEditable(this.dataCard.resource.id))
        );

        return combineLatest([isEditFeatureOkay$, isAssignmentOkay$]).pipe(
            map(([isEditFeatureOkay, isAssignmentOkay]) => isEditFeatureOkay && isAssignmentOkay)
        );
    }

    public isPreviewAvailable() {
        const action = this.actions.find(action => action.id === 'play-preview');
        if (action) {
            return action.displayCondition();
        }

        return of(true);
    }

    private manageMultipleFilesCase(): void {
        this.communicationCenter
            .getRoom('doc-files')
            .getSubject('focus-first')
            .next(true);
        this.openLessonPage(this.dataCard);
    }
}