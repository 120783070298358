// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entity-form-dialog .mat-mdc-dialog-surface .mat-toolbar {
  min-height: initial;
}
.entity-form-dialog .mat-mdc-dialog-surface .toolbar-bottom {
  height: auto;
}
.entity-form-dialog .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}
.entity-form-dialog .mat-mdc-radio-button ~ .mat-mdc-radio-button {
  height: 53px;
  width: 53px;
}
.entity-form-dialog .mat-mdc-radio-button {
  /* this rule for the color of the center of your radio button :*/
}
.entity-form-dialog .mat-mdc-radio-button .mdc-radio__outer-circle,
.entity-form-dialog .mat-mdc-radio-button .mdc-radio__inner-circle {
  height: 53px;
  width: 53px;
  border: none;
  background: transparent;
}
.entity-form-dialog .mat-mdc-radio-button.mat-accent {
  /*circle which appears when you click on the radio button :*/
}
.entity-form-dialog .mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  background: transparent;
}
.entity-form-dialog .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  box-shadow: initial;
  box-sizing: initial;
}
.entity-form-dialog .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__inner-circle {
  background: transparent;
}
.entity-form-dialog .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-listing/groups-form/groups-form.component.scss"],"names":[],"mappings":"AAMQ;EACI,mBAAA;AALZ;AAQQ;EACI,YAAA;AANZ;AAUI;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AARR;AAYI;EACE,YAAA;EACA,WAAA;AAVN;AAaE;EAEE,gEAAA;AAZJ;AAaI;;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AAXN;AAcI;EAaE,4DAAA;AAxBN;AAYM;EACE,uBAAA;AAVR;AAaQ;EACE,mBAAA;EACA,mBAAA;AAXV;AAaQ;EACE,uBAAA;AAXV;AAgBQ;EACE,6BAAA;AAdV","sourcesContent":["@import \"@fuse/scss/fuse\";\r\n\r\n.entity-form-dialog {\r\n\r\n    .mat-mdc-dialog-surface {\r\n\r\n        .mat-toolbar {\r\n            min-height: initial;\r\n        }\r\n\r\n        .toolbar-bottom {\r\n            height: auto;\r\n        }\r\n    }\r\n\r\n    .dialog-content-wrapper {\r\n        max-height: 85vh;\r\n        display: flex;\r\n        flex-direction: column;\r\n    }\r\n\r\n\r\n    .mat-mdc-radio-button ~ .mat-mdc-radio-button {\r\n      height: 53px;\r\n      width: 53px;\r\n    }\r\n\r\n  .mat-mdc-radio-button{\r\n\r\n    /* this rule for the color of the center of your radio button :*/\r\n    .mdc-radio__outer-circle ,\r\n    .mdc-radio__inner-circle {\r\n      height: 53px;\r\n      width: 53px;\r\n      border: none;\r\n      background: transparent;\r\n    }\r\n\r\n    &.mat-accent{\r\n      .mdc-radio__inner-circle{\r\n        background: transparent;\r\n      }\r\n      &.mat-mdc-radio-checked  {\r\n        .mdc-radio__outer-circle {\r\n          box-shadow: initial;\r\n          box-sizing: initial;\r\n        }\r\n        .mdc-radio__inner-circle {\r\n          background: transparent;\r\n        }\r\n      }\r\n      /*circle which appears when you click on the radio button :*/\r\n      .mat-radio-ripple {\r\n        .mat-ripple-element {\r\n          background-color:transparent;\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
