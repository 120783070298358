import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _ from 'lodash-es';
import {AuthenticationService} from '@modules/authentication';
import {IGenericPlugin} from '@modules/activities/core/services/generic-plugins.service';

export interface ToolEditorDataInterface {
    getTools?: () => IGenericPlugin[];
    tool?: IGenericPlugin;
    title?: string;
    instruction?: string;
}

@Component({
    selector: 'app-tool-editor',
    templateUrl: './tool-editor.component.html'
})
export class ToolEditorComponent implements OnInit {
    public form: UntypedFormGroup;
    public allowedTools: IGenericPlugin[] = [];
    private selectedTool: string = null;

    constructor(
        public selfDialogRef: MatDialogRef<ToolEditorComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private modalData: ToolEditorDataInterface,
        private formBuilder: UntypedFormBuilder,
        public authService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.allowedTools = this.modalData.getTools();

        this.form = this.formBuilder.group({
            title: [_.get(this.modalData, 'title'), Validators.required],
            instruction: [_.get(this.modalData, 'instruction')]
        });

        this.selectedTool = _.get(this.modalData, 'tool.toolIdentifier', null);
    }

    public onSubmit(): void {
        this.form.disable();
        const result = this.form.value;

        // replace the toolIdentifier by the tool object
        result.tool = this.allowedTools.find(t => t.toolIdentifier === this.selectedTool);

        this.selfDialogRef.close(result);
    }

    public select(toolIdentifier: string): void {
        this.selectedTool = this.selectedTool === toolIdentifier ? null : toolIdentifier;
    }

    public isActive(toolIdentifier: string): boolean {
        return this.selectedTool === toolIdentifier;
    }

    public isNoToolSelected(): boolean {
        return this.selectedTool === null;
    }
}